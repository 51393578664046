import { tacxDetailPageIndexes } from "../../decoders/indexes.tacx";
import { tacxDetailPage } from "../../definitions/pages.tacx";
import { tacxCalibrationInterface } from "../../definitions/subtypes.tacx";

export const tacxDetailPageParser = (
    parsedMessageArray: number[],
): tacxDetailPage => {
    const feMessage = {} as tacxDetailPage;
    const calibrationreqs = {} as tacxCalibrationInterface;

    const powerLSB = parsedMessageArray[tacxDetailPageIndexes.powerLsb];
    const powerMSB = parsedMessageArray[tacxDetailPageIndexes.powerMsb];
    feMessage.momentaryPower = powerLSB + (powerMSB & 0x0f) * 256;

    if (feMessage.momentaryPower === 4095) feMessage.momentaryPower = 0;

    const trainerStatusFlags = (powerMSB >> 4) & 0xf;

    calibrationreqs.power = !!(trainerStatusFlags & 0x1);
    calibrationreqs.resistance = !!(trainerStatusFlags & 0x2);
    calibrationreqs.user = !!(trainerStatusFlags & 0x4);

    feMessage.calibrationRequirements = calibrationreqs;

    return feMessage;
};
