import { combineReducers } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import bluetoothReducer from "./bluetooth/bluetooth.reducer";
import controlReducer from "./controls/controls.reducer";
import expeditionReducer from "./expedition/expedition.reducer";
import expeditionsReducer from "./expeditions/expeditions.reducer";
import persistConfig from "./index.persist";
import poiReducer from "./poi/poi.reducer";
import tracksReducer from "./tracks/tracks.reducer";
import trainerReducer from "./trainer/trainer.reducer";
import userReducer from "./user/user.reducer";
import videoReducer from "./video/video.reducer";

const combinedReducers = combineReducers({
    user: userReducer,
    tracks: tracksReducer,
    expedition: expeditionReducer,
    expeditions: expeditionsReducer,
    video: videoReducer,
    control: controlReducer,
    trainer: trainerReducer,
    poi: poiReducer,
    bluetooth: bluetoothReducer,
});
const reducers = persistReducer(persistConfig, combinedReducers);

export default reducers;
