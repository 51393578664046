import {
    GpxBreakpoint,
    GpxTrace,
    InterpolatedGpxTrace,
} from "../../../../redux/video/video.interfaces";
import { getAverageVelocityBetweenAB } from "./getVideoVelocity";

/** Extracts important gpx points by indexes provided and returns array with those */
export const getGpxBreakpoints = (
    gpx: GpxTrace[],
    notNullIndexes: number[],
): GpxTrace[] => {
    const breakpoints: GpxTrace[] = [];

    for (let i = 0; i < notNullIndexes.length; ++i) {
        breakpoints.push(gpx[notNullIndexes[i]]);
    }

    return breakpoints;
};

export const getTimestampIndexes = (gpx: GpxTrace[]): number[] => {
    const notNullIndexes: number[] = [];
    for (let i = 0; i < gpx.length; ++i) {
        if (
            typeof gpx[i].timestamp !== "undefined" &&
            gpx[i].timestamp !== null
        ) {
            notNullIndexes.push(i);
        }
    }

    return notNullIndexes;
};

/** Measures a time dilation between two points based on distance and velocity
 * Uses transformed equation (v=s/t => t=s/v)
 * [!] Measured in seconds
 */
export const getExpectedTimeForDistance = (
    p1: GpxTrace,
    p2: GpxTrace,
    velocity: number,
): number => {
    //s
    const deltaDistance = p2.distance - p1.distance;
    // s/v + conversion to seconds (*3600) and rounding up
    return Number(((deltaDistance / velocity) * 3600).toFixed(2));
};

/** Creates a new GPX Point with interpolated timestamp and adds it to the array */
export const interpolateTimestampAndPush = (
    p: GpxTrace,
    timeStart: number,
    deltaTime: number,
    points: InterpolatedGpxTrace[],
): void => {
    const interpolatedGpx: InterpolatedGpxTrace = {
        ...p,
        interpolatedTimestamp: timeStart + deltaTime,
    };

    points.push(interpolatedGpx);
};

export const getBreakpointsWithVelocity = (
    gpx: GpxTrace[],
): GpxBreakpoint[] => {
    const breakpoints: GpxBreakpoint[] = [];

    for (let index = 0; index < gpx.length; ++index) {
        // skip at the end of for loop
        if (index + 1 === gpx.length) continue;

        const p1 = gpx[index] as GpxBreakpoint;
        const p2 = gpx[index + 1] as GpxBreakpoint;

        // timestamp availability is pretty much guaranteed
        if (typeof p1.timestamp === "undefined" || p1.timestamp === null)
            throw new Error("Undefined timestamp p1");
        if (typeof p2.timestamp === "undefined" || p2.timestamp === null)
            throw new Error("Undefined timestamp p2");
        let v: number;
        try {
            v = getAverageVelocityBetweenAB(
                p1.distance,
                p2.distance,
                p1.timestamp,
                p2.timestamp,
            );
        } catch (e) {
            // prettier-ignore
            console.warn('[!] getBreakpointsWithVelocity() error:', (e as Error).message, 'at', index, p1, p2);
            v = 20;
        }

        breakpoints.push({
            ...p1,
            velocity: v,
        });
    }

    return breakpoints;
};

/** @Bug quickfix - removes elements that have been pushed twice */
export const removeDuplicatedGPX = (
    arr: InterpolatedGpxTrace[],
): InterpolatedGpxTrace[] => {
    // return [...new Set<InterpolatedGpxTrace>(arr)];
    const unique: InterpolatedGpxTrace[] = [];
    arr.forEach((value, index, arr) => {
        if (arr[index + 1] && arr[index + 1].distance !== value.distance)
            unique.push(value);
    });

    unique.push(arr.at(-1) as InterpolatedGpxTrace);

    return unique;
};

export const getInterpolatedGpx = (
    gpx: GpxTrace[],
    notNullIndexes: number[],
): InterpolatedGpxTrace[] => {
    const interpolatedPoints: InterpolatedGpxTrace[] = [];

    /* Iterate over all indexes found */
    for (let index = 0; index < notNullIndexes.length; ++index) {
        // skip at the end of for loop
        if (index + 1 === notNullIndexes.length) {
            continue;
        }

        const p1Index = notNullIndexes[index];
        const p2Index = notNullIndexes[index + 1];
        const p1 = gpx[p1Index];
        const p2 = gpx[p2Index];

        if (typeof p1.timestamp === "undefined" || p1.timestamp === null)
            throw new Error("Undefined timestamp p1");
        if (typeof p2.timestamp === "undefined" || p2.timestamp === null)
            throw new Error("Undefined timestamp p2");

        let v: number;
        try {
            // prettier-ignore
            v = getAverageVelocityBetweenAB(p1.distance, p2.distance, p1.timestamp, p2.timestamp);
        } catch (e) {
            // prettier-ignore
            console.warn('[!] getInterpolatedGpx() error:', (e as Error).message, 'at', index, p1, p2);
            v = 20;
        }

        // prettier-ignore
        // console.log("[AVG] between",p1.distance.toFixed(3),"km and",p2.distance.toFixed(3),"km velocity is",v.toFixed(3),"km/h");

        // add first point to interpolated points
        interpolateTimestampAndPush(p1, p1.timestamp, 0, interpolatedPoints);

        // start iterating through all the gpx points inbetween p1 and p2
        for (
            let innerIndex = p1Index + 1;
            innerIndex <= p2Index;
            ++innerIndex
        ) {
            //
            const point = gpx[innerIndex];

            const deltaTime = getExpectedTimeForDistance(p1, point, v);

            interpolateTimestampAndPush(
                point,
                p1.timestamp,
                deltaTime,
                interpolatedPoints,
            );
        }
    }

    /* @BUG @To-Do This is a quick-fix for duplicate points with timestamp field in the array.
     Above forloop pushes the same point at the end of 1 iteration and at the beginning of the next */
    const cleanedInterpolatedPoints: InterpolatedGpxTrace[] =
        removeDuplicatedGPX(interpolatedPoints);

    return cleanedInterpolatedPoints;
};
