import React from "react";

interface LocationProps {
    country: string;

    location: string;

    flagUrl?: string;
}
const TrackLocation = ({
    country,
    location,
    flagUrl,
}: LocationProps): JSX.Element => {
    return (
        <div className="flex al-c tracklocation">
            {!!flagUrl && !!flagUrl.length && (
                <img className="icon" src={flagUrl} alt={country} />
            )}

            <p className="text text-s text-w300 place">
                {country} - {location}
            </p>
        </div>
    );
};

export default TrackLocation;
