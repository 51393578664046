import * as flagDecoders from "./decoders.ftms";
import { ftmsFieldSize } from "./fieldSizes.ftms";

export const speedIndex = (flags?: number): number => ftmsFieldSize.Flags;

export const cadenceIndex = (flags: number): number => {
    let index = ftmsFieldSize.Flags; // flags offset
    if (flagDecoders.instSpeedPresent(flags))
        index += ftmsFieldSize.InstantaneousSpeed;
    if (flagDecoders.avgSpeedPresent(flags)) index += ftmsFieldSize.AvgSpeed;
    return index;
};

export const distanceIndex = (flags: number): number => {
    let index = cadenceIndex(flags);
    if (flagDecoders.cadencePresent(flags))
        index += ftmsFieldSize.InstantaneousCadence;
    if (flagDecoders.avgCadencePresent(flags))
        index += ftmsFieldSize.AvgCadence;
    return index;
};

export const powerIndex = (flags: number): number => {
    let index = distanceIndex(flags);
    if (flagDecoders.distancePresent(flags))
        index += ftmsFieldSize.TotalDistance;
    if (flagDecoders.resistancePresent(flags))
        index += ftmsFieldSize.ResistanceLevel;
    return index;
};

export const elapsedTimeIndex = (flags: number): number => {
    let index = powerIndex(flags);
    if (flagDecoders.powerPresent(flags))
        index += ftmsFieldSize.InstantaneousPower;
    if (flagDecoders.avgPowerPresent(flags)) index += ftmsFieldSize.AvgPower;
    if (flagDecoders.expandedEnergy(flags))
        index +=
            ftmsFieldSize.TotalEnergy +
            ftmsFieldSize.EnergyPerH +
            ftmsFieldSize.EnergyPerM;
    if (flagDecoders.heartRatePresent(flags)) index += ftmsFieldSize.HeartRate;
    if (flagDecoders.metabolicEqv(flags)) index += ftmsFieldSize.MetabolicEqv;
    return index;
};
