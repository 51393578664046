import React from "react";

import { TrackPanelVerbose } from "../../../components/TrackPanel";
import SectionTitle from "../../../components/SectionTitle";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";

import { ExpeditionsListData } from "../../../redux/expeditions/expeditions.interfaces";

import logo from "../../../assets/imgs/track-img.png";

interface ListProps {
    items: ExpeditionsListData[];

    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    currentPage?: number;

    totalPages?: number;

    title: string;

    callback: (page?: number) => void;
}

const ExpeditionsList = ({
    items,
    isLoading,
    error,
    errorMessage,
    title,
    callback,
}: ListProps): JSX.Element => {
    return (
        <div className="expeditions-list">
            <SectionTitle title={title} />

            <div className="flex column al-c inner">
                {/* Track display */}
                {!!items.length && !error && !isLoading && (
                    <>
                        <div className="flex just-c column items-container">
                            {items.map((item) => (
                                <TrackPanelVerbose
                                    id={item.id}
                                    key={item.id}
                                    img={item.img}
                                    title={item.title}
                                    distance={item.distance}
                                    elevation={item.elevation}
                                    description={[item.shortDesc]}
                                    url={`/expedition/${item.id}`}
                                    routeText="Zobacz wyprawę"
                                    imageHoverText="Zobacz wyprawę"
                                />
                            ))}
                        </div>
                    </>
                )}

                {/* No tracks display */}
                {!items.length && !error && !isLoading && (
                    <p className="text text-ml">Brak wypraw do wyświetlenia.</p>
                )}

                {/* error display */}
                {error && (
                    <div className="flex column al-c just-c">
                        <p className="text text-ml margin-v1">{errorMessage}</p>

                        <Button
                            text="Spróbuj ponownie"
                            isPrimary
                            onClick={() => callback()}
                        />
                    </div>
                )}
                {/* loading in progress display */}
                {isLoading && !error && <Spinner />}
            </div>
        </div>
    );
};
export default ExpeditionsList;
