import { getNthBoolFromValue } from "../../../util/helpers.others";
import { ftmsFlagsDecoded } from "../definitions/subtypes.ftms";

export const instSpeedPresent = (flags: number): boolean =>
    !getNthBoolFromValue(flags, 0);

export const avgSpeedPresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 1);

export const cadencePresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 2);

export const avgCadencePresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 3);

export const distancePresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 4);

export const resistancePresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 5);

export const powerPresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 6);

export const avgPowerPresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 7);

export const expandedEnergy = (flags: number): boolean =>
    getNthBoolFromValue(flags, 8);

export const heartRatePresent = (flags: number): boolean =>
    getNthBoolFromValue(flags, 9);

export const metabolicEqv = (flags: number): boolean =>
    getNthBoolFromValue(flags, 10);

export const elapsedTime = (flags: number): boolean =>
    getNthBoolFromValue(flags, 11);

export const remainingTime = (flags: number): boolean =>
    getNthBoolFromValue(flags, 12);

export const flagsDecoder = (flags: number): ftmsFlagsDecoded => {
    const flagsDecoded = {} as ftmsFlagsDecoded;

    flagsDecoded.instSpeed = instSpeedPresent(flags);
    flagsDecoded.avgSpeed = avgSpeedPresent(flags);
    flagsDecoded.cadence = cadencePresent(flags);
    flagsDecoded.avgCadence = avgCadencePresent(flags);
    flagsDecoded.distance = distancePresent(flags);
    flagsDecoded.resistance = resistancePresent(flags);
    flagsDecoded.power = powerPresent(flags);
    flagsDecoded.avgPower = avgPowerPresent(flags);
    flagsDecoded.expandedEnergy = expandedEnergy(flags);
    flagsDecoded.heartrate = heartRatePresent(flags);
    flagsDecoded.metabolicEqv = metabolicEqv(flags);
    flagsDecoded.elapsedTime = elapsedTime(flags);
    flagsDecoded.remainingTime = remainingTime(flags);

    return flagsDecoded;
};
