import React, { SVGProps } from "react";
import { Link } from "react-router-dom";

type textSize = "s" | "sm" | "m" | "ml" | "l" | "xl" | "xxl";

export interface ButtonProps {
    alternative?: boolean;

    buttonClassname?: string;

    bold?: boolean;

    href?: string;

    iconClassname?: string;

    iconFit?: boolean;

    Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;

    italic?: boolean;

    isDisabled?: boolean;

    isPrimary?: boolean;

    hide?: boolean;

    light?: boolean;

    onClick?: (arg: never) => void;

    text?: string;

    textClassname?: string;

    textSize?: textSize;

    thin?: boolean;

    uppercase?: boolean;

    reloadDocument?: boolean;
}

const Button = ({
    alternative = false,
    buttonClassname = "",
    bold = false,
    iconFit = false,
    hide = false,
    href,
    iconClassname = "",
    Icon = undefined,
    italic = false,
    isDisabled = false,
    isPrimary = false,
    light = false,
    onClick = undefined,
    text,
    textClassname = "",
    textSize = "sm",
    thin = false,
    uppercase = false,
    reloadDocument = false,
}: ButtonProps): JSX.Element => (
    <Link
        type="button"
        className={`button flex ${buttonClassname} ${
            isPrimary ? "primary" : "secondary"
        } ${alternative ? "alternative" : ""} ${uppercase ? "caps" : ""} ${
            light ? "light" : ""
        } ${iconFit ? "icon-fit" : ""}`}
        to={href && !isDisabled ? href : ""}
        onClick={isDisabled ? () => null : onClick}
        style={hide ? { display: "none" } : {}}
        reloadDocument={reloadDocument}
    >
        {text && (
            <p
                className={`text text-${textSize} ${textClassname} ${
                    bold ? "text-w600" : ""
                } ${italic ? "text-i" : ""} ${thin ? "text-w300" : ""}`}
            >
                {text}
            </p>
        )}

        {Icon && <Icon className={iconClassname} />}
    </Link>
);

export default Button;
