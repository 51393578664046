import React from "react";
import ImagePanel from "../ImagePanel";
import TrackStat from "../TrackStat";

import { TrackPanelProps } from "./TrackPanel";

import { getElevationChange } from "./getElevationChange";

import {
    ArrowRight,
    DistanceIcon,
    ElevationIcon,
} from "../../assets/icons/index.icons";
import Button from "../Button";

interface VerbosePanelProps extends TrackPanelProps {
    routeText?: string;

    imageHoverText?: string;
}
const TrackPanelVerbose = ({
    description = [],
    distance = 0,
    elevation = 0,
    id,
    img,
    title = "",
    url = "",
    routeText,
    imageHoverText,
}: VerbosePanelProps): JSX.Element => (
    <div className="flex trackpanel-verbose">
        <div className="flex image-wrapper">
            <ImagePanel
                src={img}
                url={url}
                alternative
                title={imageHoverText || "Zobacz trasę"}
            />
        </div>
        <div className="content-wrapper">
            <div className="content">
                <div className="flex data-wrapper">
                    <h3 className="text text-ml text-w500 text-i title">
                        {title}
                    </h3>

                    <div className="flex data">
                        <TrackStat
                            Icon={DistanceIcon}
                            value={distance}
                            unit="km"
                            valueClassname="value"
                            unitClassname="unit"
                            valueUnitWrapperClassname="value-unit"
                            wrapperClassname="stat"
                        />

                        <TrackStat
                            Icon={ElevationIcon}
                            value={getElevationChange(elevation).value}
                            unit={getElevationChange(elevation).unit}
                            valueClassname="value"
                            unitClassname="unit"
                            valueUnitWrapperClassname="value-unit"
                            wrapperClassname="stat"
                        />
                    </div>
                </div>

                <div className="description-wrapper">
                    {description && Array.isArray(description)
                        ? description.map((item, index) => (
                              <p
                                  className="text text-sm description"
                                  key={index}
                              >
                                  {item}
                              </p>
                          ))
                        : description}
                </div>

                <div className="btn-wrapper">
                    <Button
                        text={routeText || "Zobacz trasę"}
                        uppercase
                        Icon={ArrowRight}
                        href={url}
                        iconClassname="icon"
                        textClassname="text"
                        buttonClassname="btn"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default TrackPanelVerbose;
