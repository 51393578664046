import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import {
    expeditionCleanup,
    expeditionFetchRequest,
    expeditionTracksRequest,
} from "../../../redux/expedition/expedition.actions";
import {
    TracksInExpedition,
    ExpeditionDetails,
    VideoDescription,
    Footer,
} from "../../parts";

const ExpeditionPage = (): JSX.Element => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const expeditionDescription = useAppSelector(
        (state) => state.expedition.expedition.description,
    );

    const refetchCallback = (): void => {
        if (typeof id !== "undefined") {
            dispatch(expeditionTracksRequest({ id }));
        }
    };

    const cleanupExpeditionData = (): void => {
        dispatch(expeditionCleanup());
    };

    useEffect(() => {
        if (typeof id !== "undefined") {
            dispatch(expeditionFetchRequest({ id }));
            dispatch(expeditionTracksRequest({ id }));
            window.scrollTo(0, 0);
        }
    }, [id]);

    useEffect(() => {
        const hash = location.hash;
        if (hash && document.getElementById(hash.substring(1))) {
            // Check if there is a hash and if an element with that id exists
            document
                .getElementById(hash.substring(1))
                ?.scrollIntoView({ behavior: "smooth" });
        }
    }, [location]);

    useEffect(() => {
        cleanupExpeditionData();

        document
            .getElementById("wt-header")
            ?.scrollIntoView({ behavior: "smooth" });

        return () => cleanupExpeditionData();
    }, []);
    return (
        <>
            <ExpeditionDetails />

            <VideoDescription
                title="Opis wyprawy"
                description={expeditionDescription}
                errorMessage="Błąd pobierania opisu wyprawy."
            />

            <TracksInExpedition refetchCallback={refetchCallback} />

            <Footer />
        </>
    );
};

export default ExpeditionPage;
