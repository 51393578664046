import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import TitleBar from "../../../components/TitleBar";
import { useAppSelector } from "../../../hooks";
import { poiCleanup, poiDwnl } from "../../../redux/poi/poi.actions";

import {
    videoCleanup,
    videoFetchRequest,
    videoSpotsRequest,
} from "../../../redux/video/video.actions";
import { Footer, VideoDescription } from "../../parts";
import VideoDetails from "../../parts/video/VideoDetails";

const VideoPage = (): JSX.Element => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const videoData = useAppSelector((state) => ({
        title: state.video.videoData.title,
        description: state.video.videoData.description,
        expeditionID: state.video.videoData.expeditionId,
    }));

    const cleanupVideoData = (): void => {
        dispatch(videoCleanup());
        dispatch(poiCleanup());
    };
    useEffect(() => {
        if (typeof id !== "undefined") {
            dispatch(videoFetchRequest({ id }));
            dispatch(videoSpotsRequest({ id }));
            dispatch(poiDwnl({ id }));

            window.scrollTo(0, 0);
        }
    }, [id]);

    useEffect(() => {
        cleanupVideoData();

        return () => cleanupVideoData();
    }, []);
    return (
        <div className="videopage">
            <TitleBar title={videoData.title} />

            <VideoDetails />

            <VideoDescription
                title="Opis trasy"
                description={videoData.description}
            />
            {videoData.expeditionID &&
                typeof videoData.expeditionID === "string" && (
                    <Button
                        text="Zobacz inne trasy z tej wyprawy"
                        isPrimary
                        bold
                        uppercase
                        href={`/expedition/${videoData.expeditionID}#expedition-tracks`}
                        buttonClassname="more-btn"
                    />
                )}

            <Footer />
        </div>
    );
};
export default VideoPage;
