import { createReducer } from "@reduxjs/toolkit";
import { InterpolatedGpxTrace } from "../video/video.interfaces";
import {
    trainerGradeSet,
    trainerNextGpxSet,
    trainerSlopeUpdate,
} from "./trainer.actions";

interface TrainerState {
    prevGpx: InterpolatedGpxTrace;
    nextGpx: InterpolatedGpxTrace;

    grade: number;

    // lastUpdate: Date;
}

const initialGpx: InterpolatedGpxTrace = {
    lat: 0,
    lon: 0,
    ele: 0,
    distance: 0,
    interpolatedTimestamp: 0,
};

const initialState: TrainerState = {
    grade: 0,

    nextGpx: initialGpx,

    prevGpx: initialGpx,
    // lastUpdate: new Date(),
};

const trainerReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(trainerGradeSet, (state, action) => {
            state.grade = action.payload.grade;
        })
        .addCase(trainerNextGpxSet, (state, action) => {
            state.nextGpx = action.payload.nextGpx;
        })
        .addCase(trainerSlopeUpdate, (state, action) => {
            state.grade = action.payload.grade;
            state.prevGpx = action.payload.prevGpx;
            state.nextGpx = action.payload.nextGpx;
        });
});

export default trainerReducer;
