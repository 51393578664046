import { createReducer } from "@reduxjs/toolkit";
import {
    videoAvgVelocitySet,
    videoCleanup,
    videoFetchError,
    videoFetchRequest,
    videoFetchSuccess,
    videoGpxBreakpointSet,
    videoGpxParse,
    videoGpxParsingRequest,
    videoSpotsError,
    videoSpotsSuccess,
} from "./video.actions";
import {
    GpxBreakpoint,
    InterpolatedGpxTrace,
    VideoData,
    VideoSpot,
} from "./video.interfaces";

interface VideoState {
    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    videoData: VideoData;

    videoAvgVelocity: number;

    // adjusted to the video length and specificity gpx trace
    videoAdjustedGpx: InterpolatedGpxTrace[];

    // important gpx points with timestamps used to calculate avg velocity
    videoGpxBreakpoints: GpxBreakpoint[];

    videoAdjustedGpxLoading: boolean;

    spots: VideoSpot[];

    spotsError: boolean;

    spotsMessage: string;
}

const initVideoData: VideoData = {
    id: "",
    title: "",
    country: "",
    location: "",
    distance: 0,
    elevation: 0,
    videoURL: "",
    gpxTrace: [],
    mapMarkers: [],
    description: {
        heading: [],
        features: [],
        paragraphs: [],
    },
    flagUrl: "",
};
const initialState: VideoState = {
    isLoading: false,

    error: false,

    errorMessage: "",

    videoData: {
        id: "",
        title: "",
        country: "",
        location: "",
        distance: 0,
        elevation: 0,
        videoURL: "",
        gpxTrace: [],
        mapMarkers: [],
        description: {
            heading: [],
            features: [],
            paragraphs: [],
        },
        flagUrl: "",
    },

    videoAvgVelocity: 20,
    videoAdjustedGpxLoading: false,
    videoAdjustedGpx: [],
    videoGpxBreakpoints: [],

    spots: [],
    spotsError: false,
    spotsMessage: "",
};

const videoReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(videoFetchRequest, (state) => {
            state.isLoading = true;
            state.error = false;
            state.errorMessage = "";
        })
        .addCase(videoFetchError, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.detail;
            state.videoData = initVideoData;
        })
        .addCase(videoFetchSuccess, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMessage = "";
            state.videoData = action.payload;
        })
        .addCase(videoSpotsSuccess, (state, action) => {
            state.spots = action.payload;
            state.spotsError = false;
            state.spotsMessage = "";
        })
        .addCase(videoSpotsError, (state, action) => {
            state.spots = [];
            state.spotsError = true;
            state.spotsMessage = action.payload;
        })
        .addCase(videoCleanup, (state) => {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.errorMessage = initialState.errorMessage;
            state.videoData = initialState.videoData;
            state.spots = initialState.spots;
            state.spotsError = initialState.spotsError;
            state.spotsMessage = initialState.spotsMessage;
            state.videoAdjustedGpx = initialState.videoAdjustedGpx;
            state.videoAdjustedGpxLoading = false;
            state.videoAvgVelocity = initialState.videoAvgVelocity;
            state.videoGpxBreakpoints = initialState.videoGpxBreakpoints;
        })
        .addCase(videoAvgVelocitySet, (state, action) => {
            state.videoAvgVelocity = action.payload;
        })
        .addCase(videoGpxParsingRequest, (state) => {
            state.videoAdjustedGpxLoading = true;
            state.videoAdjustedGpx = [];
        })
        .addCase(videoGpxParse, (state, action) => {
            state.videoAdjustedGpxLoading = false;
            state.videoAdjustedGpx = action.payload;
        })
        .addCase(videoGpxBreakpointSet, (state, action) => {
            state.videoGpxBreakpoints = action.payload;
        });
});

export default videoReducer;
