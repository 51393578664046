import { AxiosResponse } from "axios";
import { SagaReturnType } from "redux-saga/effects";

import { NetworkModule } from "../../services";
import { VideoData, VideoError, VideoSpotResponse } from "./video.interfaces";

export type VideoFetchDataType = SagaReturnType<
    typeof NetworkModule.getVideoData
>;

export type VideoSpotFetchDataType = SagaReturnType<
    typeof NetworkModule.getVideoSpots
>;

export const responseIsVideoData = (
    response: VideoFetchDataType,
): response is AxiosResponse<VideoData> => {
    return (
        typeof (response as AxiosResponse<VideoData>).data.id !== "undefined" &&
        typeof (response as AxiosResponse<VideoError>).data.detail ===
            "undefined"
    );
};

export const responseIsVideoError = (
    response: VideoFetchDataType,
): response is AxiosResponse<VideoError> => {
    return (
        typeof (response as AxiosResponse<VideoData>).data.id === "undefined" &&
        typeof (response as AxiosResponse<VideoError>).data.detail !==
            "undefined"
    );
};

export const responseIsSpotResponse = (
    response: VideoSpotFetchDataType,
): response is AxiosResponse<VideoSpotResponse> => {
    return (
        typeof (response as AxiosResponse<VideoSpotResponse>).data.spots !==
            "undefined" &&
        typeof (response as AxiosResponse<VideoError>).data.detail ===
            "undefined"
    );
};

export const responseIsSpotError = (
    response: VideoSpotFetchDataType,
): response is AxiosResponse<VideoError> => {
    return (
        typeof (response as AxiosResponse<VideoSpotResponse>).data.spots ===
            "undefined" &&
        typeof (response as AxiosResponse<VideoError>).data.detail !==
            "undefined"
    );
};
