/**
 * Checks 0-1 value on the nth bit in field
 * @param field value to be checked
 * @param bit bit of the value to be checked
 * @returns true if flag is set, false otherwise
 */
export const checkFlag = (field: number, bit: number): boolean => {
    const movedBit = (field >> bit) & 1;
    return !!movedBit;
};

/**
 * Converts decimal number to binary (01010) string
 * @param decimal value to be converted
 * @returns
 */
export const dec2bin = (decimal: number): string => (decimal >>> 0).toString(2);

/**
 * Round to .5 values
 * @param value number to be rounded
 * @returns rounded number
 */
export const roundHalf = (value: number): number => Math.round(value * 2) / 2;

/**
 * Extracts a flag from value
 * @param value
 * @param offset
 * @returns bit
 */
// prettier-ignore
const getOffsetBit = (value: number, offset: number): number => (value >> offset) & 1;

/**
 * Casts numeric value 0 or 1 to boolean
 * @param bit
 * @returns
 */
const getBoolFromBit = (bit: number): boolean => !!bit;

/**
 * Checks true-false on the nth bit in numeric value
 * @param value
 * @param offset
 * @returns
 */
export const getNthBoolFromValue = (value: number, offset: number): boolean =>
    getBoolFromBit(getOffsetBit(value, offset));

/**
 * Converts string to boolean value
 * Uses comparison to true and 1 strings
 * @param value
 * @returns true if string equals true or 1
 */
export const toBool = (value?: string): boolean =>
    value?.toLowerCase() === "true" || value === "1";

export const deserializeFunction = (funcString: string) =>
    new Function(`return ${funcString}`)();
