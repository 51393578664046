import { GpxTrace } from "../../../redux/video/video.interfaces";
import { GpxLatLonArray, LatLonTuple } from "./definitions";

export const gpxTraceIsTupleArray = (
    gpxTrace: GpxTrace[] | GpxLatLonArray,
): gpxTrace is GpxLatLonArray => {
    return typeof (gpxTrace as GpxTrace[])[0].distance === "undefined";
};

export const gpxTraceIsTuple = (
    gpxTrace: GpxTrace | LatLonTuple,
): gpxTrace is LatLonTuple => {
    return typeof (gpxTrace as GpxTrace).distance === "undefined";
};
