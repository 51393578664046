import React from "react";
import Button from "../Button";
import Lines from "../Lines";

interface TitleBarProps {
    title?: string;

    wrapperClassname?: string;

    titleClassname?: string;

    buttonVisible?: boolean;

    buttonText?: string;

    buttonUrl?: string;
}

const TitleBar = ({
    title = "",
    titleClassname = "",
    wrapperClassname = "",
    buttonUrl,
    buttonText,
    buttonVisible,
}: TitleBarProps): JSX.Element => (
    <div className={`titlebar ${wrapperClassname}`}>
        <h1 className={`text-l text-w500 title ${titleClassname}`}>{title}</h1>

        <Lines
            numberOfLines={20}
            angle={45}
            marginLeft={48}
            scaleY={4}
            scaleX={0.25}
            wrapperClassname="lines top"
            colorHex="fff"
        />

        <Lines
            numberOfLines={8}
            angle={45}
            marginLeft={48}
            scaleY={4}
            scaleX={0.25}
            wrapperClassname="lines bot"
            colorHex="fff"
        />

        {buttonVisible && (
            <Button
                isPrimary
                text={buttonText}
                uppercase
                bold
                buttonClassname="no-border titlebutton"
                href={buttonUrl}
            />
        )}
    </div>
);

export default TitleBar;
