import { createAction } from "@reduxjs/toolkit";
import {
    TrackPageFetchError,
    TrackPageFetchSuccess,
    TracksFetchAllSuccess,
    // TracksFetchMore,
    // TracksFetchWithFilter,
    TracksFilterFetch,
} from "./tracks.interfaces";

// export const trackPageFetchRequest =
//     createAction<TrackPageRequest>("track/page-request");

// export const trackPageFetchLoading = createAction("track/page-fetch-loading");

// export const trackPageFetchSuccess = createAction<TrackPageFetchSuccess>(
//     "track/page-fetch-success",
// );

// newest
export const tracksFetchNewest = createAction("tracks/fetch-newest");

export const tracksFetchNewestSuccess = createAction<TrackPageFetchSuccess>(
    "tracks/fetch-newest-success",
);

export const tracksFetchNewestError = createAction<TrackPageFetchError>(
    "tracks/fetch-newest-error",
);

// popular
export const tracksFetchPopular = createAction("tracks/fetch-popular");

export const tracksFetchPopularSuccess = createAction<TrackPageFetchSuccess>(
    "tracks/fetch-popular-success",
);

export const tracksFetchPopularError = createAction<TrackPageFetchError>(
    "tracks/fetch-popular-error",
);

export const tracksFetchSuccess = createAction<TracksFetchAllSuccess>(
    "tracks/fetch-all-success",
);

export const tracksFetchMoreSuccess = createAction<TracksFetchAllSuccess>(
    "tracks/fetch-more-success",
);

export const tracksFetchFail = createAction<string>("tracks/fetch-fail");

//
//
//
export const tracksFilterFetch = createAction<TracksFilterFetch>(
    "tracks/filter-track-fetch",
);

// a form submit with changed filters has happened -> previous tracks need to be removed
export const tracksFilterReset = createAction("tracks/filter-reset");
