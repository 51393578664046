import { FtmsMessageType } from "../types/messageTypes";
import { ftmsPageParser } from "./ftms/pageParser.ftms";
import { tacxParserSwitch } from "./tacx/parserSwitch.tacx";
import { TacxMessageType } from "../types/messageTypes";

export const messageParser = (
    buffer: number[],
    tacxModeOverride?: boolean,
): // eslint-disable-next-line max-len
TacxMessageType | FtmsMessageType | undefined => {
    /* If in Tacx Mode => use tacx message parser */
    // if (BluetoothModule.inTacxMode || tacxModeOverride === true) {
    if (tacxModeOverride) {
        const { msg } = tacxParserSwitch(buffer);

        if (typeof msg !== "undefined") return msg;

        /* if not in tacx mode, parse using FTMS page parser */
    } else {
        const resp = ftmsPageParser(buffer);
        return resp;
    }

    return undefined;
};
