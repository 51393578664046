import { distanceUnit } from "../../common";

interface distanceChange {
    value: number;

    unit: distanceUnit;
}

export const getElevationChange = (distance: number): distanceChange => {
    const change: distanceChange = {} as distanceChange;

    if (distance < 1000) {
        change.value = distance;
        change.unit = "m";
    } else {
        change.value =
            Math.round((distance / 1000 + Number.EPSILON) * 100) / 100;
        change.unit = "km";
    }

    return change;
};
