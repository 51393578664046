import React from "react";
import ControlBar from "../../../components/ControlBar";
import Heightmap from "../../../components/Heightmap";

interface ControlsProps {
    isInBluetoothMode: boolean;

    seekTo: (seconds: number) => void;

    seekToPoi: (id: string) => void;

    isLoading?: boolean;
}
const VideoControls = ({
    isInBluetoothMode,
    seekTo,
    seekToPoi,
    isLoading = false,
}: ControlsProps): JSX.Element => {
    return (
        <div
            className="flex column just-sp video-controls"
            style={{ display: isLoading ? "none" : "" }}
        >
            <ControlBar
                seekCallback={seekTo}
                isInBluetoothMode={isInBluetoothMode}
            />

            <Heightmap seekToPoi={seekToPoi} isLoading={isLoading} />
        </div>
    );
};

VideoControls.displayName = "VideoControls";

export default VideoControls;
