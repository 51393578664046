import React from "react";

const ContactPage = (): JSX.Element => (
    <div className="page-trainer margin-v2">
        <div className="flex just-c al-c">
            <p className="text text-m text-w400">
                Zapraszamy do współpracy: <br></br>
                <br></br> - Przy nagrywaniu tras <br></br>
                <br></br>- Do umieszczania reklam
                <br></br>
                <br></br>- Do współpracy programistycznej
                <br></br>
                <br></br>- Do współpracy przy obróbce wideo <br></br>
                <br></br>
                Kontakt:{" "}
                <a
                    href="mailto:wirtualnetrasy@infal.pl"
                    className="text text-red"
                >
                    <i>wirtualnetrasy@infal.pl</i>
                </a>
                <br></br>
                Wszystkie prawa zastrzeżone <br></br> Piotr Falc
            </p>
        </div>
    </div>
);

export default ContactPage;
