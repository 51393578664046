import React from "react";
import { Link } from "react-router-dom";

interface PanelProps {
    alt?: string;

    alternative?: boolean;

    author?: string;

    description?: string;

    italicTitle?: boolean;

    src: string;

    title?: string;

    url?: string;
}

const ImagePanel = ({
    alt,
    alternative = false,
    author,
    description,
    italicTitle = false,
    src,
    title,
    url,
}: PanelProps): JSX.Element => (
    <Link
        className={`imgpanel${alternative ? " alternative" : ""}`}
        to={url || src}
    >
        <img src={src} alt={alt} className="image" />

        <div className="sub">
            <div className="subcontent">
                <div className="panel">
                    <h4
                        className={`text text-ml text-w600 tracktitle base ${
                            italicTitle ? "text-i" : ""
                        }`}
                    >
                        {title}
                    </h4>
                    <p className="text text-sm description base">
                        {description}
                    </p>
                    <h5 className="text text-s author base">{author}</h5>
                </div>
            </div>
        </div>
    </Link>
);

export default ImagePanel;
