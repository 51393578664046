import { createDataView } from "../../../util/helpers.dataview";
import { commandCodesTacx } from "../../ccodes";

/**
 * Normalizes grade to proper values
 * @param grade inclination/declination in % (-100%, 100%) values expected
 * @returns normalized grade ready to be sent to TACX
 */
export const normalizeGrade = (grade: number): number => {
    let gradeInRange = grade;

    /* max values check */
    if (grade > 200) gradeInRange = 200;
    else if (grade < -200) gradeInRange = -200;
    // else gradeInRange = grade * 2;

    return 20000 + gradeInRange * 100;
};

/**
 * Calculates checksum for given command
 * @param view command with all fields filled
 * @returns checksum
 */
const checksum = (view: DataView): number => {
    let cs = 0;

    for (let i = 0; i < view.byteLength; i += 1) {
        // eslint-disable-next-line no-bitwise
        cs ^= view.getUint8(i);
    }

    return cs;
};

/**
 * Prepares 0x33 (Page51 == GRADE) command content
 * @param grade inclination/declination in %
 * @returns command content as DataView
 */
const dataPage0x33 = (grade: number): DataView => {
    const dataPage = 51;
    const normalizedGrade = normalizeGrade(grade);
    const view = createDataView(8);

    view.setUint8(0, dataPage);
    view.setUint16(5, normalizedGrade, true);
    view.setUint8(7, 0); // crr defaults to 0

    return view;
};

/**
 * Creates TACX command
 * @param command command buffer
 * @param channel defaults to 5
 * @returns command body ready to be sent to tacxk
 */
const antMessage = (command: DataView, channel = 5): DataView => {
    const sync = 164;
    const length = 9;
    const type = 79;
    const view = createDataView(13);

    view.setUint8(0, sync);
    view.setUint8(1, length);
    view.setUint8(2, type);
    view.setUint8(3, channel);

    // offset for command content
    const offset = 4;
    for (let i = 0; i < 8; i += 1) {
        view.setUint8(i + offset, command.getUint8(i));
    }

    const cs = checksum(view);
    view.setUint8(12, cs);

    return view;
};

/**
 * Handles command creation for tacx trainers
 * @param command tacx command op_code
 * @param value used as an argument for the command
 * @returns command buffer ready to be sent
 */
export const tacxCommandHandler = (command: number, value = 0): DataView => {
    switch (command) {
        case commandCodesTacx.TRACK_RESISTANCE:
            return antMessage(dataPage0x33(value));
        default:
            return createDataView(0);
    }
};
