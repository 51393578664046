import React from "react";
import { useDispatch } from "react-redux";
import { ArrowDown } from "../../assets/icons/index.icons";
import {
    FullscreenIcon,
    PauseIcon,
    PlayIcon,
    SkipBackwardIcon,
    SkipForwardIcon,
    SpeedIcon1,
    SpeedIcon2,
    SpeedIcon3,
    WhiteWTLogo,
    FlatRoadOnIcon,
    FlatRoadOffIcon,
} from "../../assets/video-ui";
import { useAppSelector } from "../../hooks";
import {
    controlsChangePlaybackRate,
    controlsSetFullscreen,
    controlsToggleFullscreen,
    controlsTogglePlay,
    controlsToggleMakeFlat,
} from "../../redux/controls/controls.actions";
import {
    PlaybackState,
    PlaybackID,
} from "../../redux/controls/controls.interfaces";
import { getSeekTime } from "./util/getSeekTime";
import { playbackRate } from "./util/playbackRates";

interface ControlsProps {
    seekCallback: (seconds: number) => void;

    isInBluetoothMode: boolean;
}

const ControlBar = ({
    seekCallback,
    isInBluetoothMode,
}: ControlsProps): JSX.Element => {
    const controlState = useAppSelector((state) => state.control);
    const dispatch = useDispatch();
    const SEEK_SECONDS = 30;

    const changePlayback = (id: PlaybackID, value: number): void => {
        const newPlayback: PlaybackState = {
            id,
            rate: value,
        };
        dispatch(controlsChangePlaybackRate(newPlayback));
    };
    /** Returns CSS Classes for playback-rate icons */
    const getSpeedClasses = (id: PlaybackID): string => {
        if (id === controlState.playback.id) return "icon speed active";
        else return "icon speed";
    };
    /**  */
    const isSpeedDisabled = (id: PlaybackID): boolean =>
        id === controlState.playback.id;

    const requestFullscreen = (): void => {
        if (controlState.isFullscreen) {
            // exit fullscreen in a safari way
            if (
                typeof (document as any).webkitCancelFullScreen === "function"
            ) {
                (document as any)?.webkitCancelFullScreen();
            } else {
                document
                    .exitFullscreen()
                    .catch((e) => console.warn("Błąd wychodzenia z FS", e));
            }
            dispatch(controlsToggleFullscreen());
        } else {
            const videoWrapper = document.querySelector("#video-wrapper");

            // enter fullscreen on our lovely little kiddo safari
            if (
                typeof (videoWrapper as any).webkitRequestFullScreen ===
                "function"
            ) {
                (videoWrapper as any)?.webkitRequestFullScreen();
            } else {
                videoWrapper?.requestFullscreen();
            }
            dispatch(controlsToggleFullscreen());
        }
    };

    document.addEventListener("fullscreenchange", () => {
        if (document.fullscreenElement === null)
            dispatch(controlsSetFullscreen(false));
    });

    return (
        <div className="flex control-bar">
            <div className="flex part al-c logo-wrapper">
                <WhiteWTLogo className="logo" />
            </div>

            {/* section of control bar that should be visible only when in regular mode (not connected to trainer) */}
            <div
                className="flex al-c part"
                style={{ display: isInBluetoothMode ? "none" : "inherit" }}
            >
                <div className="flex skip-wrapper">
                    <SkipBackwardIcon
                        className="icon bw"
                        onClick={() =>
                            seekCallback(
                                getSeekTime(
                                    -SEEK_SECONDS,
                                    controlState.progress.playedSeconds,
                                ),
                            )
                        }
                    />

                    {controlState && controlState.isPlaying ? (
                        <PauseIcon
                            className="icon bw"
                            onClick={() => dispatch(controlsTogglePlay())}
                        />
                    ) : (
                        <PlayIcon
                            className="icon bw"
                            onClick={() => dispatch(controlsTogglePlay())}
                        />
                    )}

                    <SkipForwardIcon
                        className="icon"
                        onClick={() =>
                            seekCallback(
                                getSeekTime(
                                    SEEK_SECONDS,
                                    controlState.progress.playedSeconds,
                                ),
                            )
                        }
                    />
                </div>

                <span className="separator" />

                <div className="flex">
                    <SpeedIcon1
                        className={getSpeedClasses("SLOW")}
                        onClick={() =>
                            isSpeedDisabled("SLOW")
                                ? null
                                : changePlayback("SLOW", playbackRate.SLOW)
                        }
                    />

                    <SpeedIcon2
                        className={getSpeedClasses("NORMAL")}
                        onClick={() =>
                            isSpeedDisabled("NORMAL")
                                ? null
                                : changePlayback("NORMAL", playbackRate.NORMAL)
                        }
                    />

                    <SpeedIcon3
                        className={getSpeedClasses("FAST")}
                        onClick={() =>
                            isSpeedDisabled("FAST")
                                ? null
                                : changePlayback("FAST", playbackRate.FAST)
                        }
                    />
                </div>
                <span className="separator" />
                {controlState && controlState.isFlat ? (
                        <FlatRoadOnIcon
                            className="icon bw"
                            onClick={() => dispatch(controlsToggleMakeFlat())}
                        />
                    ) : (
                        <FlatRoadOffIcon
                            className="icon bw"
                            onClick={() => dispatch(controlsToggleMakeFlat())}
                        />
                    )}
                <span className="separator" />

                <div className="flex">
                    <FullscreenIcon
                        className="icon fs"
                        onClick={() => requestFullscreen()}
                    />
                </div>
            </div>

            {/* section of control bar that should be visible only when in bluetooth mode */}
            <div
                className="flex al-c part"
                style={{ display: isInBluetoothMode ? "inherit" : "none" }}
            >
                <div className="flex">
                    {controlState && controlState.isFlat ? (
                        <FlatRoadOnIcon
                            className="icon fs"
                            onClick={() => dispatch(controlsToggleMakeFlat())}
                        />
                    ) : (
                        <FlatRoadOffIcon
                            className="icon fs"
                            onClick={() => dispatch(controlsToggleMakeFlat())}
                        />
                    )}
                </div>
                <span className="separator" />
                <div className="flex">
                    <FullscreenIcon
                        className="icon fs"
                        onClick={() => requestFullscreen()}
                    />
                </div>
            </div>
        </div>
    );
};

export default ControlBar;
