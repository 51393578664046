import React from "react";
import {
    ExpeditionDataDisplay,
    ExpeditionMap,
    ExpeditionStatus,
} from "../../../components/ExpeditionElements";
import Spinner from "../../../components/Spinner";
import TitleBar from "../../../components/TitleBar";
import { useAppSelector } from "../../../hooks";

const ExpeditionDetails = (): JSX.Element => {
    const expeditionState = useAppSelector(
        (state) => state.expedition.expedition,
    );
    const expeditionLoadState = useAppSelector((state) => ({
        loading: state.expedition.isLoading,
        error: state.expedition.error,
        errorMessage: state.expedition.errorMessage,
    }));

    return (
        <div className="expedition-details">
            <TitleBar
                title={`Wyprawa - ${expeditionState.title}`}
                buttonVisible
                buttonText="Wszystkie wyprawy"
                buttonUrl="/expeditions"
                wrapperClassname="flexible"
            />

            <div className="inner">
                <p className="text text-ml text-i watch">Obejrzyj trailer</p>

                <div className="flex content">
                    {/* DETAILS */}
                    <ExpeditionDataDisplay
                        title={expeditionState.title}
                        country={expeditionState.country}
                        location={expeditionState.location}
                        distance={expeditionState.distance}
                        elevation={expeditionState.elevation}
                        flagUrl={expeditionState.flagUrl}
                        hide
                    />
                    {/*  VIDEO */}
                    <div className="video-wrapper">
                        <video
                            className="video"
                            src={expeditionState.trailerUrl}
                            typeof="video/mp4"
                            controls={
                                !expeditionLoadState.loading &&
                                !expeditionLoadState.error &&
                                !!expeditionState.trailerUrl.length
                            }
                            muted
                            loop
                        />

                        {/* DISPLAY LOADING / ERROR OVERLAY when fetching or on fetch error */}
                        <div
                            className={`${
                                expeditionLoadState.loading ||
                                expeditionLoadState.error
                                    ? "loading-visible"
                                    : "loading-hidden"
                            }`}
                        >
                            {expeditionLoadState.loading &&
                                !expeditionLoadState.error && (
                                    <Spinner size="lg" />
                                )}

                            {!expeditionLoadState.loading &&
                                expeditionLoadState.error && (
                                    <p className="text text-ml text-ff">
                                        {expeditionLoadState.errorMessage}
                                    </p>
                                )}
                        </div>
                    </div>

                    {/* BUY PART */}
                    <ExpeditionStatus
                        hide
                        buyUrl={expeditionState.buyUrl}
                        allUrl="/expeditions"
                    />

                    {/* DETAILS & BUY - responsive */}
                    <div className="flex just-sp details-status">
                        <ExpeditionDataDisplay
                            title={expeditionState.title}
                            country={expeditionState.country}
                            location={expeditionState.location}
                            distance={expeditionState.distance}
                            elevation={expeditionState.elevation}
                            flagUrl={expeditionState.flagUrl}
                        />

                        <ExpeditionStatus
                            buyUrl={expeditionState.buyUrl}
                            allUrl="/expeditions"
                        />
                    </div>
                </div>

                {/* MAP */}
                <ExpeditionMap gpxTrace={expeditionState.gpxTrace} />
            </div>
        </div>
    );
};

export default ExpeditionDetails;
