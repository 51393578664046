export interface TimeStrings {
    elapsed: string;
    duration: string;
}

export const getTimeStrings = (
    elapsedTime: number,
    duration: number,
): TimeStrings => {
    const elapsedMinutes = Math.floor((elapsedTime % 3600) / 60)
        .toString()
        .padStart(2, "0");
    const elapsedSeconds = Math.floor(elapsedTime % 60)
        .toString()
        .padStart(2, "0");

    const durationMinutes = Math.floor((duration % 3600) / 60)
        .toString()
        .padStart(2, "0");
    const durationSeconds = Math.floor(duration % 60)
        .toString()
        .padStart(2, "0");

    // hours available
    const longFilm = !!Math.floor(duration / 3600);

    if (longFilm) {
        const elapsedHours = Math.floor(elapsedTime / 3600)
            .toString()
            .padStart(2, "0");
        const hasElapsedHours = !!Math.floor(elapsedTime / 3600);
        const durationHours = Math.floor(duration / 3600)
            .toString()
            .padStart(2, "0");

        return {
            // prettier-ignore
            elapsed: `${hasElapsedHours ? `${elapsedHours}:` : ''}${elapsedMinutes}:${elapsedSeconds}`,
            duration: `${durationHours}:${durationMinutes}:${durationSeconds}`,
        };
    }

    return {
        elapsed: `${elapsedMinutes}:${elapsedSeconds}`,
        duration: `${durationMinutes}:${durationSeconds}`,
    };
};
