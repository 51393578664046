import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import SectionTitle from "../../../components/SectionTitle";
import Spinner from "../../../components/Spinner";
import TrainerState from "../../../components/TrainerState";
import { useAppSelector } from "../../../hooks";
import {
    bluetoothConnect,
    bluetoothConnectionStateUpdate,
    bluetoothConnectPrepare,
    bluetoothDisconnect,
    bluetoothError,
    bluetoothMessageReceivedPrepare,
    bluetoothScan,
} from "../../../redux/bluetooth/bluetooth.actions";
import { bluetoothStates } from "../../../redux/bluetooth/bluetooth.util";
import { BluetoothModule } from "../../../services/bluetooth/BluetoothModule";
import { commandCodesFtms } from "../../../services/bluetooth/trainer/ccodes";
import { arrayFromDataView } from "../../../services/bluetooth/util/helpers.dataview";
import { toBool } from "../../../services/bluetooth/util/helpers.others";

const TrainerPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const bt = BluetoothModule.getInstance();
    const btState = useAppSelector((state) => state.bluetooth);
    const [devices, setDevices] = useState<BluetoothDevice[]>([]);

    const handleBluetoothError = (e: unknown): void => {
        if (typeof e === "string") dispatch(bluetoothError(e));
        else if (e instanceof Error) dispatch(bluetoothError(e.message));
        else {
            dispatch(
                bluetoothError("Nieznany błąd podczas szukania urządzeń."),
            );
        }
    };
    const addTrainerDeviceCallback = (device: BluetoothDevice): void => {
        setDevices([...devices, device]);
    };

    const beginScan = (): void => {
        dispatch(bluetoothScan());

        bt.scanTrainerDevices(addTrainerDeviceCallback);
    };

    const trainerMessageCallback = (buffer: DataView): void => {
        const initialParse = arrayFromDataView(buffer);
        dispatch(bluetoothMessageReceivedPrepare(initialParse));
    };
    const connect = (id: string): void => {
        const device = devices.find((item) => item.id === id);

        if (device) {
            dispatch(bluetoothConnectPrepare(device.id));
            bt.connectToTrainer(device)
                .then(() => {
                    dispatch(
                        bluetoothConnectionStateUpdate(
                            bluetoothStates.CONNECTING,
                        ),
                    );
                    return bt.checkTacxMode();
                })
                .then(() => {
                    dispatch(
                        bluetoothConnectionStateUpdate(bluetoothStates.UUIDS),
                    );
                    return bt.setBleUuids();
                })
                .then(() => {
                    dispatch(
                        bluetoothConnectionStateUpdate(bluetoothStates.GATT),
                    );
                    return bt.updateGattCharacteristics();
                })
                .then(() => {
                    dispatch(
                        bluetoothConnectionStateUpdate(
                            bluetoothStates.CALLBACK,
                        ),
                    );

                    return bt.setMessageCallback(trainerMessageCallback);
                })
                .then(() => {
                    dispatch(
                        bluetoothConnectionStateUpdate(bluetoothStates.SUB),
                    );

                    return bt.subscribeToMessages();
                })
                .then(() => {
                    dispatch(
                        bluetoothConnect({
                            id: device.id,
                            name: device.name,
                            isTacxDevice: bt.getTacxMode,
                        }),
                    );
                    dispatch(
                        bluetoothConnectionStateUpdate(
                            bluetoothStates.CONNECTED,
                        ),
                    );

                    setDevices([]);
                })
                .catch((e: unknown) => {
                    handleBluetoothError(e);
                    try {
                        bt.disconnectFromTrainer();
                    } catch (e: unknown) {
                        //
                    }
                });
        } else {
            dispatch(bluetoothError("Urządzenie nie może zostać znalezione."));
        }
    };

    const disconnect = (): void => {
        bt.disconnectFromTrainer()
            .then(() => {
                dispatch(bluetoothDisconnect());
            })
            .catch((e: unknown) => console.error(e));
    };

    const connectionStates = {
        yes: "Połączono z trenażerem",
        no: "Brak połączenia z trenażerem.",
        wip: "Nawiązywanie połączenia...",
    };

    const sendSlopeCommand = (val: number): void => {
        bt.sendCommand(commandCodesFtms.TARGET_SLOPE, val);
    };

    return (
        <div className="page-trainer margin-v2">
            {/* <h2 className="text text-l text-w500">Łączność z trenażerem</h2> */}
            <SectionTitle title="Łączność z trenażerem" />

            {/* content */}
            <div className="flex column al-c">
                {/* NOT CONNECTED */}
                {!btState.isConnected && !btState.isConnecting && (
                    <p className="text text-ml text-w400 margin-v2">
                        {connectionStates.no}
                    </p>
                )}

                {/* CONNECTION WIP */}
                {!btState.isConnected && btState.isConnecting && (
                    <>
                        <p className="text text-ml text-w400 margin-v2">
                            {btState.connectingState}
                        </p>
                        <Spinner />
                    </>
                )}

                {/* CONNECTED */}
                {btState.isConnected && (
                    <div className="flex column al-c">
                        <TrainerState />

                        <Button
                            text={"Rozłącz"}
                            isDisabled={!btState.isConnected}
                            buttonClassname={
                                btState.isScanning || btState.isConnecting
                                    ? "no-hover disabled scan-btn"
                                    : "scan-btn"
                            }
                            uppercase
                            bold
                            isPrimary
                            onClick={disconnect}
                            hide={!btState.isConnected}
                        />
                    </div>
                )}
            </div>

            {/* DEVICE SCAN & DISPLAY */}
            <div className="flex column al-c trainers">
                {btState.isScanning && (
                    <h4 className="text text-m text-w400">
                        Dostępne urządzenia:
                    </h4>
                )}
                {!!devices && !btState.isConnected && !btState.isConnecting && (
                    <div className="margin-v1">
                        {devices.map((item) => (
                            <Button
                                key={item.id}
                                text={item.name || item.id}
                                onClick={() => connect(item.id)}
                                isDisabled={
                                    btState.isConnecting || btState.isConnected
                                }
                                buttonClassname={
                                    btState.isConnecting || btState.isConnected
                                        ? "no-hover"
                                        : "device-btn"
                                }
                                bold
                                uppercase
                            />
                        ))}
                    </div>
                )}

                <div className="margin-v2">
                    <Button
                        text={btState.isScanning ? "skanowanie..." : "skanuj"}
                        isDisabled={btState.isScanning || btState.isConnecting}
                        buttonClassname={
                            btState.isScanning || btState.isConnecting
                                ? "no-hover disabled scan-btn"
                                : "scan-btn"
                        }
                        uppercase
                        bold
                        isPrimary
                        onClick={beginScan}
                        hide={btState.isConnected}
                    />
                </div>
            </div>

            {btState.error && (
                <h4 className="text text-ml text-w400 margin-v2">
                    Błąd Bluetooth: {btState.errorMessage}
                </h4>
            )}

            {btState.isConnected && (
                <div>
                    <Button
                        text="-5%"
                        bold
                        isPrimary
                        uppercase
                        onClick={() => sendSlopeCommand(-5)}
                    />
                    <Button
                        text="0%"
                        bold
                        isPrimary
                        uppercase
                        onClick={() => sendSlopeCommand(0)}
                    />
                    <Button
                        text="5%"
                        bold
                        isPrimary
                        uppercase
                        onClick={() => sendSlopeCommand(5)}
                    />
                </div>
            )}

            {!toBool(process.env.REACT_APP_DESKTOP_APP) && (
                <>
                    <SectionTitle title="Aplikacja offline - windows" />

                    <div className="flex al-c just-c">
                        <Button
                            isPrimary
                            bold
                            uppercase
                            text="pobierz"
                            href={process.env.REACT_APP_DEKSTOP_APP_URL}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
export default TrainerPage;
