import { createAction } from "@reduxjs/toolkit";
import {
    TrainerGradeSet,
    TrainerGradeUpdateRequest,
    TrainerNextGPXSet,
    TrainerSlopeUpdate,
} from "./trainer.interfaces";

// prettier-ignore
export const trainerGradeUpdateRequest = createAction<TrainerGradeUpdateRequest>("trainer/grade-update-request");

export const trainerGradeSet =
    createAction<TrainerGradeSet>("trainer/grade-set");

// prettier-ignore
export const trainerNextGpxSet = createAction<TrainerNextGPXSet>("trainer/next-gpx-set");

export const trainerPowerSet = createAction<number>("trainer/power-set");

export const trainerSlopeRequest = createAction("trainer/slope-request");
export const trainerSlopeUpdate = createAction<TrainerSlopeUpdate>(
    "trainer/slope-update",
);
