import { createAction } from "@reduxjs/toolkit";
import { PlaybackState, ProgressState } from "./controls.interfaces";

export const controlsTogglePlay = createAction("controls/toggle-play");

export const controlsToggleMakeFlat = createAction("controls/toggle-make-flat");

export const controlsChangePlaybackRate = createAction<PlaybackState>(
    "controls/playback-rate",
);

export const controlsToggleFullscreen = createAction(
    "controls/toggle-fullscreen",
);
export const controlsSetFullscreen = createAction<boolean>(
    "controls/set-fullscreen",
);

export const controlsToggleMute = createAction("controls/toggle-mute");

export const controlsUpdateProgress = createAction<ProgressState>(
    "controls/update-progress",
);

export const controlsSetDuration = createAction<number>(
    "controls/set-duration",
);

export const controlsResetProgress = createAction("controls/reset-progr");
