import { AxiosResponse } from "axios";
import { SagaReturnType } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import { PoiFetchError, PoiFetchSuccess } from "./poi.interfaces";

export type PoiFetchData = SagaReturnType<typeof NetworkModule.getVideoPoi>;

export const responseIsPoiData = (
    response: PoiFetchData,
): response is AxiosResponse<PoiFetchSuccess> =>
    typeof (response as AxiosResponse<PoiFetchSuccess>).data.items !==
        "undefined" &&
    typeof (response as AxiosResponse<PoiFetchError>).data.detail ===
        "undefined";

export const responseIsPoiError = (
    response: PoiFetchData,
): response is AxiosResponse<PoiFetchError> =>
    typeof (response as AxiosResponse<PoiFetchSuccess>).data.items ===
        "undefined" &&
    typeof (response as AxiosResponse<PoiFetchError>).data.detail !==
        "undefined";
