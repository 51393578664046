/**
 *
 * @param videoVelocity recording velocity in kmph
 * @param userVelocity current user velocity in kmph fetched from trainer
 * @returns ratio of userVelocity to videoVelocity
 */
export const getVideoPlaybackRate = (
    videoVelocity: number,
    userVelocity: number,
): number => {
    const rate = userVelocity / videoVelocity;
    if (rate <= 0.07 && rate > 0) return 0;
    else if (rate >= 10) return 10;
    else return rate;
};
