import { tacxCommandStatusIndexes } from "../../decoders/indexes.tacx";
import { tacxStatusPage } from "../../definitions/pages.tacx";
import { tacxCommandState } from "../../../commands/states/states.tacx";
export const tacxCommandPageParser = (
    parsedValueArray: number[],
): tacxStatusPage => {
    const statusMessage = {} as tacxStatusPage;

    statusMessage.lastCommand =
        parsedValueArray[tacxCommandStatusIndexes.lastCommand];

    statusMessage.state = tacxCommandState.find(
        (obj) =>
            obj.value === parsedValueArray[tacxCommandStatusIndexes.status],
    );

    statusMessage.data = parsedValueArray.slice(
        tacxCommandStatusIndexes.dataBegin,
        tacxCommandStatusIndexes.dataEnd,
    );

    return statusMessage;
};
