import { createReducer } from "@reduxjs/toolkit";
import {
    poiCleanup,
    poiClose,
    poiCloseOnDemand,
    poiDisplay,
    poiFetchError,
    poiFetchRequest,
    poiFetchSuccess,
    poiMappedSet,
    poiPushMappedItems,
    poiRawSet,
    poiResetLastClosed,
} from "./poi.actions";
import { MappedPointOfInterest, PointOfInterest } from "./poi.interfaces";

interface PoiState {
    activePoi: MappedPointOfInterest;

    isActive: boolean;

    lastClosedId: string;

    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    items: MappedPointOfInterest[];

    rawItems: PointOfInterest[];

    mappedItems: MappedPointOfInterest[];
}

const initialState: PoiState = {
    activePoi: {} as MappedPointOfInterest,

    isActive: false,

    isLoading: false,

    error: false,

    errorMessage: "",

    items: [],

    lastClosedId: "",

    rawItems: [],

    mappedItems: [],
};

const poiReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(poiFetchRequest, (state) => {
            state.isLoading = true;
            state.error = false;
            state.errorMessage = "";
            state.activePoi = {} as MappedPointOfInterest;
            state.isActive = false;
        })
        .addCase(poiFetchSuccess, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMessage = "";
            state.items = action.payload;
        })
        .addCase(poiFetchError, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.detail;
        })
        .addCase(poiDisplay, (state, action) => {
            state.activePoi = action.payload;
            state.isActive = true;
        })
        .addCase(poiClose, (state) => {
            state.isActive = false;
            state.activePoi = {} as MappedPointOfInterest;
        })
        .addCase(poiCloseOnDemand, (state) => {
            state.lastClosedId = state.activePoi.id;
            state.isActive = false;
            state.activePoi = {} as MappedPointOfInterest;
        })
        .addCase(poiResetLastClosed, (state) => {
            state.lastClosedId = "";
        })
        .addCase(poiPushMappedItems, (state, action) => {
            state.items = action.payload;
        })
        .addCase(poiCleanup, (state) => {
            state.activePoi = initialState.activePoi;
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.errorMessage = initialState.errorMessage;
            state.items = initialState.items;
            state.activePoi = initialState.activePoi;
            state.isActive = initialState.isActive;
            state.rawItems = initialState.rawItems;
            state.mappedItems = initialState.mappedItems;
        })
        .addCase(poiRawSet, (state, action) => {
            state.rawItems = action.payload;
        })
        .addCase(poiMappedSet, (state, action) => {
            state.rawItems = [];
            state.mappedItems = action.payload;
        });
});

export default poiReducer;
