import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import { RootState } from "../store";
import { GpxBreakpoint, InterpolatedGpxTrace } from "../video/video.interfaces";
import {
    poiDwnl,
    poiFetchError,
    poiMappedSet,
    poiRawSet,
    poiRawUpdateRequest,
} from "./poi.actions";
import {
    MappedPointOfInterest,
    PoiFetch,
    PointOfInterest,
} from "./poi.interfaces";
import { PoiFetchData, responseIsPoiData } from "./poi.typeguards";
import {
    getTimestampForPoi,
    poiGetGpxRange,
    poiHandleVelocityBetweenAB,
} from "./poi.util";

interface VideoDataReturnType {
    distance: number;
    duration: number;
    gpxTrace: InterpolatedGpxTrace[];
}
interface PoiItemsReturnType {
    items: MappedPointOfInterest[];
}

interface PoiRawReturnType {
    items: PointOfInterest[];
    gpx: InterpolatedGpxTrace[];
    breakpoints: GpxBreakpoint[];
}

/**
 * WARNING! Be super-duper careful when selecting this way. Not sure if there is a Typescript way to do this
 * @NOTE 28/01/23 I figured out state type
 * @param state
 * @returns video data for POI mapping
 */
export const getVideoData = (state: RootState): VideoDataReturnType => ({
    distance: state.video.videoData.distance,
    duration: state.control.progress.duration,
    gpxTrace: state.video.videoAdjustedGpx,
});

/**
 * WARNING! Be super-duper careful when selecting this way. Not sure if there is a Typescript way to do this
 * @param state
 * @returns poi items used for further mapping
 */
export const getPoiItems = (state: RootState): PoiItemsReturnType => ({
    items: state.poi.items,
});

export const getRawPoiData = (state: RootState): PoiRawReturnType => ({
    items: state.poi.rawItems,
    gpx: state.video.videoAdjustedGpx,
    breakpoints: state.video.videoGpxBreakpoints,
});

function* onPoiDownload(action: PayloadAction<PoiFetch>): unknown {
    const response: PoiFetchData = yield call(
        NetworkModule.getVideoPoi,
        action.payload.id,
    );

    if (responseIsPoiData(response)) {
        yield put({
            type: poiRawSet.toString(),
            payload: response.data.items,
        });
    } else {
        yield put({
            type: poiFetchError.toString(),
            payload: response.data,
        });
    }
}

function* onPoiRawUpdate(): unknown {
    // prettier-ignore
    const { items, gpx, breakpoints }: ReturnType<typeof getRawPoiData> = yield select(getRawPoiData);

    const mappedItems: MappedPointOfInterest[] = [];

    for (const poi of items) {
        try {
            /* get range between which poi is located */
            const { p1, p2 } = poiGetGpxRange(poi, gpx);

            /* get avg velocity for the section */
            const velocity = poiHandleVelocityBetweenAB(p1, p2, breakpoints);

            /* calculate time deltas for start of poi and end */
            // prettier-ignore
            const timestampDeltaStart = getTimestampForPoi(p1, poi.distanceStart, velocity);

            // prettier-ignore
            const timestampDeltaEnd = getTimestampForPoi(p2, poi.distanceEnd, velocity);

            /* calculate the final timestamps */
            // prettier-ignore
            const timestampStart = p1.interpolatedTimestamp + timestampDeltaStart;
            const timestampEnd = p2.interpolatedTimestamp + timestampDeltaEnd;

            mappedItems.push({
                ...poi,
                timestampStart,
                timestampEnd,
            });
        } catch (e: unknown) {
            /* an error occured while processing POI. Continue as usual with next items */
            // prettier-ignore
            console.warn("[!] POI parsing ",poi.id,"failed:", (e as Error).message);
            continue;
        }
    }

    yield put({
        type: poiMappedSet.toString(),
        payload: mappedItems,
    });
}
export default [
    takeLatest(poiDwnl.toString(), onPoiDownload),
    takeLatest(poiRawUpdateRequest.toString(), onPoiRawUpdate),
];
