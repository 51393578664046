import { createReducer } from "@reduxjs/toolkit";
import {
    _expeditionsFetchError,
    _expeditionsFetchRequest,
    _expeditionsFetchSuccess,
} from "./expeditions.actions";
import { ExpeditionsListData } from "./expeditions.interfaces";

interface ExpeditionsState {
    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    currentPage?: number;

    totalPages?: number;

    items: ExpeditionsListData[];
}

const initialState: ExpeditionsState = {
    currentPage: -1,

    isLoading: false,

    error: false,

    errorMessage: "",

    totalPages: -1,

    items: [],
};

const expeditionsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(_expeditionsFetchRequest, (state) => {
            state.isLoading = true;
            state.error = false;
            state.errorMessage = "";
            state.items = [];
        })
        .addCase(_expeditionsFetchError, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.detail;
            state.items = [];
        })
        .addCase(_expeditionsFetchSuccess, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMessage = "";
            state.items = action.payload.items;
            state.currentPage = action.payload.currentPage;
            state.totalPages = action.payload.totalPages;
        });
});

export default expeditionsReducer;
