export const getMapScaleFactor = (delta: number): number => {
    let scaleFactor: number;

    if (delta < 50) scaleFactor = 0.2;
    else if (delta < 150) scaleFactor = 0.35;
    else if (delta < 300) scaleFactor = 0.5;
    else if (delta < 450) scaleFactor = 0.7;
    else scaleFactor = 1;

    return scaleFactor;
};
