import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import {
    ArrowRight,
    FacebookLogo,
    InstagramLogo,
    WTLogoAlt,
} from "../../../assets/icons/index.icons";
import MarqueeSlider from "../../../components/Marquee";
import { NetworkModule } from "../../../services";
import { PromoBoostText } from "../mainpage/SearchPart";

interface InputError {
    "newsletter-email": {
        message: string;
        ref: never;
        type: "pattern" | "required";
    };
}

interface InputSuccess {
    "newsletter-email": string;
}

const Footer = (): JSX.Element => {
    const { register, handleSubmit, reset, formState } = useForm();
    const defaultEmailPlaceholder = "Wpisz adres email";
    const errorEmailPlaceholder = "Błąd subskrypcji!";

    const [emailState, setEmailState] = useState<string>(
        defaultEmailPlaceholder,
    );
    const [subbed, setSubbed] = useState<boolean>(false);

    const onSubmit = (data: FieldValues) => {
        const castData = data as InputSuccess;

        NetworkModule.subToNewsletter(castData["newsletter-email"])
            .then(() => {
                setEmailState(defaultEmailPlaceholder);
                setSubbed(true);
            })
            .catch(() => {
                setEmailState(errorEmailPlaceholder);
            });
    };

    const onError = (error: FieldValues) => {
        setEmailState((error as InputError)["newsletter-email"].message);
        reset();
    };

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset();
        }
    }, [formState.isSubmitSuccessful, reset]);

    return (
        <footer className="footer">
            <div className="footer-marquee-wrapper">
                <MarqueeSlider
                    texts={Array(6).fill("NEWSLETTER")}
                    textClassname="text text-xl text-w500 marqueetext"
                    innerContainerClassname="innerMarqueeWrapper"
                    Icon={ArrowRight}
                    iconClassname="arrow"
                />
            </div>

            <div className="newsletter">
                <PromoBoostText
                    text="zapisz się do newslettera  i uzyskaj dostęp do darmowych tras"
                    wrapperClassname="info"
                />

                <form
                    className="flex form"
                    onSubmit={handleSubmit(onSubmit, onError)}
                >
                    <input
                        className="input"
                        {...register("newsletter-email", {
                            required: {
                                value: true,
                                message: "Wpisz adres email",
                            },
                            pattern: {
                                // eslint-disable-next-line no-control-regex
                                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                message: "Nieprawidłowy format adresu email!",
                            },
                        })}
                        placeholder={emailState}
                    />
                    <div className="btn-wrapper">
                        <button
                            type="submit"
                            className="btn text text-sm text-w600"
                            disabled={subbed}
                        >
                            {subbed ? "Zasubskrybowano!" : "Subskrybuj"}
                        </button>
                    </div>
                </form>
            </div>

            <div className="flex utility">
                <div className="flex al-c policies">
                    <WTLogoAlt className="" />
                    {/* <h5 className="text text-sm text-w600 policy-wrapper">
                        <a
                            className="text text-s text-w600 policy"
                            href="/privacy-policy"
                        >
                            Polityka prywatności
                        </a>
                    </h5>
                    <h5 className="text text-sm text-w600 policy-wrapper">
                        <a
                            className="text text-s text-w600 policy"
                            href="/cookie-policy"
                        >
                            Polityka cookies
                        </a>
                    </h5> */}
                </div>
                <div className="flex al-c">
                    <a
                        className="social-wrapper ig"
                        href="https://instagram.com/wirtualnetrasy.pl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramLogo className="social" />
                    </a>
                    <a
                        className="social-wrapper fb"
                        href="https://www.facebook.com/portal.wirtualnetrasy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FacebookLogo className="social" />
                    </a>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
