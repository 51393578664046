import React from "react";
import { IconType } from "../../common";

interface StatProps {
    Icon?: IconType;

    iconClassname?: string;

    noDisplay?: boolean;

    value?: number | string;

    unit?: string;

    valueClassname?: string;

    unitClassname?: string;

    valueUnitWrapperClassname?: string;

    wrapperClassname?: string;
}

const TrackStat = ({
    Icon,
    iconClassname = "",
    noDisplay = false,
    value,
    valueClassname = "",
    unit,
    unitClassname = "",
    valueUnitWrapperClassname = "",
    wrapperClassname = "",
}: StatProps): JSX.Element => (
    <div
        className={`stat ${wrapperClassname}`}
        style={{ display: noDisplay ? "none" : "" }}
    >
        {Icon && <Icon className={iconClassname} />}

        <div className={`value-unit ${valueUnitWrapperClassname}`}>
            <p className={`text text-ml text-w700 value ${valueClassname}`}>
                {typeof value === "number" ? Number(value.toFixed(2)) : value}
            </p>

            <p className={`text text-sm text-w400 unit ${unitClassname}`}>
                {unit}
            </p>
        </div>
    </div>
);

export default TrackStat;
