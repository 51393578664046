import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import {
    expeditionFetchError,
    expeditionFetchRequest,
    expeditionFetchSuccess,
    expeditionTracksError,
    expeditionTracksRequest,
    expeditionTracksSuccess,
} from "./expedition.actions";
import {
    ExpeditionRequest,
    ExpeditionTrackRequest,
} from "./expedition.interfaces";

import {
    ExpDataResponse,
    ExpTrackResponse,
    responseIsExpeditionData,
    responseIsExpeditionError,
    responseIsExpeditionTracks,
} from "./expedition.typeguards";

function* onExpeditionFetch(action: PayloadAction<ExpeditionRequest>): unknown {
    if (typeof action.payload.id === "string") {
        const response: ExpDataResponse = yield call(
            NetworkModule.getExpeditionData,
            action.payload.id,
        );

        if (responseIsExpeditionData(response)) {
            yield put({
                type: expeditionFetchSuccess.toString(),
                payload: {
                    ...response.data,
                },
            });
        }
        if (responseIsExpeditionError(response)) {
            yield put({
                type: expeditionFetchError.toString(),
                payload: {
                    detail: response.data.detail,
                },
            });
        }
    } else {
        yield put({
            type: expeditionFetchError.toString(),
            payload: {
                detail: "Nieprawidłowe id wyprawy",
            },
        });
    }
}

// TO-DO Data typeguards
function* onExpeditionTracksFetch(
    action: PayloadAction<ExpeditionTrackRequest>,
): unknown {
    const response: ExpTrackResponse = yield call(
        NetworkModule.getExpeditionTracks,
        action.payload.id,
    );

    if (responseIsExpeditionTracks(response)) {
        yield put({
            type: expeditionTracksSuccess.toString(),
            payload: {
                ...response.data,
            },
        });
    } else {
        yield put({
            type: expeditionTracksError.toString(),
            payload: {
                ...response.data,
            },
        });
    }
}

export default [
    takeLatest(expeditionFetchRequest.toString(), onExpeditionFetch),
    takeLatest(expeditionTracksRequest.toString(), onExpeditionTracksFetch),
];
