import React, { useState } from "react";
import { ArrowDown, WTLogo } from "../../assets/icons/index.icons";
import Button from "../Button";

interface ScrollInterface {
    wrapperClassname?: string;
    buttonClassname?: string;

    onClick?: () => void;
}

const ScrollItems = ({
    buttonClassname = "",
    wrapperClassname = "",
    onClick,
}: ScrollInterface): JSX.Element => (
    <div className={`${wrapperClassname}`}>
        <Button
            alternative
            buttonClassname={`links-btn ${buttonClassname}`}
            text="wyprawy"
            textSize="s"
            textClassname="text-w700 links"
            uppercase
            href="/expeditions"
            onClick={onClick}
        />
        <Button
            alternative
            bold
            buttonClassname={`links-btn ${buttonClassname}`}
            text="trasy"
            textSize="s"
            textClassname="text-w700 links"
            uppercase
            href="/tracks"
            onClick={onClick}
        />
        {/* <Button
            alternative
            bold
            buttonClassname={`links-btn ${buttonClassname}`}
            text="o nas"
            textSize="s"
            textClassname="text-w700 links"
            uppercase
            href="/about"
            onClick={onClick}
        />
        <Button
            alternative
            bold
            buttonClassname={`links-btn links--trainer ${buttonClassname}`}
            text="sklep"
            textSize="s"
            textClassname="text-w700 links"
            uppercase
            href="/shop/"
            reloadDocument
        />*/}
        <Button
            alternative
            bold
            buttonClassname={`links-btn ${buttonClassname}`}
            text="kontakt"
            textSize="s"
            textClassname="text-w700 links"
            uppercase
            href="/contact"
            onClick={onClick}
        />
    </div>
);

const Header = (): JSX.Element => {
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const toggleMenu = (): void => setMenuOpened(!menuOpened);

    const closeMenu = (): void => {
        setMenuOpened(false);
        const element: HTMLInputElement | null =
            document.querySelector("#burger-toggle");
        if (element) element.checked = false;
    };

    return (
        <header className="header flex" id="wt-header">
            <div className="flex wrapper">
                <div className="wrapper-logo">
                    <Button
                        Icon={WTLogo}
                        iconClassname="icon logo"
                        buttonClassname="no-hover no-border no-bg logo-btn"
                        href="/"
                        // iconFit
                    />
                </div>

                {/* DROPDOWN MENU */}
                <div className={`dropdown-nav ${menuOpened ? "opened" : ""}`}>
                    <ScrollItems
                        buttonClassname="dropdown-btn"
                        onClick={closeMenu}
                    />

                    <Button
                        alternative
                        bold
                        buttonClassname="no-border dropdown-dwnl links-btn"
                        isPrimary={false}
                        text="Trenażer"
                        textClassname="links links--trainer"
                        uppercase
                        href="/trainer"
                        onClick={closeMenu}
                    />

                    <Button
                        text="Konto"
                        alternative
                        buttonClassname="no-border dropdown-acc links-btn"
                        href="/profile"
                        onClick={closeMenu}
                        iconClassname="icon-g icon-circle icons"
                        iconFit
                        bold
                        uppercase
                    />
                </div>

                {/* REGULAR TOP-NAV-BAR */}
                {/* Scroll items wrapper */}
                <div className="flex wrapper-links">
                    {/* Scroll items */}
                    <ScrollItems wrapperClassname="flex scroll-items" />
                </div>
                {/* other options wrapper */}
                <div className="flex wrapper-secondary">
                    {/* Download app button */}
                    <Button
                        alternative
                        bold
                        buttonClassname="dwnl"
                        Icon={ArrowDown}
                        iconClassname="icon icon--download"
                        isPrimary={false}
                        text="Trenażer"
                        textClassname="links links--trainer"
                        uppercase
                        href="/trainer"
                    />
                    {/* Profile button */}
                    {/* <Button
                        alternative
                        buttonClassname="links-btn account"
                        href="/profile"
                        Icon={PersonOutline}
                        iconClassname="icon-g icon-circle icons"
                        iconFit
                    /> */}

                    <input
                        id="burger-toggle"
                        type="checkbox"
                        onChange={toggleMenu}
                    />
                    <label className="burger-wrapper" htmlFor="burger-toggle">
                        <div className="burger"></div>
                    </label>
                </div>
            </div>
        </header>
    );
};

export default Header;
