import { put, takeLatest } from "@redux-saga/core/effects";
import { Action } from "@reduxjs/toolkit";
import { userLoginTry } from "./user.actions";

function* onUserLoginTry(action: Action<typeof userLoginTry>): unknown {
    try {
        yield put({ type: "user/set", payload: {} });
    } catch (e: unknown) {
        yield put({ type: "user/error", payload: (e as Error).message });
    }
}

export default [takeLatest(userLoginTry.toString(), onUserLoginTry)];
