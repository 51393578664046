import React from "react";
import Spinner from "../Spinner";

interface HandlerProps {
    isLoading: boolean;
    error: boolean;
    errorMessage: string;
}

const LoadingErrorHandler = ({
    isLoading,
    error,
    errorMessage,
}: HandlerProps): JSX.Element => {
    return (
        <div
            className={`${
                isLoading || error
                    ? "track-error visible"
                    : "track-error hidden"
            }`}
        >
            {isLoading && !error && <Spinner size="lg" />}

            {!isLoading && error && (
                <p className="text text-ml text-ff">{errorMessage}</p>
            )}
        </div>
    );
};

export default LoadingErrorHandler;
