import { AxiosResponse } from "axios";
import { SagaReturnType } from "redux-saga/effects";
import { NetworkModule } from "../../services";

export type TracksQuickFetchResponseType = SagaReturnType<
    typeof NetworkModule.fetchQuickTracks
>;

export type TracksFetchWithLimitResponseType = SagaReturnType<
    typeof NetworkModule.fetchTracksWithLimit
>;

export const responseIsTrackError = (
    response: TracksQuickFetchResponseType,
): response is string => typeof response === "string";

export const responseIsTrackData = (
    response: TracksQuickFetchResponseType,
): response is AxiosResponse =>
    typeof (response as AxiosResponse).data !== "undefined" &&
    typeof (response as AxiosResponse).status === "number";
