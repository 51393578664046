import { SimplifiedBTDevice } from "../../redux/bluetooth/bluetooth.reducer";
import { commandHandler } from "./trainer/commands/handlers/handler.general";
import { uuidFtms, uuidTacx } from "./trainer/uuid";
import {
    BluetoothCharacteristicNotFoundError,
    BluetoothConnectionError,
    BluetoothSubError,
    BluetoothUndfDevice,
    BluetoothUnsubError,
} from "./util/errorCodes";
import { arrayFromDataView } from "./util/helpers.dataview";
import { deserializeFunction, toBool } from "./util/helpers.others";

export class BluetoothModule {
    private static instance: BluetoothModule;

    private connected = false;

    private device: BluetoothDevice | undefined;

    private inTacxMode = false;

    private subbedToDevice = false;

    private messageCallback?: (message: DataView) => void;

    private uuids: {
        service: string;
        read: string;
        write: string;
    } = {
        service: "",
        read: "",
        write: "",
    };

    private gattChars: {
        read?: BluetoothRemoteGATTCharacteristic;
        write?: BluetoothRemoteGATTCharacteristic;
    } = {
        read: {} as BluetoothRemoteGATTCharacteristic,
        write: {} as BluetoothRemoteGATTCharacteristic,
    };

    static getInstance(): BluetoothModule {
        if (!BluetoothModule.instance) {
            BluetoothModule.instance = new BluetoothModule();
        }

        return BluetoothModule.instance;
    }

    /**
     * Returns true if there is a trainer currently connected via BT
     * @returns bool
     */
    public get getConnected(): boolean {
        return this.connected;
    }

    public get getTacxMode(): boolean {
        return this.inTacxMode;
    }

    public get getDevice(): BluetoothDevice | undefined {
        return this.device;
    }

    public async scanTrainerDevices(
        addDeviceCallback: (device: BluetoothDevice) => void,
    ) {
        // foundCallback: (device: BluetoothDevice) => void,
        // failCallback?: (e: string) => void,
        // try {
        const enabled = await navigator.bluetooth.getAvailability();
        console.log("skanowanie uruchomione:", enabled);
        if (!enabled) throw new Error("Błąd sprzętowy");
        // try {
        //     const device = await navigator.bluetooth
        //         .requestDevice({
        //             filters: [{ services: [uuidFtms.service] }],
        //         })
        //         .then((e: any) => console.log(e));
        //     console.log("URZĄDZENIE", device);
        // } catch (e: unknown) {
        //     console.warn("hehe", e);
        // }

        /* scan for compatible trainers */
        navigator.bluetooth
            .requestDevice(
                {
                    filters: [
                        { services: [uuidTacx.service] },
                        { services: [uuidFtms.service] },
                    ],
                },
                // { acceptAllDevices: true },
            )
            .then((device) => {
                console.log("znaleziono urządzenie!", device);
                addDeviceCallback(device);
            })
            .catch((e: unknown) =>
                console.warn(
                    e,
                    "\nnazwa",
                    (e as Error).name,
                    "\nmessage",
                    (e as Error).message,
                    "\nstack",
                    (e as Error).stack,
                    "\ncause",
                    (e as Error).cause,
                ),
            );
        // navigator.bluetooth
        //     .getDevices()
        //     .then((arr) => console.log(arr))
        //     .catch((e) => console.warn(e));
        /* on found device, use callback */
        // .then((device: BluetoothDevice) => {
        //     foundCallback(device);
        // })

        // .catch((error: Error) => {
        //     if (error.name === "NotFoundError" && failCallback) {
        //         failCallback("Brak urządzeń");
        //     }
        // });
        // } catch (e: unknown) {
        // console.warn("Bluetooth error", e);
        // failCallback((e as Error).message);
        // Promise.reject(e);
        // }
    }

    public async connectToTrainer(device: BluetoothDevice): Promise<void> {
        return new Promise((resolve, reject) => {
            if (typeof device.gatt !== "undefined") {
                device.gatt.connect().then((v) => {
                    if (v.connected === false) {
                        reject(BluetoothConnectionError.message);
                    } else {
                        this.device = device;
                        resolve();
                    }
                });
            } else {
                reject(BluetoothCharacteristicNotFoundError.message);
            }
        });
    }

    public async disconnectFromTrainer(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.device?.gatt?.disconnect();
                this.onDisconnect();
                resolve();
            } catch (e: unknown) {
                reject((e as Error).message);
            }
        });
    }
    public onDisconnect(): void {
        this.device = undefined;
        this.connected = false;
        this.inTacxMode = false;
        this.uuids = {
            service: "",
            read: "",
            write: "",
        };
        this.gattChars = {
            read: undefined,
            write: undefined,
        };
    }

    public async checkTacxMode(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (
                typeof this.device === "undefined" ||
                typeof this.device.gatt === "undefined"
            )
                throw new Error(BluetoothUndfDevice.message);

            this.device.gatt
                .getPrimaryServices()
                .then((services) => {
                    if (typeof services === "undefined")
                        reject("No BT services");
                    return services.map((e) => e.uuid);
                })
                .then((uuids) => {
                    if (typeof uuids === "undefined")
                        reject("No BT uuids found");

                    if (uuids.includes(uuidTacx.service)) {
                        this.inTacxMode = true;
                    } else if (uuids.includes(uuidFtms.service)) {
                        this.inTacxMode = false;
                    } else {
                        this.inTacxMode = false;
                        reject("No service uuid found");
                    }
                });

            resolve();
        });
    }

    public setBleUuids(): Promise<void> {
        return new Promise((resolve) => {
            if (this.inTacxMode) {
                this.uuids = {
                    service: uuidTacx.service,
                    read: uuidTacx.readCharacteristic,
                    write: uuidTacx.writeCharacteristic,
                };
            } else {
                this.uuids = {
                    service: uuidFtms.service,
                    read: uuidFtms.indoorBikeData,
                    write: uuidFtms.fitnessMachineControlPoint,
                };
            }
            resolve();
        });
    }

    public async updateGattCharacteristics(): Promise<void> {
        return new Promise((resolve, reject) => {
            // prettier-ignore
            if (typeof this.device === "undefined" || typeof this.device.gatt === "undefined" ) {
                reject(BluetoothUndfDevice.message);
                return;
            }

            this.device.gatt
                .getPrimaryService(this.uuids.service)
                .then((service) => service.getCharacteristics())
                .then((chars) => {
                    const allUuids = chars.map((item) => item.uuid);

                    const readIndex = allUuids.indexOf(this.uuids.read);
                    const writeIndex = allUuids.indexOf(this.uuids.write);
                    console.log(allUuids, readIndex, writeIndex);

                    if (readIndex < 0 || writeIndex < 0) {
                        // prettier-ignore
                        reject(BluetoothCharacteristicNotFoundError.message);
                    }

                    this.gattChars.read = chars[readIndex];
                    this.gattChars.write = chars[writeIndex];
                    resolve();
                })
                .catch((e: unknown) => {
                    console.warn("fakap", (e as Error).message);

                    this.gattChars.read = undefined;
                    this.gattChars.write = undefined;
                    reject((e as Error).message);
                });
        });
    }

    public async subscribeToMessages(): Promise<void> {
        if (typeof this.device === "undefined")
            throw new Error(BluetoothUndfDevice.message);

        if (
            typeof this.gattChars.read === "undefined" ||
            typeof this.gattChars.write === "undefined"
        )
            throw new Error(BluetoothCharacteristicNotFoundError.message);

        this.gattChars.read
            .startNotifications()
            .then((ctx) => {
                ctx.addEventListener(
                    "characteristicvaluechanged",
                    (ev: Event) => this.messageHandler(ev),
                );
            })
            .catch((e: unknown) => {
                console.error("[BT] Can't start notifications", e);
                throw new Error(BluetoothSubError.message);
            });

        if (this.inTacxMode === false) {
            this.gattChars.write.startNotifications().catch((e: unknown) => {
                console.error("[BT] Can't start notifications", e);
                throw new Error(BluetoothSubError.message);
            });
        }
    }

    public async unsubscribeToMessages(): Promise<void> {
        if (typeof this.device === "undefined")
            throw new Error(BluetoothUndfDevice.message);

        if (typeof this.gattChars.read === "undefined")
            throw new Error(BluetoothUnsubError.message);
        // stop read subscription
        this.gattChars.read
            .stopNotifications()
            .then((ctx) =>
                ctx.removeEventListener(
                    "characteristicvaluechanged",
                    (_: any) => console.debug(_),
                ),
            )
            .catch((e: unknown) => {
                console.error("[BT] Can't stop notifications", e);
            });

        if (this.inTacxMode === false) {
            if (typeof this.gattChars.write === "undefined")
                throw new Error(BluetoothUnsubError.message);

            // in ftms mode unsubscribe from control point
            this.gattChars.write
                .stopNotifications()
                .then((ctx) =>
                    ctx.removeEventListener(
                        "characteristicvaluechanged",
                        (_: any) => console.debug(_),
                    ),
                )
                .catch((e: unknown) => {
                    console.error("[BT] Can't stop notifications", e);
                    throw new Error(BluetoothUnsubError.message);
                });
        }
    }

    public messageHandler(message: DataView | Event): void {
        if (typeof this.messageCallback === "undefined") return;

        let buffer: DataView;

        if (message instanceof DataView) {
            buffer = message;
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { value }: { value: DataView } = message.target;

            buffer = value;
        }

        this.messageCallback(buffer);
    }

    public async sendCommand(opCode: number, value: number): Promise<void> {
        try {
            if (typeof this.device === "undefined")
                throw new Error(BluetoothUndfDevice.message);

            const buffer = commandHandler(this.inTacxMode, opCode, value);

            if (toBool(process.env.REACT_APP_DEBUG))
                console.log(
                    "[BT] OPCODE",
                    opCode,
                    "VALUE",
                    value,
                    "BUFFER",
                    arrayFromDataView(buffer),
                );

            this.gattChars.write?.writeValue(buffer).catch((e: unknown) => {
                console.warn(
                    "[BT] Błąd wysyłania komedny",
                    (e as Error).message,
                );
            });
        } catch (e: unknown) {
            console.warn((e as Error).message);
        }
    }

    public setDevice(device: BluetoothDevice): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.device = device;
                resolve();
            } catch (e: unknown) {
                reject((e as Error).message);
            }
        });
    }

    public setMessageCallback(
        callback: (value: DataView) => void,
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.messageCallback = callback;
                resolve();
            } catch (e: unknown) {
                reject((e as Error).message);
            }
        });
    }
}
