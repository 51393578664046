import React from "react";
import Map from "../Map";

import { GpxTrace } from "../../redux/video/video.interfaces";

interface ExpeditionMapProps {
    gpxTrace?: GpxTrace[];
}

const ExpeditionMap = ({ gpxTrace }: ExpeditionMapProps): JSX.Element => (
    <div className="expedition-map">
        <p className="text text-ml text-i text-ff">Mapa wyprawy</p>

        {/* MAP */}
        <Map gpxTrace={gpxTrace} markers={[]} wrapperClassname="map-wrapper" />
    </div>
);

export default ExpeditionMap;
