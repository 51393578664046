import { GpxTrace } from "../../../redux/video/video.interfaces";

export interface GPXData {
    distance: number;
    highestPoint: number;
    lowestPoint: number;
    delta: number;
    duration: number;
}
export const getGpxData = (gpxTrace: GpxTrace[]): GPXData => {
    const xy = gpxTrace.map((item) => ({
        x: item.distance,
        y: item.ele,
    }));

    const lowestPoint: number = Math.min(...xy.map((item) => item.y));
    const highestPoint: number = Math.max(...xy.map((item) => item.y));
    const distance: number = Math.max(...xy.map((item) => item.x));
    const delta: number = Math.abs(highestPoint - lowestPoint);

    const timestamps: GpxTrace[] = gpxTrace.filter(
        (item) => typeof item.timestamp === "number",
    );

    const duration: number = Math.max(
        ...timestamps.map((item) => item.timestamp as number),
    );

    return {
        distance,
        delta,
        lowestPoint,
        highestPoint,
        duration,
    };
};
