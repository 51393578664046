import { Action, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { BluetoothModule } from "../../services/bluetooth/BluetoothModule";
import { messageParser } from "../../services/bluetooth/trainer/messages/parsers/messageParser.tacx";
import {
    FtmsMessageType,
    TacxMessageType,
} from "../../services/bluetooth/trainer/messages/types/messageTypes";
import { BluetoothConnectionError } from "../../services/bluetooth/util/errorCodes";
import {
    bluetoothAddDevice,
    bluetoothConnect,
    bluetoothError,
    bluetoothErrorPrepare,
    bluetoothMessageReceivedPrepare,
    bluetoothScan,
    bluetoothUpdateFtmsPage,
    bluetoothUpdateTacxCommandPage,
    bluetoothUpdateTacxDetailPage,
    bluetoothUpdateTacxGeneralPage,
} from "./bluetooth.actions";
import {
    isFtmsDataPage,
    isTacxDetailPage,
    isTacxGeneralPage,
    isTacxStatusPage,
} from "./bluetooth.typeguards";

export const selectDevices = (state: any): BluetoothDevice[] => state.all.items;

// function* yieldBTError(action: PayloadAction<string>) {
//     console.log("yieldBTError", action);

//     yield put({
//         type: bluetoothError.toString(),
//         payload: action.payload,
//     });
// }

// function* yieldBTDevice(device: BluetoothDevice) {
//     yield put({
//         type: bluetoothAddDevice.toString(),
//         payload: device,
//     });
// }

// function* yieldBTConnection(device: BluetoothDevice) {
//     yield put({
//         type: bluetoothConnect,
//         payload: device,
//     });
// }

// function* onBluetoothScan(action: Action<typeof bluetoothScan>) {
//     console.log("onBluetoothScan1");
//     const bt = BluetoothModule.getInstance();
//     // const dispatch = useDispatch();

//     // function* innerGenerator(e: string) {
//     //     console.log("innerGenerator");
//     //     yield put({
//     //         type: bluetoothError.toString(),
//     //         payload: e,
//     //     });
//     // }
//     console.log("onBluetoothScan");
//     try {
//         // yield call
//     } catch (e: unknown) {
//         //
//     }

//     yield 1;
//     // bt.scanTrainerDevices(
//     //     (device: BluetoothDevice) => yieldBTDevice(device),
//     //     // (e: string) => {
//     //     //     innerGenerator(e);
//     //     // yield put({
//     //     //     type: bluetoothError.toString(),
//     //     //     payload: action.payload,
//     //     // });
//     //     // console.log("scan fakap", e);
//     //     // dispatch(bluetoothErrorPrepare(e));
//     //     // },
//     // ).catch((e: unknown) => {
//     //     yield put({
//     //         type: bluetoothError.toString(),
//     //         payload: e,
//     //     });
//     // });

//     // yield 1;
// }

// function* onBluetoothConnect(action: PayloadAction<string>) {
//     const bt = BluetoothModule.getInstance();
//     const devices: ReturnType<typeof selectDevices> = yield select(
//         selectDevices,
//     );

//     const device = devices.find((item) => item.id === action.payload);
//     const dispatch = useDispatch();
//     // DEVICE NOT FOUND ON THE LIST
//     if (typeof device === "undefined") {
//         // Yield error
//         yield put({
//             type: bluetoothError.toString(),
//             payload: BluetoothConnectionError.message,
//         });
//     } else {
//         // Use BT-Module to connect to the trainer
//         bt.connectToTrainer(
//             device,
//             // on error, yield bluetoothError action
//             (e: string) => dispatch(bluetoothErrorPrepare(e)),
//         )
//             // to-do dodać wszystkie aktualizacje w BT module stanów tacx i tak dalej zanim zaktualizujemy stan
//             // on successfully establishing a BLE connection, change state
//             .then(() => yieldBTConnection(device))
//             .catch(
//                 // on error, yield bluetoothError action
//                 (e: string) => dispatch(bluetoothErrorPrepare(e)),
//             );
//     }
// }
function* onTrainerPage(action: PayloadAction<number[]>) {
    const bt = BluetoothModule.getInstance();
    if (bt.getTacxMode) {
        const tacxPage = messageParser(action.payload, true);

        if (isTacxGeneralPage(tacxPage))
            yield put({
                type: bluetoothUpdateTacxGeneralPage.toString(),
                payload: tacxPage,
            });
        else if (isTacxDetailPage(tacxPage))
            yield put({
                type: bluetoothUpdateTacxDetailPage.toString(),
                payload: tacxPage,
            });
        else if (isTacxStatusPage(tacxPage))
            yield put({
                type: bluetoothUpdateTacxCommandPage.toString(),
                payload: tacxPage,
            });
        else console.warn("Unknown received page", tacxPage);
    } else {
        const ftmsPage = messageParser(action.payload);

        if (isFtmsDataPage(ftmsPage))
            yield put({
                type: bluetoothUpdateFtmsPage.toString(),
                payload: ftmsPage,
            });
        else console.warn("Unknown received page", ftmsPage);
    }
}
export default [
    // takeLatest(bluetoothScan.toString(), onBluetoothScan),
    // takeLatest(bluetoothConnect.toString(), onBluetoothConnect),
    // takeLatest(bluetoothErrorPrepare.toString(), yieldBTError),
    takeEvery(bluetoothMessageReceivedPrepare.toString(), onTrainerPage),
];
