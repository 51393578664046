import { tacxGeneralPage } from "../../definitions/pages.tacx";
import { tacxGeneralPageIndexes } from "../../decoders/indexes.tacx";

/**
 * Parses trainer values to readable member types
 * @param parsedMessageArray Array of uint8 values (parsed DataView received directly from trainer)
 * @returns tacxGeneralPage object
 */
export const tacxGeneralPageParser = (
    parsedMessageArray: number[],
): tacxGeneralPage => {
    const feMessage = {} as tacxGeneralPage;

    const speedLSB = parsedMessageArray[tacxGeneralPageIndexes.velocityH]; // 0.001 m/s
    const speedMSB = parsedMessageArray[tacxGeneralPageIndexes.velocityL]; // 0.001 m/s

    feMessage.velocity = ((speedLSB + speedMSB * 256) / 1000) * 3.6; // from 0.001 m/s to km/h

    feMessage.heartbeat = -1;

    return feMessage;
};
