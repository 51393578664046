import React from "react";

interface LinesProps {
    angle?: number;

    colorHex?: string;

    height?: number;

    lineClassname?: string;

    margin?: string;

    marginRight?: number;

    marginLeft?: number;

    numberOfLines?: number;

    scaleX?: number;

    scaleY?: number;

    width?: number;

    wrapperClassname?: string;
}

const Lines = ({
    angle = 0,
    colorHex = "222",
    lineClassname = "",
    margin = "0",
    marginRight = 0,
    marginLeft = 0,
    numberOfLines = 1,
    scaleX = 1,
    scaleY = 1,
    wrapperClassname = "",
}: LinesProps): JSX.Element => (
    <div className={`flex ${wrapperClassname}`}>
        {Array.from({ length: numberOfLines }).map((item, index) => (
            <span
                key={index}
                className={`text text-sm ${lineClassname}`}
                style={{
                    margin: margin,
                    marginRight: marginRight,
                    marginLeft: marginLeft,
                    transform: `rotate(${angle}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
                    color: `#${colorHex}`,
                }}
            >
                |
            </span>
        ))}
    </div>
);

export default Lines;
