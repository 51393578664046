import { createReducer } from "@reduxjs/toolkit";
import {
    controlsChangePlaybackRate,
    controlsResetProgress,
    controlsSetDuration,
    controlsSetFullscreen,
    controlsToggleFullscreen,
    controlsToggleMute,
    controlsTogglePlay,
    controlsToggleMakeFlat,
    controlsUpdateProgress,
} from "./controls.actions";
import { PlaybackState, ProgressState } from "./controls.interfaces";

interface ControlState {
    isPlaying: boolean;

    progress: ProgressState;

    isFullscreen: boolean;

    isMuted: boolean;

    playback: PlaybackState;

    isInBluetoothMode: boolean;

    isFlat: boolean;
}

const initialState: ControlState = {
    isPlaying: false,
    isFlat: false,

    progress: {
        played: 0,
        playedSeconds: 0,
        duration: 0,
        loaded: 0,
        loadedSeconds: 0,
    },

    isFullscreen: false,

    isMuted: true,

    playback: {
        id: "NORMAL",
        rate: 1,
    },

    isInBluetoothMode: false,
};

const controlReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(controlsTogglePlay, (state) => {
            state.isPlaying = !state.isPlaying;
        })
        .addCase(controlsToggleMakeFlat, (state) => {
            state.isFlat = !state.isFlat;
        })
        .addCase(controlsToggleMute, (state) => {
            state.isMuted = !state.isMuted;
        })
        .addCase(controlsUpdateProgress, (state, action) => {
            state.progress = action.payload;
        })
        .addCase(controlsChangePlaybackRate, (state, action) => {
            state.playback = action.payload;
        })
        .addCase(controlsToggleFullscreen, (state) => {
            state.isFullscreen = !state.isFullscreen;
        })
        .addCase(controlsSetFullscreen, (state, action) => {
            state.isFullscreen = action.payload;
        })
        .addCase(controlsSetDuration, (state, action) => {
            state.progress.duration = action.payload;
        })
        .addCase(controlsResetProgress, (state) => {
            state.progress = {
                played: 0,
                playedSeconds: 0,
                duration: 0,
                loaded: 0,
                loadedSeconds: 0,
            };
            state.isPlaying = false;
        });
});

export default controlReducer;
