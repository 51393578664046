import React, { useRef } from "react";
import TrackStat from "../TrackStat";

import { getElevationChange } from "./getElevationChange";

import { DistanceIcon, ElevationIcon } from "../../assets/icons/index.icons";
import { Link } from "react-router-dom";

export interface TrackPanelProps {
    description?: string | string[];

    id: string;

    distance?: number;

    elevation?: number;

    img: string;

    title: string;

    url?: string;

    trailerUrl?: string;

    country?: string;

    location?: string;
}

const TrackPanel = ({
    id,
    distance = 0,
    elevation = 0,
    img,
    title,
    trailerUrl,
}: TrackPanelProps): JSX.Element => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const onMouseOver = (e: unknown): void => {
        if (videoRef !== null && videoRef.current) videoRef.current.play();
    };

    const onMouseOut = (e: unknown): void => {
        if (videoRef !== null && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <div className="trackpanel-wrapper">
            <Link
                // href={`${process.env.REACT_APP_TRACK_BASE}/${id}`}
                to={`${process.env.REACT_APP_TRACK_BASE}/${id}`}
                className="flex column trackpanel"
            >
                <div className="img-wrapper">
                    <img alt={title} src={img} className="img" />

                    <video
                        src={trailerUrl}
                        typeof="video/mp4"
                        muted
                        controls={false}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        className="video"
                        ref={videoRef}
                    />
                </div>

                <h3 className="text text-ml title">{title}</h3>

                <div className="flex">
                    <TrackStat
                        Icon={DistanceIcon}
                        value={typeof distance === "number" ? distance : "-"}
                        unit="km"
                        valueClassname="value"
                        unitClassname="unit"
                        valueUnitWrapperClassname="value-unit"
                        wrapperClassname="stat"
                    />

                    <TrackStat
                        Icon={ElevationIcon}
                        value={
                            typeof elevation === "number"
                                ? getElevationChange(elevation).value
                                : "-"
                        }
                        unit={getElevationChange(elevation).unit}
                        valueClassname="value"
                        unitClassname="unit"
                        valueUnitWrapperClassname="value-unit"
                        wrapperClassname="stat"
                    />
                </div>
            </Link>
        </div>
    );
};

export default TrackPanel;
