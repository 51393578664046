import axios, { AxiosError, AxiosResponse } from "axios";
import {
    ExpeditionData,
    ExpeditionGetError,
    ExpeditionTracksData,
} from "../../redux/expedition/expedition.interfaces";
import { ExpeditionsFetchResponse } from "../../redux/expeditions/expeditions.interfaces";
import { PoiFetchError, PoiFetchSuccess } from "../../redux/poi/poi.interfaces";
import { TracksFetchAllSuccess } from "../../redux/tracks/tracks.interfaces";
import {
    VideoData,
    VideoError,
    VideoSpotResponse,
} from "../../redux/video/video.interfaces";

export class NetworkModule {
    static async getExpeditionData(
        id: string,
    ): Promise<AxiosResponse<ExpeditionData | ExpeditionGetError>> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/expeditions/${id}`,
            );
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd łączności. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<ExpeditionGetError>; //to-do better way to do it
            }
            return response as AxiosResponse<ExpeditionGetError>;
        }
    }

    static async getExpeditionsPage(
        page?: string,
    ): Promise<AxiosResponse<ExpeditionsFetchResponse | ExpeditionGetError>> {
        try {
            if (typeof page !== "undefined") {
                return await axios.get(
                    `${process.env.REACT_APP_API_BASE}/api/expeditions/?page=${page}}`,
                );
            } else {
                return await axios.get(
                    `${process.env.REACT_APP_API_BASE}/api/expeditions`,
                );
            }
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd łączności. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<ExpeditionGetError>; //to-do better way to do it
            }

            return response as AxiosResponse<ExpeditionGetError>;
        }
    }

    static async getExpeditionTracks(
        expeditionId: string,
    ): Promise<AxiosResponse<ExpeditionTracksData | ExpeditionGetError>> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/routes/?expedition=${expeditionId}`,
            );
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd pobierania tras dla wyprawy. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<ExpeditionGetError>; //to-do better way to do it
            }

            return response as AxiosResponse<ExpeditionGetError>;
        }
    }

    static async getVideoData(
        trackId: string,
    ): Promise<AxiosResponse<VideoData | VideoError>> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/routes/${trackId}`,
            );
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd pobierania danych o filmie. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<VideoError>; //to-do better way to do it
            }

            return response as AxiosResponse<VideoError>;
        }
    }

    static async getVideoSpots(
        trackId: string,
    ): Promise<AxiosResponse<VideoSpotResponse | VideoError>> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/spots/?id=${trackId}`,
            );
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd pobierania spotów. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<VideoError>; //to-do better way to do it
            }

            return response as AxiosResponse<VideoError>;
        }
    }

    static async getVideoPoi(
        trackId: string,
    ): Promise<AxiosResponse<PoiFetchSuccess | PoiFetchError>> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/poi/?route=${trackId}`,
            );
        } catch ({ response }) {
            if (typeof (response as AxiosResponse).data === "undefined") {
                return {
                    data: {
                        detail: "Błąd pobierania kluczowych punktów na trasie. Spróbuj odświeżyć aplikację.",
                    },
                } as AxiosResponse<PoiFetchError>; //to-do better way to do it
            }

            return response as AxiosResponse<PoiFetchError>;
        }
    }

    static async subToNewsletter(email: string): Promise<AxiosResponse> {
        try {
            const data = {
                email,
                agreement: true,
            };

            return await axios.put(
                `${process.env.REACT_APP_API_BASE}/api/newsletter/`,
                data,
            );
        } catch (e: unknown) {
            return Promise.reject();
        }
    }

    static async fetchQuickTracks(
        sortBy: "newest" | "popular",
    ): Promise<AxiosResponse | string> {
        try {
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/routes/?sortBy=${sortBy}`,
            );
        } catch (e: unknown) {
            // const castError = e as AxiosError;
            // return castError.message;
            return "Błąd pobierania tras. Spróbuj odświeżyć aplikację.";
        }
    }

    static async fetchTracksWithLimit(
        page?: number,
    ): Promise<AxiosResponse<TracksFetchAllSuccess> | string> {
        try {
            const query = `?page=${page}`;
            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/routes/${
                    typeof page === "number" ? query : ""
                }`,
            );
        } catch (e: unknown) {
            return "Błąd pobierania tras. Spróbuj odświeżyć aplikację";
        }
    }

    static async fetchTracksWithFilter(
        filter: any,
        page?: number,
    ): Promise<AxiosResponse<TracksFetchAllSuccess> | string> {
        try {
            let query = "?";

            if (filter) {
                for (const key in filter) {
                    if (
                        filter[key as keyof typeof filter] !== null &&
                        filter[key as keyof typeof filter]?.length
                    ) {
                        query += key;
                        query += `=${filter[key as keyof typeof filter]}&`;
                    }
                }
                if (typeof page !== "undefined") {
                    query += `page=${page}`;
                }

                if (query.at(-1) === "&")
                    query = query.substring(0, query.length - 1);
            }

            if (query.at(-1) === "?") query = "";

            return await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/routes/${query}`,
            );
        } catch (e: unknown) {
            return "Błąd pobierania tras. Spróbuj odświeżyć aplikację";
        }
    }
}
