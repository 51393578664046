const byteOffset = 1;
export const tacxPageDataOffset = 4;

export const tacxCommandStatusIndexes = {
    lastCommand: 0 + byteOffset,
    status: 2 + byteOffset,
    dataBegin: 3 + byteOffset,
    dataEnd: 7 + byteOffset,
};

export const tacxGeneralPageIndexes = {
    velocityH: 3 + byteOffset,
    velocityL: 4 + byteOffset,
};

export const tacxDetailPageIndexes = {
    accumPowerH: 2 + byteOffset,
    accumPowerL: 3 + byteOffset,
    powerLsb: 4 + byteOffset,
    powerMsb: 5 + byteOffset,
    flags: 6 + byteOffset,
};
