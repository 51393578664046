import { createReducer } from "@reduxjs/toolkit";

import {
    expeditionCleanup,
    expeditionFetchError,
    expeditionFetchRequest,
    expeditionFetchSuccess,
    expeditionTracksError,
    expeditionTracksRequest,
    expeditionTracksSuccess,
} from "./expedition.actions";

import { ExpeditionData, ExpeditionTracks } from "./expedition.interfaces";

interface ExpeditionState {
    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    expedition: ExpeditionData;

    tracks: ExpeditionTracks;
}

const initialState: ExpeditionState = {
    isLoading: false,

    error: false,

    errorMessage: "",

    expedition: {
        id: "1",
        title: "",
        country: "",
        location: "",
        distance: 0,
        elevation: 0,
        trailerUrl: "",
        buyUrl: "",
        gpxTrace: [],
        description: {
            heading: [],
            features: [],
            paragraphs: [],
        },
        flagUrl: "",
    },

    tracks: {
        isLoading: false,

        error: false,

        errorMessage: "",

        items: [],
    },
};

const expeditionReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(expeditionFetchRequest, (state) => {
            state.isLoading = true;
            state.error = false;
            state.errorMessage = "";
        })
        .addCase(expeditionFetchError, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.detail;
        })
        .addCase(expeditionFetchSuccess, (state, action) => {
            state.isLoading = false;
            state.error = false;
            state.errorMessage = "";
            state.expedition = action.payload;
        })
        .addCase(expeditionTracksRequest, (state) => {
            state.tracks.isLoading = true;
            state.tracks.error = false;
            state.tracks.errorMessage = "";
        })
        .addCase(expeditionTracksError, (state, action) => {
            state.tracks.isLoading = false;
            state.tracks.error = true;
            state.tracks.errorMessage = action.payload.detail;
        })
        .addCase(expeditionTracksSuccess, (state, action) => {
            state.tracks.isLoading = false;
            state.tracks.error = false;
            state.tracks.errorMessage = "";
            state.tracks.items = action.payload.items;
        })
        .addCase(expeditionCleanup, (state) => {
            state.isLoading = initialState.isLoading;
            state.error = true;
            state.errorMessage = initialState.errorMessage;
            state.expedition = initialState.expedition;
            state.tracks = initialState.tracks;
        });
});

export default expeditionReducer;
