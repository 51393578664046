/* eslint-disable require-yield */
import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { roundHalf } from "../../services/bluetooth/util/helpers.others";
import { ProgressState } from "../controls/controls.interfaces";
import { InterpolatedGpxTrace } from "../video/video.interfaces";
import {
    trainerGradeSet,
    trainerGradeUpdateRequest,
    trainerNextGpxSet,
    trainerSlopeRequest,
    trainerSlopeUpdate,
} from "./trainer.actions";
import { TrainerGradeUpdateRequest } from "./trainer.interfaces";
import { getGradeBetweenPoints } from "./trainer.util";

interface GpxDataReturnType {
    gpx: InterpolatedGpxTrace[];
    progress: ProgressState;
}
/**
 * WARNING! Be super-duper careful when selecting this way. Not sure if there is a Typescript way to do this
 * @param state Redux-State
 * @returns Gpx Data
 */
export const getRelevantGpxData = (state: any): GpxDataReturnType => ({
    gpx: state.video.videoAdjustedGpx,
    progress: state.control.progress,
});

// @To-Do grade update towards target grade
function* onGradeUpdate(
    action: PayloadAction<TrainerGradeUpdateRequest>,
): unknown {
    const { previousGpx, nextGpx } = action.payload;
    let newGrade: number;

    try {
        newGrade = getGradeBetweenPoints(previousGpx, nextGpx);
        // @fix roundedGrade
        const roundedGrade = roundHalf(newGrade);

        // update grade
        yield put({
            type: trainerGradeSet.toString(),
            payload: {
                grade: roundedGrade,
            },
        });
    } catch (e: unknown) {
        newGrade = 0;

        // @TO-DO use toBool() // if the grade should be reset to zero on error (OutOfRange or invalid values)
        if (process.env.REACT_APP_GRADE_RESET_ON_ERROR === "true") {
            // update grade
            yield put({
                type: trainerGradeSet.toString(),
                payload: {
                    grade: newGrade,
                },
            });
        }
    }

    // update next gpx point
    yield put({
        type: trainerNextGpxSet.toString(),
        payload: {
            nextGpx: action.payload.nextGpx,
        },
    });
}

function* onSlopeUpdate(): unknown {
    // prettier-ignore
    const gpxData: ReturnType<typeof getRelevantGpxData> = yield select(getRelevantGpxData);

    try {
        // prettier-ignore
        if (typeof gpxData.gpx === "undefined") throw new TypeError("Undefined GpxData");
        if (gpxData.gpx.length < 1) throw new Error("GpxData null-length");

        const nextGpxIndex = gpxData.gpx.findIndex(
            (item) =>
                item.interpolatedTimestamp > gpxData.progress.playedSeconds + 1,
        );

        const nextGpx = gpxData.gpx[nextGpxIndex];
        const prevGpx = gpxData.gpx[nextGpxIndex - 1];

        const grade = getGradeBetweenPoints(prevGpx, nextGpx);

        yield put({
            type: trainerSlopeUpdate.toString(),
            payload: {
                grade,
                nextGpx,
                prevGpx,
            },
        });
    } catch (e: unknown) {
        console.warn("Unable to process GpxData", (e as Error).message);
    }
}

export default [
    takeLatest(trainerGradeUpdateRequest.toString(), onGradeUpdate),
    takeLatest(trainerSlopeRequest.toString(), onSlopeUpdate),
];
