import { AxiosResponse } from "axios";
import { SagaReturnType } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import { ExpeditionGetError } from "../expedition/expedition.interfaces";
import { ExpeditionsFetchResponse } from "./expeditions.interfaces";

export type ExpsDataType = SagaReturnType<
    typeof NetworkModule.getExpeditionsPage
>;

export const responseIsExpeditionsPage = (
    response: ExpsDataType,
): response is AxiosResponse<ExpeditionsFetchResponse> => {
    return (
        typeof (response as AxiosResponse<ExpeditionGetError>).data.detail ===
            "undefined" &&
        typeof (response as AxiosResponse<ExpeditionsFetchResponse>).data
            .items !== "undefined"
    );
};

export const responseIsExpeditionsError = (
    response: ExpsDataType,
): response is AxiosResponse<ExpeditionGetError> => {
    return (
        typeof (response as AxiosResponse<ExpeditionsFetchResponse>).data
            .items === "undefined" &&
        typeof (response as AxiosResponse<ExpeditionGetError>).data.detail !==
            "undefined"
    );
};
