import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { _expeditionsFetchRequest } from "../../../redux/expeditions/expeditions.actions";
import { ExpeditionsList, Footer } from "../../parts";

const ExpeditionsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { items, isLoading, error, errorMessage } = useAppSelector(
        (state) => state.expeditions,
    );

    const reFetchExpeditions = (page?: number): void => {
        dispatch(_expeditionsFetchRequest());
    };

    useEffect(() => {
        reFetchExpeditions();
    }, []);

    return (
        <div className="expeditions-page">
            <ExpeditionsList
                title="Wszystkie wyprawy"
                items={items}
                isLoading={isLoading}
                error={error}
                errorMessage={errorMessage}
                callback={reFetchExpeditions}
            />

            <Footer />
        </div>
    );
};

export default ExpeditionsPage;
