import React from "react";
import { Cart, WTLogoGrey } from "../../assets/icons/index.icons";
import Button from "../Button";

interface HideLayout {
    hide?: boolean;

    buyUrl: string;

    allUrl?: string;
}

const ExpeditionStatus = ({
    hide,
    buyUrl = "",
    allUrl = "",
}: HideLayout): JSX.Element => (
    <div
        className={`flex column al-c expeditionstatus just-spe${
            hide ? " hide" : ""
        }`}
    >
        <WTLogoGrey />
    </div>
);

export default ExpeditionStatus;
