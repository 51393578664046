import { createReducer } from "@reduxjs/toolkit";
import { ftmsDataPage } from "../../services/bluetooth/trainer/messages/definitions/pages.ftms";
import {
    tacxDetailPage,
    tacxGeneralPage,
    tacxStatusPage,
} from "../../services/bluetooth/trainer/messages/definitions/pages.tacx";
import {
    bluetoothAddDevice,
    bluetoothConnect,
    bluetoothConnectionStateUpdate,
    bluetoothConnectPrepare,
    bluetoothDeviceReady,
    bluetoothDisconnect,
    bluetoothError,
    bluetoothScan,
    bluetoothUpdateFtmsPage,
    bluetoothUpdateTacxCommandPage,
    bluetoothUpdateTacxDetailPage,
    bluetoothUpdateTacxGeneralPage,
} from "./bluetooth.actions";

export interface SimplifiedBTDevice {
    id: string;

    name?: string;

    gatt?: string;
}

interface BluetoothState {
    availableDevices: SimplifiedBTDevice[];

    isScanning: boolean;

    error: boolean;

    errorMessage: string;

    isUsingTacx: boolean;

    connectingState: string;

    // in the process of connecting to device
    isConnecting: boolean;
    // has stable connection to a BLE device
    isConnected: boolean;

    currentDevice: {
        id?: string;

        name?: string;
    };

    tacx: {
        generalPage?: tacxGeneralPage;
        detailPage?: tacxDetailPage;
        statusPage?: tacxStatusPage;
    };

    ftms: {
        page?: ftmsDataPage;
    };
}

const initialState: BluetoothState = {
    availableDevices: [],

    isConnected: false,

    isConnecting: false,

    connectingState: "",

    isScanning: false,

    isUsingTacx: false,

    error: false,

    errorMessage: "",

    tacx: {},

    ftms: {},

    currentDevice: {},
};

const bluetoothReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(bluetoothScan, (state) => {
            state.availableDevices = [];
            state.error = false;
            state.errorMessage = "";
            state.isScanning = true;
        })
        .addCase(bluetoothAddDevice, (state, action) => {
            state.availableDevices = [
                ...state.availableDevices,
                action.payload,
            ];
        })
        .addCase(bluetoothConnectPrepare, (state, action) => {
            state.isConnecting = true;
            state.isScanning = false;
            state.connectingState =
                "Przygotowywanie do połączenia z trenażerem";
        })
        .addCase(bluetoothConnect, (state, action) => {
            state.isConnected = true;
            state.isConnecting = false;
            state.currentDevice.id = action.payload.id;
            state.currentDevice.name = action.payload.name;
            state.isUsingTacx = action.payload.isTacxDevice;
            state.availableDevices = [];
        })
        .addCase(bluetoothDisconnect, (state) => {
            state.currentDevice.id = undefined;
            state.currentDevice.name = undefined;
            state.isConnected = false;
            state.error = false;
            state.errorMessage = "";
            state.availableDevices = [];
        })
        .addCase(bluetoothError, (state, action) => {
            state.error = true;
            state.errorMessage = action.payload;
            state.availableDevices = [];
        })
        .addCase(bluetoothUpdateTacxGeneralPage, (state, action) => {
            state.tacx.generalPage = action.payload;
        })
        .addCase(bluetoothUpdateTacxDetailPage, (state, action) => {
            state.tacx.detailPage = action.payload;
        })
        .addCase(bluetoothUpdateTacxCommandPage, (state, action) => {
            state.tacx.statusPage = action.payload;
        })
        .addCase(bluetoothUpdateFtmsPage, (state, action) => {
            state.ftms.page = action.payload;
        })
        .addCase(bluetoothConnectionStateUpdate, (state, action) => {
            state.connectingState = action.payload;
        });
});

export default bluetoothReducer;
