import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import FilterSelect from "../../../components/FilterSelect";
import { FilterInterface } from "../../../components/FilterSelect/FilterSelect";
import SectionTitle from "../../../components/SectionTitle";
import Spinner from "../../../components/Spinner";
import { TrackPanel } from "../../../components/TrackPanel";
import { useAppSelector, useQuery } from "../../../hooks";
import {
    tracksFilterFetch,
    tracksFilterReset,
} from "../../../redux/tracks/tracks.actions";
import { Footer } from "../../parts";

const TracksPage = (): JSX.Element => {
    const { isLoading, error, errorMessage, items, showMore } = useAppSelector(
        (state) => state.tracks.all,
    );

    const query = useQuery();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [userFilters, setUserFilters] = useState<any>(); // filters are dynamically served from the server
    const [filterList, setFilterList] = useState<FilterInterface[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const increasePageCount = (): void => setPageCount(pageCount + 1);
    const resetPageCount = (): void => setPageCount(0);
    const dispatchTrackFetch = (filters: any, page?: number): void => {
        dispatch(tracksFilterFetch({ filters, page }));
    };

    const processQuery = (): Promise<any> => {
        return new Promise((resolve) => {
            const queriedFilters = {} as any;

            for (const filter of filterList) {
                const { name } = filter;

                queriedFilters[name] = query.get(name);
            }

            setUserFilters(queriedFilters);
            resolve(queriedFilters);
        });
    };

    const processFetchedFilters = (data: FilterInterface[]): Promise<void> => {
        return new Promise((resolve) => {
            // save server-side filter list
            setFilterList(data);
            const newFilterSet = {} as any;
            for (const filter of data) {
                const { name } = filter;

                newFilterSet[`${name}`] = "";
            }

            // cleanup user filters on new fetch
            setUserFilters(newFilterSet);
            setFilterLoading(false);
            resolve();
        });
    };

    const fetchFilters = (): Promise<FilterInterface[]> => {
        setFilterLoading(true);
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_FILTERS as string)
                .then((res) => {
                    const { data } = res;
                    resolve(data);
                })
                .catch((e: unknown) => {
                    // pass
                    console.warn(e);
                    reject((e as Error).message);
                });
        });
    };

    const fetchFiltersAndTracks = (): void => {
        fetchFilters()
            // process fetched filters from API
            .then((filters) => processFetchedFilters(filters))
            // process query in the URL
            .then(() => processQuery())
            // finish loading filters, display filter component
            .then(() => setFilterLoading(false))
            .then(() => fetchTracks())
            .catch((e: unknown) => console.warn((e as Error).message));
    };

    /*  when page count increase is requested, we need to fetch more tracks with same filters.
        Increase page count
        Avoid change of filters */
    const fetchMore = (): void => {
        dispatchTrackFetch(userFilters, pageCount + 1);

        increasePageCount();
    };

    // fetch tracks with (new)filters
    const fetchTracks = (): Promise<void> => {
        return new Promise((resolve) => {
            // as filters change, so should the tracks currently on display. The previous ones get removed.
            dispatch(tracksFilterReset());
            // reset page count as new filters are applied
            resetPageCount();
            // fetch tracks
            dispatchTrackFetch(userFilters);
            resolve();
        });
    };

    // fetch tracks with (new)filters (forcefully apply filters). useState has a delay which leads to unexpected behaviour.
    // Yes it's a bad way to do this but I dont have the time
    // Forgive me
    const fetchTracksWithForcedFilter = (filters: any): Promise<void> => {
        return new Promise((resolve) => {
            // as filters change, so should the tracks currently on display. The previous ones get removed.
            dispatch(tracksFilterReset());
            // reset page count as new filters are applied
            resetPageCount();
            // fetch tracks
            dispatchTrackFetch(filters);
            resolve();
        });
    };

    // create a url string with all the key-values submitted in the form
    const createQueryString = (data: any): string => {
        let queryString = "?";

        for (const key in data as any) {
            if (
                data[key as keyof typeof data] !== null &&
                data[key as keyof typeof data].length > 0
            ) {
                queryString += key.toLowerCase();
                queryString += `=${data[key as keyof typeof data]}&`;
            }
        }
        if (queryString.at(-1) === "&")
            queryString = queryString.substring(0, queryString.length - 1);

        return queryString;
    };

    const onSubmit = (data: unknown) => {
        // create a query string out of data passed from the form
        const queryString = createQueryString(data);
        // navigate to a subpage with query
        navigate(queryString);
    };

    const onError = (data: unknown) => console.log("ERROR!", data);

    // process updated query (new filters set)
    useEffect(() => {
        processQuery()
            .then((filters) => fetchTracksWithForcedFilter(filters))
            .catch((e: unknown) => console.warn((e as Error).message));
    }, [query]);

    // fetch filters, process initial query (most likely empty) and fetch tracks
    useEffect(() => {
        fetchFiltersAndTracks();
    }, []);

    return (
        <div className="page-tracks">
            <SectionTitle title="Trasy" />

            <FilterSelect
                onError={onError}
                onSubmit={onSubmit}
                inputs={filterList}
                defaultValues={userFilters}
                isLoading={filterLoading}
            />

            <div className="inner">
                {/* tracks carousel */}
                {!!items.length && (
                    <div className="flex column al-c">
                        <div className="flex carousel">
                            {items.map((item) => (
                                <TrackPanel key={item.id} {...item} />
                            ))}
                        </div>

                        {/* @to-do page start at 1, check maximum for updated filters */}
                        {/* {!isLoading && !error && (
                            <button
                                type="button"
                                className="button text text-m text-w600 caps primary"
                                onClick={() => fetchMore()}
                            >
                                Załaduj więcej
                            </button>
                        )} */}
                    </div>
                )}

                {/* loading indicator */}
                {!!isLoading && !error && (
                    <div className="flex just-c">
                        <Spinner size="lg" />
                    </div>
                )}

                {/* error indicator on fetch-more */}
                {!isLoading && !!error && !!items.length && (
                    <div className="flex column al-c">
                        <h5 className="text text-ml text-w500">
                            {errorMessage}
                        </h5>
                    </div>
                )}

                {/* error indicator on first fetch (no items) */}
                {!isLoading && !!error && !items.length && (
                    <div className="flex column al-c">
                        <h5 className="text text-ml text-w500 margin-v2">
                            {errorMessage}
                        </h5>

                        <button
                            type="button"
                            className="button text text-m text-w600 caps primary"
                            onClick={() => fetchTracks()}
                        >
                            Spróbuj ponownie
                        </button>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
};

export default TracksPage;
