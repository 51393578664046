import React, { SVGProps } from "react";
import Marquee from "react-fast-marquee";
import { textSize } from "../../common";

interface MarqueeProps {
    gradient?: boolean;

    Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;

    iconClassname?: string;

    innerContainerClassname?: string;

    textClassname?: string;

    textSize?: textSize;

    texts?: string[];

    wrapperClassname?: string;
}
const MarqueeSlider = ({
    gradient = false,
    Icon,
    iconClassname = "",
    innerContainerClassname = "",
    textClassname = "",
    textSize = "sm",
    texts = [],
    wrapperClassname = "",
}: MarqueeProps): JSX.Element => (
    <Marquee className={wrapperClassname} gradient={gradient}>
        {texts.map((item, index) => (
            <div key={index} className={`flex ${innerContainerClassname}`}>
                <p className={`text text-${textSize} ${textClassname}`}>
                    {item}
                </p>

                {Icon && <Icon className={`${iconClassname}`} />}
            </div>
        ))}
    </Marquee>
);

export default MarqueeSlider;
