import { createAction } from "@reduxjs/toolkit";

import { User, UserLogin, UserRegister } from "./user.interfaces";

export const userLoginTry = createAction<UserLogin>("user/login-try");
export const userRegisterTry = createAction<UserRegister>("user/register-try");
export const userSet = createAction<User>("user/set");

export const userLoading = createAction("user/loading");
export const userError = createAction<string>("user/error");
export const userReset = createAction("user/reset");
