import React from "react";
import { IconType } from "../../common";

export interface MarkerProps {
    bottom: number;
    left: number;
    id?: string;
    onClick?: (...arg: any) => void;
    Icon: IconType;
    className?: string;
}

const IconMarker = ({
    bottom,
    left,
    id = "",
    onClick,
    Icon,
    className = "",
}: MarkerProps): JSX.Element => {
    return (
        <Icon
            id={id}
            className={className}
            style={{ bottom, left }}
            onClick={onClick}
        />
    );
};

export default IconMarker;
