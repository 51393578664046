import { createReducer } from "@reduxjs/toolkit";
import { userReset, userSet } from "./user.actions";
import { User } from "./user.interfaces";

interface UserState {
    user: User;

    loggedIn: boolean;
}

const initialState: UserState = {
    user: {} as User,

    loggedIn: false,
};

const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(userSet, (state, action) => {
            state.user = action.payload;
            state.loggedIn = true;
        })
        .addCase(userReset, (state) => {
            state.user = {} as User;
            state.loggedIn = false;
        });
});

export default userReducer;
