import { createDataView } from "../../../util/helpers.dataview";
import { roundHalf } from "../../../util/helpers.others";
import { commandCodesFtms } from "../../ccodes";
import { commandOffsetsFtms } from "../../ccodes/ccodes.ftms";

interface paramsInterface {
    wind?: number;
    slope?: number;
    crr?: number;
    drag?: number;
}

export const completeParamsCommand = (
    castValues: paramsInterface,
): DataView => {
    // calculate with proper resolution
    const wind = (castValues.wind || 0) * 1000;
    const slope = (castValues.slope || 0) * 100;
    const crr = (castValues.crr || 0.003) * 10000;
    const drag = (castValues.drag || 0) * 100;

    const view = createDataView(7);

    view.setUint8(0, commandCodesFtms.PARAM_SIMULATION);
    view.setInt16(commandOffsetsFtms.wind, wind, true);
    view.setInt16(commandOffsetsFtms.slope, slope, true);
    view.setUint8(commandOffsetsFtms.crr, crr);
    view.setUint8(commandOffsetsFtms.drag, drag);

    return view;
};

/**
 * Create FTMS Power command
 * @param power desired watt power
 * @returns command buffer
 */
export const ftmsTargetPowerCommand = (power: number): DataView => {
    const view = createDataView(3);
    view.setUint8(0, commandCodesFtms.TARGET_POWER);
    view.setInt16(1, power, true);

    return view;
};

/**
 * Create FTMS Resistance command
 * @param resistance to-do?
 * @param toughness to-do?
 * @returns command buffer
 */
export const ftmsTargetResistanceCommand = (
    resistance: number,
    toughness?: number,
): DataView => {
    const finalResistance = resistance * (toughness || 1);

    const view = createDataView(3);

    view.setUint8(0, commandCodesFtms.TARGET_RESISTANCE);
    view.setInt16(1, finalResistance, true);

    return view;
};

/* slope is given in percent range, doubled as trainer's resolution is 0.5% */
/* TO-DO Verify with BT Device max slope range */
/**
 * Creates track grade command
 * @param slope grade
 * @returns command buffer
 */
export const ftmsTargetSlopeCommand = (slope: number): DataView => {
    const roundedSlope = roundHalf(slope);

    let normalizedSlope = roundedSlope * 2;

    /* TO-DO max values from trainer info */
    if (normalizedSlope > 30) normalizedSlope = 30;
    else if (normalizedSlope < -30) normalizedSlope = -30;

    return completeParamsCommand({ slope: normalizedSlope });
};

/**
 * Creates reset command
 * @returns command buffer
 */
export const ftmsResetTrainerCommand = (): DataView => {
    const opCode = 0x01;
    const view = createDataView(1);
    view.setUint8(0, opCode);

    return view;
};

/**
 * Creates request-control command
 * @returns command buffer
 */
export const ftmsRequestControlCommand = (): DataView => {
    const opCode = 0x00;
    const view = createDataView(1);
    view.setUint8(0, opCode);

    return view;
};
