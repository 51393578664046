import React from "react";

import { TrackPanelVerbose } from "../../../components/TrackPanel";
import SectionTitle from "../../../components/SectionTitle";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";

import { useAppSelector } from "../../../hooks";

interface TracksProps {
    refetchCallback: () => void;
}

const TracksInExpedition = ({ refetchCallback }: TracksProps): JSX.Element => {
    const state = useAppSelector((state) => state.expedition.tracks);

    return (
        <section className="expedition-tracks" id="expedition-tracks">
            <SectionTitle title="Trasy w ramach wyprawy" />

            <div className="flex column al-c expedition-tracks-content">
                {/* Track display */}
                {!!state.items.length && !state.error && !state.isLoading && (
                    <div className="flex column track-wrapper">
                        {state.items.map((item) => (
                            <TrackPanelVerbose
                                key={item.id}
                                img={item.img}
                                title={item.title}
                                elevation={item.elevation}
                                distance={item.distance}
                                description={item.description}
                                id={item.id}
                                url={`/track/${item.id}`}
                            />
                        ))}
                    </div>
                )}

                {/* No tracks display */}
                {!state.items.length && !state.error && !state.isLoading && (
                    <p className="text text-ml">Brak tras do wyświetlenia</p>
                )}

                {/* error display */}
                {state.error && (
                    <div className="flex column al-c just-c">
                        <p className="text text-ml margin-v1">
                            {state.errorMessage}
                        </p>

                        <Button
                            text="Spróbuj ponownie"
                            isPrimary
                            onClick={() => refetchCallback()}
                        />
                    </div>
                )}
                {/* loading in progress display */}
                {state.isLoading && !state.error && <Spinner />}
            </div>
        </section>
    );
};
export default TracksInExpedition;
