import { createAction } from "@reduxjs/toolkit";
import {
    ExpeditionGetError,
    ExpeditionData,
    ExpeditionRequest,
    ExpeditionTrackRequest,
    ExpeditionTracksData,
} from "./expedition.interfaces";

export const expeditionFetchRequest =
    createAction<ExpeditionRequest>("expedition/request");

export const expeditionFetchSuccess = createAction<ExpeditionData>(
    "expedition/fetch-success",
);

export const expeditionFetchError = createAction<ExpeditionGetError>(
    "expedition/fetch-error",
);

export const expeditionTracksRequest = createAction<ExpeditionTrackRequest>(
    "expedition/tracks-request",
);

export const expeditionTracksError = createAction<ExpeditionGetError>(
    "expedition/tracks-error",
);

export const expeditionTracksSuccess = createAction<ExpeditionTracksData>(
    "expedition/tracks-success",
);

export const expeditionCleanup = createAction("expedition/cleanup");
