import { InterpolatedGpxTrace } from "../../../../redux/video/video.interfaces";

/**
 *
 * @param distance track length in kilometres
 * @param duration video duration in seconds
 * @returns recording velocity in kmph, assuming constant speed (bold assumption)
 */
export const getVideoVelocity = (
    distance: number, // in kilometres
    duration: number, // in seconds
): number => {
    let kmph: number;
    try {
        kmph = distance / (duration / 3600);
        if (!Number.isFinite(kmph)) throw new Error("Infinity");
        if (Number.isNaN(kmph)) throw new Error("NaN");
    } catch (e) {
        kmph = 20;
    }

    return kmph;
};

/**
 * p1 and p2 are two gpx points between which avg velocity is calculated
 * @param p1
 * @param p2
 * @returns avg velocity expressed in km/h
 * @default on Infinity and NaN returns avg of 20kmph
 */
export const getVideoSectionVelocity = (
    p1: InterpolatedGpxTrace,
    p2: InterpolatedGpxTrace,
): number => {
    //
    let kmph: number;
    const distance1 = p1.distance;
    const timestamp1 = p1.interpolatedTimestamp;
    const distance2 = p2.distance;
    const timestamp2 = p2.interpolatedTimestamp;

    try {
        const deltaDistance = distance2 - distance1;
        const deltaTime = timestamp2 - timestamp1;

        kmph = deltaDistance / (deltaTime / 3600);
        if (!Number.isFinite(kmph)) throw new Error("Infinity");
        if (Number.isNaN(kmph)) throw new Error("NaN");
    } catch (e) {
        kmph = 20;
    }

    return kmph;
};

export const getAverageVelocityBetweenAB = (
    p1Distance: number,
    p2Distance: number,
    p1Timestamp?: number,
    p2Timestamp?: number,
): number => {
    if (
        typeof p1Timestamp === "undefined" ||
        typeof p2Timestamp === "undefined"
    )
        throw new TypeError("Timestamp undefined");

    const deltaDistance = p2Distance - p1Distance;
    const deltaTime = p2Timestamp - p1Timestamp;
    const kmph = deltaDistance / (deltaTime / 3600);

    if (deltaDistance === 0) throw new Error("DeltaX === 0");
    if (deltaTime === 0) throw new Error("DeltaT === 0");
    if (!Number.isFinite(kmph)) throw new RangeError("Infinity");
    if (Number.isNaN(kmph)) throw new RangeError("NaN");

    return kmph;
};
