import React from "react";
import Button from "../../../components/Button";

export const PromoBoostText = ({
    text,
    wrapperClassname = "",
    showSpan = true,
    showResponsive = false,
}: {
    text: string;
    wrapperClassname?: string;
    showSpan?: boolean;
    showResponsive?: boolean;
}): JSX.Element => (
    <div className={`flex ${wrapperClassname}`}>
        {/* <span className="line">|</span> */}
        <span
            className={`line ${showSpan ? "show" : ""} ${
                showResponsive ? "responsive" : ""
            }`}
        >
            |
        </span>
        <p className="text text-s promo">{text}</p>
        <span className="line secondary">|</span>
    </div>
);

const SearchPart = (): JSX.Element => (
    <div className="searchpart">
        <div className="half l" />

        <div className="half r" />

        <div className="content">
            <p className="text text-xxl text-w500 title">
                #Wyrusz z nami w trasę
            </p>

            <div className="btn-wrapper">
                <Button
                    text="Zobacz trasy"
                    isPrimary
                    bold
                    uppercase
                    buttonClassname="btn"
                    href="/tracks"
                />
            </div>

            <div className="promowrapper">
                {/* <PromoBoostText
                    text="trenuj z domu"
                    showSpan={false}
                    showResponsive
                />
                <PromoBoostText text="łącz trasy" />
                <PromoBoostText text="baw się" /> */}
                <div className="flex al-c just-c">
                    <span className="line responsive">|</span>
                    <p className="text text-s promo">trenuj z domu</p>
                    <span className="line secondary">|</span>
                </div>
                <div className="flex al-c just-c">
                    <span className="line show">|</span>
                    <p className="text text-s promo">łącz trasy</p>
                    <span className="line secondary">|</span>
                </div>
                <div className="flex al-c just-c">
                    <span className="line show">|</span>
                    <p className="text text-s promo">baw się</p>
                    <span className="line secondary">|</span>
                </div>
            </div>
        </div>
    </div>
);

export default SearchPart;
