import { InterpolatedGpxTrace } from "../../../redux/video/video.interfaces";
import { getGpxData } from "./getGpxData";
import { getMapScaleFactor } from "./getMapScaleFactor";

export interface MarkerOffset {
    bottom: number;
    left: number;
}

export const getMarkerOffsets = (
    containerHeight: number,
    containerWidth: number,
    offsetBottom: number,
    gpxTrace: InterpolatedGpxTrace[],
    // averageVelocity: number,
    // duration: number,
    // progress?: number,
    time: number,
    isFlat: boolean
): MarkerOffset => {
    try {
        const { lowestPoint, delta, duration } = getGpxData(gpxTrace);
        const scaleFactor = getMapScaleFactor(delta);
        const beginPoint: number = containerHeight - offsetBottom;

        if (Number.isNaN(time) || !Number.isFinite(time))
            throw new Error(`Time value is incorrect: ${typeof time} ${time}`);

        const nextGpxIndex = gpxTrace.findIndex(
            (item) => item.interpolatedTimestamp + 1 > time,
        );
        let previousGpx: InterpolatedGpxTrace;
        let nextGpx: InterpolatedGpxTrace;

        if (nextGpxIndex <= 1) {
            previousGpx = gpxTrace[0];
            nextGpx = gpxTrace[1];
        } else {
            previousGpx = gpxTrace[nextGpxIndex - 1];
            nextGpx = gpxTrace[nextGpxIndex];
        }

        const deltaGpxY = nextGpx.ele - previousGpx.ele;
        // prettier-ignore
        const deltaGpxT =nextGpx.interpolatedTimestamp - previousGpx.interpolatedTimestamp;

        const deltaUserT = time - previousGpx.interpolatedTimestamp;

        // prettier-ignore
        const deltaUserY = previousGpx.ele + (deltaUserT / deltaGpxT) * deltaGpxY;

        // prettier-ignore
        const bottom = containerHeight - (beginPoint - (Math.abs(deltaUserY - lowestPoint) / delta) * beginPoint * scaleFactor);

        // prettier-ignore
        const left = containerWidth * ((previousGpx.interpolatedTimestamp + deltaUserT) / duration);

        if (isFlat) {
            return {
                bottom: offsetBottom,
                left,
            };
        }

        return {
            bottom,
            // left: containerWidth * progress, // @To-Do fix xD
            left,
        };
    } catch (e: unknown) {
        console.log("marker fakap", (e as Error).message, time, containerWidth);

        return {
            bottom: 0,
            left: -50,
        };
    }
};
