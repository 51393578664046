import React, { useEffect, useState } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polyline,
} from "react-leaflet";

import { GpxTrace } from "../../redux/video/video.interfaces";
import { parseGpxTrace } from "./util/parseGpxTrace";
import { LatLonTuple, GpxLatLonArray, MarkerProps } from "./util/definitions";
import { parseGpxPoint } from "./util/parseGpxPoint";
import Spinner from "../Spinner";
import { getMiddleGpx } from "./util/getCenterPosition";

interface MapProps {
    /**
     * VideoData or Map error thrown
     */
    error?: boolean;

    /**
     * Message to be displayed
     */
    errorText?: string;
    /**
     * GpxTrace in tuple format [number, number] or API format {lat, lon, distance, ele}
     */
    gpxTrace?: GpxTrace[] | GpxLatLonArray;

    /**
     * Single GpxPoint where map should be centered.
     * In tuple format [number, number] or API format {lat, lon, distance, ele}
     * @default Middle point of gpxTrace
     */
    centerPosition?: GpxTrace | LatLonTuple;

    /**
     * All the markers that should be drawn on the map
     */
    markers?: MarkerProps[];

    /**
     * MapDisplay classname (height required)
     */
    wrapperClassname?: string;

    /**
     * GpxTrace line outline
     * @default #B61323
     */
    lineOutline?: string;

    /**
     * GpxTrace line fill
     * @default #D71524
     */
    lineFill?: string;

    /**
     * How zoomed-in the map should be
     * @default 11
     */
    zoom?: number;
}

const Map = ({
    centerPosition,
    error,
    errorText = "Błąd ładowania mapy.",
    gpxTrace,
    lineFill = "#D71524",
    lineOutline = "#B61323",
    markers,
    wrapperClassname,
    zoom = 11,
}: MapProps): JSX.Element => {
    if (typeof gpxTrace === "undefined" || !gpxTrace.length || error)
        return (
            <div className="flex al-c just-c margin-v2">
                <p className="text text-ff text-sm">{errorText}</p>
            </div>
        );

    const [loading, setLoading] = useState<boolean>(true);
    const [parsedGpx, setParsedGpx] = useState<GpxLatLonArray>([]);
    // prettier-ignore
    const [centerGpx, setCenterGpx] = useState<LatLonTuple | undefined>(undefined);

    const handleGpxUpdate = (): Promise<void> => {
        return new Promise((resolve) => {
            const parsedData = parseGpxTrace(gpxTrace);
            setParsedGpx(parsedData);
            resolve();
        });
    };

    const handleMapCenterUpdate = (): Promise<void> => {
        return new Promise((resolve) => {
            let middlePoint: LatLonTuple;
            if (typeof centerPosition === "undefined") {
                middlePoint = parseGpxPoint(getMiddleGpx(gpxTrace));
            } else {
                middlePoint = parseGpxPoint(centerPosition);
            }
            setCenterGpx(middlePoint);
            resolve();
        });
    };

    const handleMapUpdate = (): void => {
        setLoading(true);

        handleGpxUpdate()
            .then(() => handleMapCenterUpdate())
            .then(() => setLoading(false))
            .catch((e: unknown) => console.warn("[!]", (e as Error).message));
    };
    useEffect(() => {
        handleMapUpdate();
    }, []);

    useEffect(() => {
        handleMapUpdate();
    }, [gpxTrace]);

    useEffect(() => {
        handleMapCenterUpdate();
    }, [centerPosition]);

    if (loading)
        return (
            <div className="flex al-c just-c">
                <Spinner />
                <p className="text text-sm">Ładowanie mapy.</p>
            </div>
        );
    else if (parsedGpx.length && centerGpx)
        return (
            <MapContainer
                center={centerGpx}
                zoom={zoom}
                scrollWheelZoom
                className={wrapperClassname}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Polyline
                    pathOptions={{ fillColor: lineFill, color: lineOutline }}
                    positions={parsedGpx}
                />
                {markers &&
                    markers.length &&
                    markers.map((item) => {
                        if (typeof item.position === "undefined") return <></>;
                        else
                            return (
                                <Marker
                                    key={item.position[0] / item.position[1]}
                                    position={item.position}
                                    title={item.title}
                                    opacity={item.opacity}
                                >
                                    <Popup>{item.description}</Popup>
                                </Marker>
                            );
                    })}
            </MapContainer>
        );
    else return <></>;
};

export default Map;
