import { MappedPointOfInterest } from "../../../redux/poi/poi.interfaces";

export const getPoiTime = (
    id: string,
    currentTimestamp: number,
    items: MappedPointOfInterest[],
): number => {
    try {
        const poi = items.find((item) => item.id === id);

        if (
            typeof poi !== "undefined" &&
            typeof poi.timestampStart !== "undefined"
        )
            return poi.timestampStart;
        else throw new Error(`POI ${id} Not Found`);
    } catch (e: unknown) {
        return currentTimestamp;
    }
};
