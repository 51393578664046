import { createAction } from "@reduxjs/toolkit";
import {
    GpxBreakpoint,
    GpxTrace,
    InterpolatedGpxTrace,
    VideoData,
    VideoError,
    VideoRequest,
    VideoSpot,
    VideoSpotRequest,
} from "./video.interfaces";

export const videoFetchRequest = createAction<VideoRequest>("video/request");

export const videoFetchSuccess = createAction<VideoData>("video/fetch-success");

export const videoFetchError = createAction<VideoError>("video/fetch-error");

export const videoSpotsRequest = createAction<VideoSpotRequest>(
    "video/spots/request",
);

export const videoSpotsSuccess = createAction<VideoSpot[]>(
    "video/spots/success",
);

export const videoSpotsError = createAction<string>("video/spots/error");

export const videoCleanup = createAction("video/cleanup");

export const videoAvgVelocitySet = createAction<number>(
    "video/avg-velocity-set",
);

// prettier-ignore
export const videoGpxParsingRequest = createAction<GpxTrace[]>("video/gpx-parse-request");

// prettier-ignore
export const videoGpxParse = createAction<InterpolatedGpxTrace[]>("video/gpx-parse")

export const videoGpxBreakpointSet = createAction<GpxBreakpoint[]>(
    "video/gpx-breakpoint-set",
);
