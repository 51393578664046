import React from "react";
import { TrackPanel } from "../TrackPanel";

import type { TrackPanelProps } from "../TrackPanel";

interface CarouselData {
    tracks?: TrackPanelProps[];
}

const TrackCarousel = ({ tracks }: CarouselData): JSX.Element => (
    <div className="flex carousel">
        {tracks && tracks.map((item) => <TrackPanel key={item.id} {...item} />)}
    </div>
);

export default TrackCarousel;
