import React from "react";
import { ArrowRight, PlusIcon } from "../../../assets/icons/index.icons";
import Lines from "../../../components/Lines";
import MarqueeSlider from "../../../components/Marquee";
import bikers from "../../../assets/imgs/bikers.png";

export const texts = [
    "SPRAWDŹ DARMOWE TRASY",
    "TESTUJ TRASY ZA DARMO",
    "SPRAWDŹ DARMOWE TRASY",
    "TESTUJ TRASY ZA DARMO",
    "SPRAWDŹ DARMOWE TRASY",
    "TESTUJ TRASY ZA DARMO",
];

const AboutPart = (): JSX.Element => {
    const info = `Trasy nagrywamy sami. Do każdej trasy dodajemy profil wysokościowy. Dzięki temu możesz zobaczyć, w którym momencie trasy jest pod górkę, a w którym jest piękny, długi zjazd. Projekt zakłada, że masz trenażer rowerowy wykorzystujący technologię Bluetooth oraz protokół FTMS. Jeśli nie, to dobra wiadomość jest taka, że możesz oglądać trasy przez przeglądarkę. Przewijaj i oglądaj! Trasy można używać w następujących konfiguracjach:`;
    const plus1 = `Jako aplikację w przeglądarce internetowej (Chrome, Firefox). Chrome potrafi także połączyć się z Twoim trenażerem przez bluetooth!`;
    const plus2 = `Jako aplikację desktopową pod Windows10/11. Lepiej czasem podłączyć się pod bluetooth bezpośrednio z systemu operacyjnego.`;

    return (
        <section className="part-about">
            <div className="slider">
                <MarqueeSlider
                    texts={texts}
                    Icon={ArrowRight}
                    wrapperClassname="marquee"
                    innerContainerClassname="marquee-wrapper"
                    textClassname="marquee-text"
                    gradient
                />
            </div>

            <div className="flex just-e inner">
                <img src={bikers} alt="bikers-animated" className="img" />

                <div className="text-wrapper">
                    <div className="title--wrapper">
                        <h2 className="text text-l title">Jak to działa</h2>
                    </div>

                    <div className="description-wrapper">
                        {/* MAP headings?? */}
                        <p className="text text-ml text-w500">{info}</p>
                    </div>

                    <div className="feature-wrapper">
                        {/* MAP key features */}
                        <div className="flex al-c feature">
                            <PlusIcon className="plus" />
                            <h5 className="text text-m text-w400">{plus1}</h5>
                        </div>
                        <div className="flex al-c feature">
                            <PlusIcon className="plus" />
                            <h5 className="text text-m text-w400">{plus2}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPart;
