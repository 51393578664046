import React from "react";
import { useForm } from "react-hook-form";

export interface FilterInterface {
    type: "select" | "text";
    name: string;
    display_text?: string;
    values?: FilterSelectOption[];
    currentValue?: string;
}

export interface FilterSelectOption {
    key: string;

    name: string;
}

interface FilterSelectProps {
    inputs: FilterInterface[];

    defaultValues?: any;

    isLoading?: boolean;

    wrapperClassname?: string;

    formClassname?: string;

    selectClassname?: string;

    optionClassname?: string;

    inputClassname?: string;

    onSubmit: (data: unknown) => void;

    onError: (data: unknown) => void;
}

const FilterSelect = ({
    inputs = [],
    defaultValues = {},
    isLoading = false,
    wrapperClassname = "",
    formClassname = "",
    selectClassname = "",
    optionClassname = "",
    inputClassname = "",
    onSubmit,
    onError,
}: FilterSelectProps) => {
    const {
        register,
        handleSubmit,

        // watch,
        formState: { errors },
    } = useForm();

    if (!inputs.length) return <></>;

    return (
        <div className={`filter-wrapper ${wrapperClassname}`}>
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className={`filter-form flex al-c just-c ${formClassname}`}
                style={{ visibility: isLoading ? "hidden" : "initial" }}
            >
                {/* map all inputs passed to the component */}
                {!!inputs &&
                    !!inputs.length &&
                    inputs.map((item) =>
                        // if input type is select
                        item.type === "select" ? (
                            <select
                                key={item.name}
                                {...register(item.name)}
                                className={`text text-m text-w300 filter-select ${selectClassname}`}
                                defaultValue={defaultValues[item.name] || ""}
                            >
                                {/* default option display (option with no value, just visibility display) */}
                                <option
                                    value=""
                                    className="text text-sm text-w600"
                                >
                                    {item.display_text || item.name}
                                </option>

                                {/* map all options for the select */}
                                {item.values?.map((item, index) => (
                                    <option
                                        key={index}
                                        value={item.key}
                                        className={`option text text-m text-w300 ${optionClassname}`}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            // if input type is 'text', render a styled text input
                            <input
                                className={`input text text-m text-w300${inputClassname}`}
                                key={item.name}
                                type="text"
                                {...register(item.name)}
                                aria-invalid={!!errors[item.name]}
                                placeholder={item.display_text || item.name}
                            />
                        ),
                    )}
                <input
                    type="submit"
                    className="button primary flex caps text text-m text-ff text-w500 submit"
                    value="Filtruj"
                />
            </form>
        </div>
    );
};

export default FilterSelect;
