import {
    videoFetchError,
    videoFetchRequest,
    videoFetchSuccess,
    videoGpxBreakpointSet,
    videoGpxParse,
    videoGpxParsingRequest,
    videoSpotsError,
    videoSpotsRequest,
    videoSpotsSuccess,
} from "./video.actions";

import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { GpxTrace, VideoRequest, VideoSpotRequest } from "./video.interfaces";
import { NetworkModule } from "../../services";
import {
    responseIsSpotError,
    responseIsSpotResponse,
    responseIsVideoData,
    responseIsVideoError,
    VideoFetchDataType,
    VideoSpotFetchDataType,
} from "./video.typeguards";
import {
    getBreakpointsWithVelocity,
    getGpxBreakpoints,
    getInterpolatedGpx,
    getTimestampIndexes,
} from "../../views/parts/video/util/getInterpolatedGpx";

function* onVideoRequest(action: PayloadAction<VideoRequest>): unknown {
    const response: VideoFetchDataType = yield call(
        NetworkModule.getVideoData,
        action.payload.id,
    );

    if (responseIsVideoData(response)) {
        // side-effect -> parse GPX after receiving it
        yield put({
            type: videoGpxParsingRequest.toString(),
            payload: response.data.gpxTrace,
        });

        yield put({
            type: videoFetchSuccess.toString(),
            payload: {
                ...response.data,
            },
        });
    }

    if (responseIsVideoError(response)) {
        yield put({
            type: videoFetchError.toString(),
            payload: {
                detail: response.data.detail,
            },
        });
    }
}

function* onVideoSpotsRequest(
    action: PayloadAction<VideoSpotRequest>,
): unknown {
    const response: VideoSpotFetchDataType = yield call(
        NetworkModule.getVideoSpots,
        action.payload.id,
    );

    if (responseIsSpotResponse(response)) {
        yield put({
            type: videoSpotsSuccess.toString(),
            payload: response.data.spots,
        });
    }

    if (responseIsSpotError(response)) {
        yield put({
            type: videoSpotsError.toString(),
            payload: response.data.detail,
        });
    }
}

function* onVideoGpxParse(action: PayloadAction<GpxTrace[]>): unknown {
    const notNullIndexes = getTimestampIndexes(action.payload);

    /* Extract breakpoints from GpxTrace */
    const breakpoints = getGpxBreakpoints(action.payload, notNullIndexes);
    /* Calculate avg velocity between them, needed for playback rate adjusting in trainer mode */
    const extendedBreakpoints = getBreakpointsWithVelocity(breakpoints);

    /* Update redux with breakpoints */
    yield put({
        type: videoGpxBreakpointSet.toString(),
        payload: extendedBreakpoints,
    });

    /* Interpolate timestamp for the rest of the gpx trace */
    const interpolatedPoints = getInterpolatedGpx(
        action.payload,
        notNullIndexes,
    );

    /* Update gpx trace in redux */
    yield put({
        type: videoGpxParse.toString(),
        payload: interpolatedPoints,
    });
}

export default [
    takeLatest(videoFetchRequest.toString(), onVideoRequest),
    takeLatest(videoSpotsRequest.toString(), onVideoSpotsRequest),
    takeLatest(videoGpxParsingRequest.toString(), onVideoGpxParse),
];
