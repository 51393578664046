/**
 * Converts ByteArray to uint8 values
 * @param object ByteArray received sent by trainer via BLE
 * @returns uint8_t array of values
 */
export const parseDataView = (object: DataView): number[] => {
    const length: number = object.byteLength; // number of bytes in the buffer

    const parsedInts: number[] = [];

    for (let offset = 0; offset < length; offset += 1) {
        parsedInts.push(object.getUint8(offset)); // gets values between 0 and 255
    }

    return parsedInts;
};

/**
 * Creates a new DataView buffer with passed length
 * @param numberOfBytes desired buffer length
 * @returns new DataView buffer
 */
export const createDataView = (numberOfBytes: number): DataView =>
    new DataView(new ArrayBuffer(numberOfBytes));

/**
 * Gets uint_8 array from buffer
 * @param view received buffer
 * @returns number[] of uint_8
 */
export const arrayFromDataView = (view: DataView): number[] =>
    Array.from(new Uint8Array(view.buffer));

/**
 * Created a buffer from numeric values
 * @param arr array of numbers
 * @returns array converted to a DataView buffer
 */
export const dataViewFromUint8Array = (arr: number[]): DataView =>
    new DataView(Uint8Array.from(arr).buffer);
