import { parseDataView } from "../../../../util/helpers.dataview";
import { tacxPageDataOffset } from "../../decoders/indexes.tacx";
import { TacxMessageType } from "../../types/messageTypes";
import { tacxCommandPageParser } from "../tacx/commandPageParser.tacx";
import { tacxDetailPageParser } from "../tacx/detailPageParser.tacx";
import { tacxGeneralPageParser } from "../tacx/generalPageParser.tacx";

interface returnMessageType {
    msg: TacxMessageType | undefined;
    type: number;
}

export const tacxParserSwitch = (messageArray: number[]): returnMessageType => {
    /* Shallow copy number array because otherwise we would modify the original */
    const parsedMessageArray = messageArray.slice()
    const messageLength = parsedMessageArray[1];
    const type = parsedMessageArray[4];

    let msg: TacxMessageType | undefined;

    switch (type) {
        case 16:
            msg = tacxGeneralPageParser(
                parsedMessageArray.slice(
                    tacxPageDataOffset,
                    tacxPageDataOffset + messageLength - 1,
                ),
            );
            break;
        case 25:
            msg = tacxDetailPageParser(
                parsedMessageArray.slice(
                    tacxPageDataOffset,
                    tacxPageDataOffset + messageLength - 1,
                ),
            );
            break;
        case 71:
            msg = tacxCommandPageParser(
                parsedMessageArray.slice(
                    tacxPageDataOffset,
                    tacxPageDataOffset + messageLength - 1,
                ),
            );
            break;
        default:
            break;
    }

    return {
        msg,
        type,
    };
};