import { call, put, takeLatest } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import {
    ExpsDataType,
    responseIsExpeditionsError,
    responseIsExpeditionsPage,
} from "./expeditions.typeguards";
import {
    _expeditionsFetchError,
    _expeditionsFetchRequest,
    _expeditionsFetchSuccess,
} from "./expeditions.actions";

function* onExpeditionsFetch(action: unknown): unknown {
    const response: ExpsDataType = yield call(NetworkModule.getExpeditionsPage);

    if (responseIsExpeditionsPage(response)) {
        const { items } = response.data;
        yield put({
            type: _expeditionsFetchSuccess.toString(),
            payload: {
                items,
            },
        });
    } else if (responseIsExpeditionsError(response)) {
        yield put({
            type: _expeditionsFetchError.toString(),
            payload: {
                detail: response.data.detail,
            },
        });
    } else {
        yield put({
            type: _expeditionsFetchError.toString(),
            payload: {
                detail: "Nieznany błąd pobierania wypraw.",
            },
        });
    }
}

export default [
    takeLatest(_expeditionsFetchRequest.toString(), onExpeditionsFetch),
];
