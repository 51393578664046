import React from "react";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { TrackCarousel } from "../../../components/TrackCarousel";
import { TrackPanelProps } from "../../../components/TrackPanel";

interface TracklistProps {
    othersUrl?: string;

    othersVisible?: boolean;

    othersText?: string;

    title?: string;

    tracks?: TrackPanelProps[];

    isLoading: boolean;

    error: boolean;

    errorMessage: string;
}

const TracklistPart = ({
    othersUrl = "",
    othersVisible = true,
    othersText,
    title = "Trasy",
    tracks,
    isLoading,
    error,
    errorMessage,
}: TracklistProps): JSX.Element => (
    <div className="tracklist-wrapper">
        <div className="tracklist">
            <div className="flex just-c segment-title-wrapper">
                <h2 className="text text-l text-w600 segment-title">{title}</h2>
            </div>

            {!isLoading && !error && tracks && tracks.length && (
                <TrackCarousel tracks={tracks} />
            )}

            {isLoading && !error && (
                <div className="flex just-c">
                    <Spinner size="lg" />
                </div>
            )}

            {!isLoading && error && (
                <p className="text text-ml text-w500">{errorMessage}</p>
            )}

            <div
                className="btn-wrapper"
                style={!othersVisible || error ? { display: "none" } : {}}
            >
                <Button
                    text={othersText || "Wszystkie trasy"}
                    uppercase
                    isPrimary
                    bold
                    href={othersUrl}
                />
            </div>
        </div>
    </div>
);

export default TracklistPart;
