import React from "react";
import { DistanceIcon, ElevationIcon } from "../../assets/icons/index.icons";
import TrackLocation from "../TrackLocation";
import { getElevationChange } from "../TrackPanel/getElevationChange";
import TrackStat from "../TrackStat";

interface ExpeditionDataProps {
    title: string;
    country: string;
    location: string;
    distance: number;
    elevation: number;
    flagUrl: string;
}

interface HideLayout {
    hide?: boolean;
}

const ExpeditionDataDisplay = ({
    title,
    country,
    location,
    distance,
    elevation,
    hide = false,
    flagUrl,
}: ExpeditionDataProps & HideLayout): JSX.Element => {
    return (
        <div className={`flex column expedition-dd ${hide ? "hide" : ""}`}>
            <div className="data-title--wrapper">
                <h3 className="text text-ml text-i data-title">{title}</h3>
            </div>

            <div className="flex column data-details--wrapper">
                <TrackLocation
                    country={country}
                    location={location}
                    flagUrl={flagUrl}
                />

                <TrackStat Icon={DistanceIcon} value={distance} unit="km" />

                <TrackStat
                    Icon={ElevationIcon}
                    value={getElevationChange(elevation).value}
                    unit={getElevationChange(elevation).unit}
                />
            </div>
        </div>
    );
};

export default ExpeditionDataDisplay;
