import { tacxStateType } from "../../messages/definitions/subtypes.tacx";

export const tacxEquipmentState: tacxStateType[] = [
    {
        type: "ET_UNKNOWN",
        value: 0,
    },
    {
        type: "ET_GENERAL",
        value: 16,
    },
    {
        type: "ET_TRAINER",
        value: 25,
    },
];

export const tacxTrainerState: tacxStateType[] = [
    {
        type: "STATE_RESERVED",
        value: 0,
    },
    {
        type: "STATE_ASLEEP",
        value: 1,
    },
    {
        type: "STATE_READY",
        value: 2,
    },
    {
        type: "STATE_IN_USE",
        value: 3,
    },
    {
        type: "STATE_FINISHED",
        value: 4,
    },
];

export const tacxCommandState: tacxStateType[] = [
    {
        type: "SUCCESS",
        value: 0,
    },
    {
        type: "FAIL",
        value: 1,
    },
    {
        type: "NOT_SUPPORTED",
        value: 2,
    },
    {
        type: "REJECTED",
        value: 3,
    },
    {
        type: "UNINITIALIZED",
        value: 255,
    },
];
