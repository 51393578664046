import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import TrackDetails from "../../../components/TrackDetails";
import { useAppSelector } from "../../../hooks";
import {
    controlsResetProgress,
    controlsUpdateProgress,
} from "../../../redux/controls/controls.actions";
import { ProgressState } from "../../../redux/controls/controls.interfaces";
import VideoControls from "./VideoControls";
import LoadingErrorHandler from "../../../components/LoadingErrorHandler";
import TrackStatistics from "../../../components/TrackStatistics";
import PoiDisplay from "../../../components/PoiDisplay";
import { getPoiTime } from "../../../components/PoiDisplay/util/getPoiTime";
import {
    poiClose,
    poiDisplay,
    poiRawUpdateRequest,
    poiResetLastClosed,
} from "../../../redux/poi/poi.actions";
import { getCurrentPoi } from "../../../components/PoiDisplay/util/getCurrentPoi";
import { getVideoPlaybackRate } from "./util/getVideoPlaybackRate";
import { trainerSlopeRequest } from "../../../redux/trainer/trainer.actions";

import Map from "../../../components/Map";
import { videoAvgVelocitySet } from "../../../redux/video/video.actions";

const VideoDetails = (): JSX.Element => {
    /* BEGIN STATE SELECTORS FROM REDUX */
    const videoState = useAppSelector((state) => state.video);
    const controlState = useAppSelector((state) => state.control);
    const btState = useAppSelector((state) => ({
        isInBluetoothMode: state.bluetooth.isConnected,
        isUsingTacx: state.bluetooth.isUsingTacx,
        tacx: state.bluetooth.tacx,
        ftms: state.bluetooth.ftms,
    }));
    const poiState = useAppSelector((state) => ({
        isActive: state.poi.isActive,
        activePoi: state.poi.activePoi,
        raw: state.poi.rawItems,
        mapped: state.poi.mappedItems,
        lastClosedId: state.poi.lastClosedId,
    }));
    /* END */
    const [metadataLoaded, setMetadataLoaded] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const dispatch = useDispatch();

    /** Get current played progress expressed in fraction (range 0 - 1) rounded to 6 decimal places */
    const getPlayedTimeFraction = (
        currentTime: number,
        duration: number,
    ): number => Number((currentTime / duration).toFixed(6));

    /** Get current time, played fraction stats, push it to redux state */
    const updateProgress = (bypassPause?: boolean): Promise<void> => {
        return new Promise((res, rej) => {
            if (
                videoRef.current !== null &&
                (!videoRef.current.paused || bypassPause)
            ) {
                const { currentTime, duration } = videoRef.current;

                const newProgress: ProgressState = {
                    playedSeconds: currentTime,
                    played: getPlayedTimeFraction(currentTime, duration),
                    duration: duration,
                };

                dispatch(controlsUpdateProgress(newProgress));
                res();
            } else {
                rej("videoRef.current is null @updateProgress()");
            }
        });
    };

    /** Works differently from updateAverageVelocity in TrackStatistics.tsx
     * as this method is used with seekTo() to find previous velocity and update
     * elapsed distance accordingly. The one in TrackStatistics relies on set next gpx point
     */
    const updateAverageVelocity = (): Promise<void> => {
        return new Promise((res, rej) => {
            if (videoRef.current !== null) {
                const { currentTime } = videoRef.current;

                const nextBreakpointIndex =
                    videoState.videoGpxBreakpoints.findIndex(
                        (item) => item.timestamp > currentTime,
                    );
                // @TO-DO if next == last or -1
                const prevIndex = nextBreakpointIndex - 1;
                const breakpoint = videoState.videoGpxBreakpoints[prevIndex];

                dispatch(videoAvgVelocitySet(breakpoint.velocity));
                res();
            } else {
                rej("videoRef.current is null @updateAverageVelocity()");
            }
        });
    };

    /** Move video to X seconds using video's ref */
    const seekTo2 = (seconds: number): Promise<void> => {
        return new Promise((res, rej) => {
            if (videoRef.current !== null) {
                videoRef.current.currentTime = seconds;
                res();
            } else {
                rej("videoRef is null");
            }
        });
    };

    const dispatchGradeRequest = (): Promise<void> => {
        return new Promise((res) => {
            dispatch(trainerSlopeRequest());
            res();
        });
    };

    const seek = (seconds: number): void => {
        seekTo2(seconds)
            .then(() => updateProgress(true))
            // .then(() => rewindNextGpxPoint())
            .then(() => updateAverageVelocity())
            .then(() => dispatchGradeRequest())
            // @TO-DO update distance?
            .catch((e: unknown) => console.warn(e));
    };

    const getPoiTimestamp = (id: string): Promise<number> => {
        return new Promise((res) => {
            const poiTime: number = getPoiTime(
                id,
                controlState.progress.playedSeconds,
                poiState.mapped,
            );
            dispatch(poiResetLastClosed());
            res(poiTime);
        });
    };

    const seekToPoi2 = (id: string): void => {
        getPoiTimestamp(id)
            .then((time) => seekTo2(time))
            .then(() => updateProgress(true))
            // .then(() => rewindNextGpxPoint())
            .then(() => dispatchGradeRequest())
            .then(() => updateAverageVelocity())
            .catch((e: unknown) => console.warn((e as Error).message));
    };

    /** On seeking to certain point (fw/bw/POI), updates forcefully nextGpx point for accurate grade/slope values */
    /*const rewindNextGpxPoint = (): Promise<void> => {
        return new Promise((res) => {
            try {
                if (videoRef.current !== null) {
                    const nextGpxIndex = videoState.videoAdjustedGpx.findIndex(
                        (item) =>
                            item.interpolatedTimestamp >
                            controlState.progress.playedSeconds,
                    );

                    if (typeof nextGpxIndex === "undefined")
                        throw new Error("NextGPX Index undefined");

                    if (nextGpxIndex === 0)
                        throw new Error("NextGPX Index OOR");

                    const previousGpx =
                        videoState.videoAdjustedGpx[nextGpxIndex - 1];
                    const nextGpx = videoState.videoAdjustedGpx[nextGpxIndex];

                    if (
                        typeof previousGpx === "undefined" ||
                        typeof nextGpx === "undefined"
                    )
                        throw new Error("GPX Points undefined");

                    dispatch(
                        trainerGradeUpdateRequest({
                            previousGpx,
                            nextGpx,
                        }),
                    );
                }
            } catch (e: unknown) {
                console.warn(
                    "[!] GPX Change onSeek failed:",
                    (e as Error).message,
                );
                dispatch(trainerGradeSet({ grade: 0 }));
            }
            res();
        });
    };*/

    // prettier-ignore
    const hasVideoLink = (): boolean => videoState.videoData.videoURL.length > 5;

    /** Uses VideoRef element to set playback rate of the video based on param passed */
    const setPlaybackRate = (rate: number): void => {
        try {
            if (videoRef.current !== null) {
                videoRef.current.playbackRate = rate;
            }
        } catch (e: unknown) {
            console.warn((e as Error).message);
        }
    };

    // const updateDuration = (): Promise<void> => {
    //     return new Promise((res, rej) => {
    //         if (videoRef.current !== null) {
    //             const { duration } = videoRef.current;

    //             if (
    //                 !Number.isSafeInteger(duration) ||
    //                 isNaN(duration) ||
    //                 !Number.isFinite(duration)
    //             ) {
    //                 dispatch(controlsSetDuration(0));
    //             } else {
    //                 dispatch(controlsSetDuration(duration));
    //             }

    //             res();
    //         } else {
    //             rej("HTMLVideoElement is null");
    //         }
    //     });
    // };

    // const updatePoi = (): Promise<void> => {
    //     return new Promise((res, rej) => {
    //         if (poiState.items && poiState.items.length) {
    //             if (
    //                 poiState.items.findIndex(
    //                     (item) => typeof item.timestampStart !== "undefined",
    //                 ) !== -1
    //             ) {
    //                 dispatch(poiMapItems());
    //                 res();
    //             } else {
    //                 rej("POI have no timestamps to map");
    //             }
    //         } else {
    //             rej("No POI available to map");
    //         }
    //     });
    // };

    /* Set initial (zero) progress and duration of the video. 
    using onLoadedMetadata because onLoad event returned NaN duration.
    */
    const onLoadedMetadata = (
        e: React.SyntheticEvent<HTMLVideoElement, Event>,
    ): void => {
        const initialProgress: ProgressState = {
            playedSeconds: 0,
            played: 0,
            duration: e.currentTarget.duration,
        };

        // send a new duration metadata info to redux
        dispatch(controlsUpdateProgress(initialProgress));
        // set metadata loaded as true
        setMetadataLoaded(true);
    };

    /* when video ref changes (video is loaded), run updateProgress() method every 100ms */
    useEffect(() => {
        const interval = setInterval(() => {
            updateProgress().catch(() => {
                // @To-Do actually come up with something to not updateProgress when video stopped
            });
        }, 100);

        return () => {
            dispatch(controlsResetProgress());
            clearInterval(interval);
        };
    }, [videoRef]);

    /* Play/pause control button pressed (redux state update, checks whether appliable because of the bluetooth connection) */
    useEffect(() => {
        if (btState.isInBluetoothMode === false) {
            if (controlState.isPlaying) {
                videoRef.current?.play();
            } else {
                videoRef.current?.pause();
            }
        }
    }, [controlState.isPlaying]);

    /* dispatch a poi items update within redux-saga logic */
    useEffect(() => {
        /* run dispatch only when poi items have loaded and gpx has been parsed 
            -> POI then can be mapped onto the adjusted gpx 
        */
        if (poiState.raw.length && videoState.videoAdjustedGpx.length)
            dispatch(poiRawUpdateRequest());
    }, [poiState.raw, videoState.videoAdjustedGpx]);

    /* on each playback rate change in redux update video's playback rate */
    useEffect(() => {
        if (btState.isInBluetoothMode === false)
            setPlaybackRate(controlState.playback.rate);
    }, [controlState.playback]);

    /* on seconds progress of video, check POI state, display new one, close currently displayed or skip */
    useEffect(() => {
        const poi = getCurrentPoi(
            controlState.progress.playedSeconds,
            poiState.mapped,
        );

        // no poi to handle, no nothing
        if (!poiState.activePoi.id && typeof poi === "undefined") return;

        if (typeof poi === "undefined") dispatch(poiClose());
        else if (poi.id === poiState.lastClosedId) {
            // poi that should be displayed is the previously closed one. Do nth.
        } else {
            dispatch(poiDisplay(poi));
        }
    }, [controlState.progress.playedSeconds]);

    /* adjust video playback rate on each update of velocity page */
    useEffect(() => {
        let userVelocity: number | undefined;

        if (btState.isUsingTacx) {
            userVelocity = btState.tacx.generalPage?.velocity;
        } else {
            userVelocity = btState.ftms.page?.velocity;
        }

        if (
            Number.isNaN(videoState.videoAvgVelocity) ||
            typeof userVelocity === "undefined"
        )
            return;

        const rate = getVideoPlaybackRate(
            videoState.videoAvgVelocity,
            userVelocity,
        );
        setPlaybackRate(rate);
    }, [btState.ftms, btState.tacx]);

    useEffect(() => {
        try {
            if (videoRef.current?.readyState === 4) {
                if (btState.isInBluetoothMode) videoRef.current?.play();
            }
        } catch (e: unknown) {
            console.warn("Tried playing before load");
        }
    }, [videoRef.current?.readyState]);
    return (
        <div className="videopage-details">
            <div className="flex just-sp content-wrapper">
                {/* MAP-WRAPPER */}
                <div className="flex column map-wrapper">
                    {/* MAP-TITLE */}
                    <p className="text text-ml text-i text-ff text-w500 title">
                        Mapa trasy
                    </p>

                    {/* MAP */}
                    <Map
                        gpxTrace={videoState.videoData.gpxTrace}
                        markers={videoState.videoData.mapMarkers}
                        wrapperClassname="inner"
                    />
                </div>

                {/* VIDEO-WRAPPER */}
                <div
                    className={`flex column video-wrapper ${
                        hasVideoLink() ? "" : "no-video"
                    }`}
                >
                    {/* VIDEO-TITLE */}
                    <p className="text text-ml text-i text-ff text-w500 title">
                        Wideo trasy
                    </p>

                    {/* VIDEO MAIN CONTAINER */}
                    <div
                        className={`flex inner ${
                            videoState.error ? "error" : ""
                        }`}
                        id="video-wrapper"
                    >
                        <div
                            className={
                                controlState.isFullscreen
                                    ? "video-wrapper-inner fs"
                                    : "video-wrapper-inner nofs"
                            }
                            style={{
                                width:
                                    videoState.videoData.videoURL.length < 5
                                        ? "100%"
                                        : "",
                            }}
                        >
                            {/* @TO-DO [useState for initial buffer] onFirstLoad set to Buffered and display UI  */}
                            <video
                                src={videoState.videoData.videoURL}
                                typeof="video/mp4"
                                className="video"
                                id="video-player"
                                ref={videoRef}
                                controls={false}
                                muted={controlState.isMuted}
                                onLoadedMetadata={onLoadedMetadata}
                            />

                            <VideoControls
                                seekTo={seek}
                                seekToPoi={seekToPoi2}
                                isInBluetoothMode={btState.isInBluetoothMode}
                                isLoading={!metadataLoaded}
                            />

                            <TrackStatistics
                                isInBluetoothMode={btState.isInBluetoothMode}
                                progressState={controlState.progress}
                                trackDistance={videoState.videoData.distance}
                                velocity={
                                    btState.isInBluetoothMode
                                        ? btState.isUsingTacx
                                            ? btState.tacx.generalPage?.velocity
                                            : btState.ftms.page?.velocity
                                        : videoState.videoAvgVelocity
                                }
                                power={
                                    btState.isUsingTacx
                                        ? btState.tacx.detailPage
                                              ?.momentaryPower
                                        : btState.ftms.page?.power
                                }
                                isLoading={!metadataLoaded}
                            />

                            {/* LOADING / ERROR DISPLAY */}
                            <LoadingErrorHandler
                                isLoading={!metadataLoaded}
                                error={videoState.error}
                                errorMessage={videoState.errorMessage}
                            />

                            <PoiDisplay />
                        </div>
                    </div>
                </div>
            </div>

            <TrackDetails
                title={videoState.videoData.title}
                country={videoState.videoData.country}
                location={videoState.videoData.location}
                distance={videoState.videoData.distance}
                elevation={videoState.videoData.elevation}
                flagUrl={videoState.videoData.flagUrl}
            />
        </div>
    );
};

export default VideoDetails;
