import {
    completeParamsCommand,
    ftmsRequestControlCommand,
    ftmsResetTrainerCommand,
    ftmsTargetPowerCommand,
    ftmsTargetResistanceCommand,
    ftmsTargetSlopeCommand,
} from "../builders/builder.ftms";
import { createDataView } from "../../../util/helpers.dataview";
import { commandCodesFtms } from "../../ccodes";

export const ftmsCommandHandler = (
    code: number,
    value: number,
    values?: number[],
): DataView => {
    switch (code) {
        case commandCodesFtms.TARGET_POWER:
            return ftmsTargetPowerCommand(value);
        case commandCodesFtms.TARGET_RESISTANCE:
            return ftmsTargetResistanceCommand(value);
        case commandCodesFtms.PARAM_SIMULATION:
            if (typeof values !== "undefined") {
                return completeParamsCommand({
                    wind: values[0],
                    slope: values[1],
                    crr: values[2],
                    drag: values[3],
                });
            }
            return createDataView(0);
        case commandCodesFtms.TARGET_SLOPE:
            return ftmsTargetSlopeCommand(value);
        case commandCodesFtms.CONTROL:
            return ftmsRequestControlCommand();
        case commandCodesFtms.RESET:
            return ftmsResetTrainerCommand();
        default:
            return createDataView(0);
    }
};
