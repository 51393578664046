import { GpxTrace } from "../../../redux/video/video.interfaces";
import { GpxLatLonArray, LatLonTuple } from "./definitions";
import { gpxTraceIsTupleArray } from "./typeguards";

export const getMiddleGpx = (
    gpxTrace: GpxTrace[] | GpxLatLonArray | undefined,
): LatLonTuple | undefined => {
    // gpxTrace unknown
    if (typeof gpxTrace === "undefined" || !gpxTrace.length) return undefined;

    // gpxTrace is Array<Array<number, number>>
    if (gpxTraceIsTupleArray(gpxTrace)) {
        return gpxTrace.at(Math.round(gpxTrace.length / 2));
        // gpxTrace is typeof GpxTrace array { ele, lat, lon, distance }[]
    } else {
        const point = gpxTrace[Math.round(gpxTrace.length / 2)];
        return [point.lat, point.lon];
    }
};
