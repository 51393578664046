import { ftmsCommandHandler } from "./handler.ftms";
import { tacxCommandHandler } from "./handler.tacx";

export const commandHandler = (
    usingTacx: boolean,
    commandCode: number,
    commandValue: number,
): DataView => {
    if (usingTacx) return tacxCommandHandler(commandCode, commandValue);
    else return ftmsCommandHandler(commandCode, commandValue);
};
