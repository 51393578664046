import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../assets/icons/index.icons";
import { VideoDescriptionData } from "../../../redux/video/video.interfaces";

interface DescriptionProps {
    title: string;

    description?: VideoDescriptionData;

    errorMessage?: string;
}
const VideoDescription = ({
    title,
    description,
    errorMessage,
}: DescriptionProps): JSX.Element => {
    const [noDescription, setNoDescription] = useState<boolean>(false);

    const updateNoDescription = (): void =>
        setNoDescription(
            !description ||
                Object.keys(description).length === 0 ||
                typeof description.features === "undefined" ||
                typeof description.heading === "undefined" ||
                typeof description.paragraphs === "undefined",
        );

    useEffect(() => {
        updateNoDescription();
    }, [description]);

    return (
        <div className="video-description">
            <div className="inner">
                <div className="title--wrapper">
                    <h2 className="text text-l title">{title}</h2>
                </div>

                <div className="flex text-wrapper">
                    <div
                        className="half left"
                        style={{ display: noDescription ? "none" : "" }}
                    >
                        {/* HEADINGS */}
                        {!noDescription &&
                            description?.heading.map((item, index) => (
                                <div key={index} className="heading-wrapper">
                                    <h4 className="text text-ml text-w500 heading">
                                        {item}
                                    </h4>
                                </div>
                            ))}

                        {/* EXPEDITION'S KEY POINTS */}
                        {!noDescription &&
                            description?.features.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex al-c feature-wrapper"
                                >
                                    <PlusIcon className="plus" />
                                    <h5 className="text text-m text-w400 feature">
                                        {item}
                                    </h5>
                                </div>
                            ))}
                    </div>

                    <div
                        className="half right"
                        style={{ display: noDescription ? "none" : "" }}
                    >
                        {/* REST OF EXPEDITION'S DESCRIPTION */}
                        {!noDescription &&
                            description?.paragraphs.map((item, index) => (
                                <div
                                    key={index}
                                    className="description-wrapper"
                                >
                                    <p className="text text-m description">
                                        {item}
                                    </p>
                                </div>
                            ))}
                    </div>

                    {noDescription && (
                        <div className="margin-v2">
                            <p className="text text-ml">
                                {errorMessage || "Błąd pobierania opisu trasy."}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default VideoDescription;
