import { InterpolatedGpxTrace } from "../../../redux/video/video.interfaces";

/**
 * Get distance (in kilometres) based on fraction of video played and assigned track's distance
 * @param progressFraction
 * @param distance
 * @returns elapsed distance
 */
export const getDistanceProgress = (
    time: number,
    gpxTrace: InterpolatedGpxTrace[],
    velocity: number,
): number => {
    try {
        // prettier-ignore
        const gpxIndex = gpxTrace.findIndex((item) => item.interpolatedTimestamp > time);
        const gpx = gpxTrace[gpxIndex - 1];

        // prettier-ignore
        const distance = gpx.distance + ((time - gpx.interpolatedTimestamp) * velocity / 3600);

        return distance;
    } catch (e: unknown) {
        // @To-Do handle distance progress in another way?
        // console.warn((e as Error).message);
        return 0;
    }
};
