export interface BluetoothError {
    code: number;
    message: string;
}

export const BluetoothHardwareError: BluetoothError = {
    code: 0x01,
    message: "Błąd sprzętowy",
};

export const BluetoothNotFoundError: BluetoothError = {
    code: 0x02,
    message: "Brak znalezionych urządzeń",
};

export const BluetoothConnectionError: BluetoothError = {
    code: 0x03,
    message: "Błąd łączenia się z urządzeniem",
};

export const BluetoothUndfDevice: BluetoothError = {
    code: 0x04,
    message: "Nieznane urządzenie docelowe",
};

export const BluetoothCharacteristicNotFoundError: BluetoothError = {
    code: 0x05,
    message: "Nieznaleziono dostępnych punktów kontrolnych w urządzeniu",
};

export const BluetoothSubError: BluetoothError = {
    code: 0x06,
    message: "Błąd pobierania danych z urządzenia",
};

export const BluetoothUnsubError: BluetoothError = {
    code: 0x07,
    message: "Błąd zakończenia pobierania danych z urządzenia",
};
