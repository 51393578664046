import React from "react";

interface TitleProps {
    title?: string;
}

const SectionTitle = ({ title }: TitleProps): JSX.Element => (
    <div className="title-wrapper">
        <h1 className="text text-xl text-w600 section-title">{title}</h1>
    </div>
);

export default SectionTitle;
