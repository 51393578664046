import { GpxTrace } from "../../../redux/video/video.interfaces";
import { GpxLatLonArray } from "./definitions";
import { gpxTraceIsTupleArray } from "./typeguards";

export const parseGpxTrace = (
    gpxTrace: GpxTrace[] | GpxLatLonArray,
): GpxLatLonArray => {
    if (gpxTraceIsTupleArray(gpxTrace)) return gpxTrace;
    else
        return gpxTrace.map((item) => {
            return [item.lat, item.lon];
        });
};
