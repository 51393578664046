/**
 * Calculates timestamp (in seconds) to seek to based on input
 * @param secondsAdded time added to current timestamp
 * @param currentProgress current timestamp
 * @returns new timestamp
 */
export const getSeekTime = (
    secondsAdded: number,
    currentProgress: number,
): number => {
    const seekTime = currentProgress + secondsAdded;

    if (seekTime <= 0) return 0;
    else return Number.parseInt(seekTime.toFixed(1));
};
