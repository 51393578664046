import React from "react";
import Header from "../../../components/Nav";

interface LayoutProps {
    children?: React.ReactNode;
}
const Layout = ({ children }: LayoutProps): JSX.Element => (
    <div className="flex column al-c layout">
        <Header />
        {children}
    </div>
);
export default Layout;
