import { dataViewFromUint8Array } from "../../../../util/helpers.dataview";
import { powerIndex, speedIndex } from "../../decoders/indexes.ftms";
import { FtmsMessageType } from "../../types/messageTypes";

export const ftmsPageParser = (messageArray: number[]): FtmsMessageType => {
    const data = dataViewFromUint8Array(messageArray);
    const flags = data.getUint16(0, true);

    const instantaneousSpeed = data.getUint16(speedIndex(flags), true) / 100;
    const instantaneousPower = data.getUint16(powerIndex(flags), true);

    const msg: FtmsMessageType = {
        velocity: instantaneousSpeed,
        power: instantaneousPower,
    };

    return msg;
};
