import React from "react";
import { DistanceIcon, ElevationIcon } from "../../assets/icons/index.icons";
import { VideoShortDetails } from "../../redux/video/video.interfaces";
import TrackLocation from "../TrackLocation";
import { getElevationChange } from "../TrackPanel/getElevationChange";
import TrackStat from "../TrackStat";

interface DetailsProps {
    buttonVisible?: boolean;
}

const TrackDetails = ({
    title,
    location,
    country,
    elevation,
    distance,
    buttonVisible,
    flagUrl,
}: VideoShortDetails & DetailsProps): JSX.Element => (
    <div className="flex al-c just-sp track-details">
        <div className="flex column inner">
            <div className="title--wrapper">
                <h3 className="text text-ml text-w400 text-i text-ff">
                    {title}
                </h3>
            </div>

            <div className="flex data--wrapper">
                <TrackLocation
                    country={country}
                    location={location}
                    flagUrl={flagUrl}
                />

                <TrackStat
                    value={distance}
                    Icon={DistanceIcon}
                    unit="km"
                    wrapperClassname="stat"
                />

                <TrackStat
                    value={getElevationChange(elevation).value}
                    Icon={ElevationIcon}
                    unit={getElevationChange(elevation).unit}
                    wrapperClassname="stat"
                />
            </div>
        </div>

        {buttonVisible && <></>}
    </div>
);

export default TrackDetails;
