import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks";
import { poiCloseOnDemand } from "../../redux/poi/poi.actions";
import Button from "../Button";

const PoiDisplay = (): JSX.Element => {
    const poiState = useAppSelector((state) => ({
        activePoi: state.poi.activePoi,
        isActive: state.poi.isActive,
    }));

    const isFullscreen = useAppSelector((state) => state.control.isFullscreen);
    const dispatch = useDispatch();

    return (
        <div
            className={`poi-display ${poiState.isActive ? "active" : ""} ${
                isFullscreen ? "fs" : ""
            }
            }`}
        >
            <div
                // prettier-ignore
                className={`img-wrapper ${!poiState.activePoi.img || poiState.activePoi.img === null? "hidden": ""}`}
            >
                <img className="img" src={poiState.activePoi.img} />
            </div>

            <div className="content flex column">
                <div className="title--wrapper">
                    <h3 className="text text-ml text-w500 text-ff">
                        {poiState.activePoi.title}
                    </h3>
                </div>

                <div className="paragraph-wrapper">
                    {poiState.activePoi &&
                        poiState.activePoi.paragraphs &&
                        poiState.activePoi.paragraphs.map((item, index) => (
                            <p
                                key={index}
                                className="text text-sm text-ff text-w300"
                            >
                                {item}
                            </p>
                        ))}
                </div>

                <div className="btn-wrapper">
                    <Button
                        text="zamknij"
                        isPrimary
                        uppercase
                        alternative
                        onClick={() => dispatch(poiCloseOnDemand())}
                    />
                </div>
            </div>
        </div>
    );
};

export default PoiDisplay;
