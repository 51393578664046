import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HorsePowerIcon } from "../../assets/video-ui";
import { useAppSelector } from "../../hooks";
import { ProgressState } from "../../redux/controls/controls.interfaces";
import { trainerSlopeRequest } from "../../redux/trainer/trainer.actions";
import { videoAvgVelocitySet } from "../../redux/video/video.actions";
import { BluetoothModule } from "../../services/bluetooth/BluetoothModule";
import {
    commandCodesFtms,
    commandCodesTacx,
} from "../../services/bluetooth/trainer/ccodes";
import { toBool } from "../../services/bluetooth/util/helpers.others";
import TrackStat from "../TrackStat/TrackStat";
import { getDistanceProgress } from "./util/getDistanceProgress";
import { getTimeStrings, TimeStrings } from "./util/getTimeStrings";

interface StatsProps {
    isInBluetoothMode: boolean;

    progressState: ProgressState;

    trackDistance: number;

    velocity?: number;

    power?: number;

    isLoading?: boolean;
}
const TrackStatistics = ({
    isInBluetoothMode,
    progressState,
    trackDistance,
    velocity = 0,
    power = 0,
    isLoading = false,
}: StatsProps): JSX.Element => {
    const dispatch = useDispatch();
    // prettier-ignore
    const adjustedGpxTrace = useAppSelector((state) => state.video.videoAdjustedGpx);

    const gpxBreakpoints = useAppSelector(
        (state) => state.video.videoGpxBreakpoints,
    );
    const trainerStats = useAppSelector((state) => state.trainer);
    const btState = useAppSelector((state) => ({
        isUsingTacx: state.bluetooth.isUsingTacx,
        isConnected: state.bluetooth.isConnected,
    }));

    const bt = BluetoothModule.getInstance();
    const [timeStrings, setTimeStrings] = useState<TimeStrings>();
    const [elapsedDistance, setElapsedDistance] = useState<number>(0);
    const controlState = useAppSelector((state) => ({
        isFlat: state.control.isFlat,
    }));

    useEffect(() => {
        if (btState.isConnected) {
            console.warn("[BT] Slope command value needs verification");

            if (btState.isUsingTacx)
                bt.sendCommand(
                    commandCodesTacx.BASIC_RESISTANCE,
                    !controlState.isFlat ? trainerStats.grade : 0,
                );
            else
                bt.sendCommand(
                    commandCodesFtms.TARGET_SLOPE,
                    !controlState.isFlat ? trainerStats.grade : 0,
                );
        }
    }, [trainerStats.grade]);

    const failUpdateVelocityFallback = (
        breakpointDistance: number,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const nextBreakpointIndex = gpxBreakpoints.findIndex(
                (item) => item.distance > breakpointDistance,
            );
            // if next breakpoint exists and is not the first item on the list
            if (nextBreakpointIndex) {
                const lastBreakpoint = gpxBreakpoints[nextBreakpointIndex - 1];

                if (lastBreakpoint.velocity) {
                    dispatch(videoAvgVelocitySet(lastBreakpoint.velocity));
                    resolve();
                }
            } else {
                // @fix jak są dwa punkty
                reject("Next Breakpoint index not found");
            }
        });
    };

    const updateAverageVelocity = (): Promise<void> => {
        // @TO-DO only if in bluetooth mode
        // @NOTE actually no xDDdsadfklsdfgklsdlgkhsdgaik
        // @NOTE /VideoDetails.tsx forcefully updates nextGpx on video seeking, so this method works just fine
        // @NOTE changed the above, running a loop to update gpx
        return new Promise((resolve) => {
            if (
                typeof trainerStats.nextGpx.timestamp !== "undefined" &&
                trainerStats.nextGpx.timestamp !== null
            ) {
                const breakpointDistance = trainerStats.nextGpx.distance;
                // timestamp available
                const breakpoint = gpxBreakpoints.find(
                    (item) => item.distance === breakpointDistance,
                );

                // breakpoint found
                if (typeof breakpoint !== "undefined") {
                    console.log(
                        "breakpoint found",
                        breakpoint.distance,
                        "km, ",
                        breakpoint.velocity,
                        "km/h",
                    );
                    // use velocity defined in the breakpoint to set an average
                    dispatch(videoAvgVelocitySet(breakpoint.velocity));
                    // breakpoint was not found, fallback to previous
                } else {
                    // @To-Do verify if-else statement required
                    // find next breakpoint
                    // console.log("breakpoint not found, falling back...");
                    // failUpdateVelocityFallback(breakpointDistance);
                }
            } else {
                failUpdateVelocityFallback(trainerStats.nextGpx.distance);
            }
            resolve();
        });
    };

    const dispatchGradeRequest = (): Promise<void> => {
        return new Promise((res) => {
            dispatch(trainerSlopeRequest());
            res();
        });
    };

    useEffect(() => {
        // update time on progress
        setTimeStrings(
            getTimeStrings(progressState.playedSeconds, progressState.duration),
        );
        // update distance on progress
        setElapsedDistance(
            getDistanceProgress(
                progressState.playedSeconds,
                adjustedGpxTrace,
                velocity,
            ),
        );

        // prettier-ignore
        if (progressState.playedSeconds > trainerStats.nextGpx.interpolatedTimestamp ) {
            updateAverageVelocity()
                .then(() => dispatchGradeRequest())
                .catch((e: unknown) => {
                    console.warn(e);
                    // prettier-ignore
                    if (toBool(process.env.REACT_APP_VIDEO_VELOCITY_RESET_ON_ERROR)) {
                        dispatch(videoAvgVelocitySet(20));
                    }
                });
        }
    }, [progressState]);

    return (
        <div
            className="flex just-c track-statistics"
            style={{ display: isLoading ? "none" : "" }}
        >
            {/* POWER (WATTS) AND VELOCITY (KMH) */}
            <div
                className="flex power-velocity part"
                style={{ display: isInBluetoothMode ? "inherit" : "none" }}
            >
                <span className="separator responsive-hide" />

                <TrackStat
                    Icon={HorsePowerIcon}
                    iconClassname="icon responsive-hide"
                    value={power}
                    unit="w"
                    unitClassname="text-ff"
                    valueClassname="text-ff"
                    wrapperClassname="stat-fit--l"
                />

                <span className="separator responsive-hide" />

                <TrackStat
                    value={velocity}
                    unit="kmh"
                    unitClassname="text-ff"
                    valueClassname="text-ff"
                    wrapperClassname="stat-fit--l"
                />
            </div>

            {/* GRADE AND SLOPE PART */}
            <div className="flex grade-slope part">
                <span className="separator responsive-hide" />

                <TrackStat
                    value={!controlState.isFlat ? trainerStats.grade : 0}
                    unit="%"
                    valueClassname="text-ff value--small"
                    unitClassname="text-ff unit--small"
                    wrapperClassname="stat-fit--s"
                />
            </div>

            {/* DISTANCE CURRENT & ELAPSED (KM) */}
            <div className="flex elapsed-distance part">
                <span className="separator responsive-hide" />

                <TrackStat
                    value={elapsedDistance}
                    unit="km"
                    valueClassname="text-ff value--small"
                    unitClassname="text-ff unit--small"
                    wrapperClassname="stat-fit--m"
                />

                <TrackStat
                    value={trackDistance}
                    unit="km"
                    valueClassname="text-ff value--small"
                    unitClassname="text-ff unit--small"
                    wrapperClassname="stat-fit--m"
                />
                <span className="separator responsive-hide" />
            </div>

            {/* ELAPSED & TOTAL TIME MM:SS */}
            <div
                className="flex elapsed-time part"
                style={{ display: isInBluetoothMode ? "none" : "" }}
            >
                <TrackStat
                    value={timeStrings?.elapsed}
                    valueClassname="text-ff value--small"
                    wrapperClassname="stat-fit--m"
                    noDisplay={isInBluetoothMode}
                />

                <TrackStat
                    value={timeStrings?.duration}
                    valueClassname="text-ff value--small"
                    wrapperClassname="stat-fit--m"
                    noDisplay={isInBluetoothMode}
                />

                <span className="separator responsive-hide" />
            </div>
        </div>
    );
};

export default TrackStatistics;
