export const commandCodesFtms = {
    CONTROL: 0x00,
    RESET: 0x01,
    TARGET_RESISTANCE: 0x04,
    TARGET_POWER: 0x05,
    PARAM_SIMULATION: 0x11,

    TARGET_SLOPE: 0x99, // fictional op code, does not exist in ftms standard, used for switch case
};

export const commandOffsetsFtms = {
    wind: 1,
    slope: 3,
    crr: 5,
    drag: 6,
};
