import { all } from "redux-saga/effects";
import tracksSaga from "./tracks/tracks.saga";
import userSaga from "./user/user.saga";
import expeditionSaga from "./expedition/expedition.saga";
import videoSaga from "./video/video.saga";
import expeditionsSaga from "./expeditions/expeditions.saga";
import trainerSaga from "./trainer/trainer.saga";
import poiSaga from "./poi/poi.saga";
import bluetoothSaga from "./bluetooth/bluetooth.saga";

export default function* saga() {
    yield all([
        ...userSaga,
        ...tracksSaga,
        ...expeditionSaga,
        ...expeditionsSaga,
        ...videoSaga,
        ...trainerSaga,
        ...poiSaga,
        ...bluetoothSaga,
    ]);
}
