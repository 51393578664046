import React from "react";
import { PlusIcon } from "../../assets/icons/index.icons";
import { useAppSelector } from "../../hooks";

const TrainerState = (): JSX.Element => {
    const trainerState = useAppSelector((state) => ({
        isUsingTacx: state.bluetooth.isUsingTacx,
        tacx: state.bluetooth.tacx,
        ftms: state.bluetooth.ftms,
        isConnected: state.bluetooth.isConnected,
        device: state.bluetooth.currentDevice,
    }));

    return (
        <div className="flex trainerstate">
            {/* left part with icon and a vertical line separator */}
            <div className="flex al-c just-c icon--wrapper">
                <PlusIcon className="icon" />
            </div>
            {/* right part with device name, connectivity state & data received */}
            <div className="flex column">
                <div className="flex device--title">
                    <p className="text text-m">Trenażer</p>
                    <h4 className="text text-ml title">
                        {trainerState.device.name}
                    </h4>
                </div>

                <div className="flex column device--info">
                    <p className="text text-sm key">
                        Stan:{" "}
                        <b className="text text-m value">
                            {trainerState.isConnected
                                ? "Połączono"
                                : "Rozłączono"}
                        </b>
                    </p>

                    <p className="text text-sm key">
                        Aktualna prędkość:{" "}
                        <b className="text text-m value">
                            {trainerState.isUsingTacx
                                ? trainerState.tacx.generalPage?.velocity
                                : trainerState.ftms.page?.velocity}{" "}
                            km/h
                        </b>
                    </p>

                    <p className="text text-sm key">
                        Aktualna Moc:{" "}
                        <b className="text text-m value">
                            {trainerState.isUsingTacx
                                ? trainerState.tacx.detailPage?.momentaryPower
                                : trainerState.ftms.page?.power}{" "}
                            W
                        </b>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TrainerState;
