import { ftmsDataPage } from "../../services/bluetooth/trainer/messages/definitions/pages.ftms";
import {
    tacxDetailPage,
    tacxGeneralPage,
    tacxStatusPage,
} from "../../services/bluetooth/trainer/messages/definitions/pages.tacx";
import {
    FtmsMessageType,
    TacxMessageType,
} from "../../services/bluetooth/trainer/messages/types/messageTypes";

export const isTacxGeneralPage = (
    page?: TacxMessageType | FtmsMessageType,
): page is tacxGeneralPage => {
    return (
        typeof (page as tacxGeneralPage).distance !== "undefined" &&
        typeof (page as tacxDetailPage).momentaryCadence === "undefined" &&
        typeof (page as ftmsDataPage).power === "undefined" &&
        typeof (page as tacxStatusPage).data === "undefined"
    );
};

export const isTacxDetailPage = (
    page?: TacxMessageType | FtmsMessageType,
): page is tacxDetailPage => {
    return (
        typeof (page as tacxGeneralPage).distance === "undefined" &&
        typeof (page as tacxDetailPage).momentaryCadence !== "undefined" &&
        typeof (page as ftmsDataPage).power === "undefined" &&
        typeof (page as tacxStatusPage).data === "undefined"
    );
};

export const isTacxStatusPage = (
    page?: TacxMessageType | FtmsMessageType,
): page is tacxStatusPage => {
    return (
        typeof (page as tacxGeneralPage).distance === "undefined" &&
        typeof (page as tacxDetailPage).momentaryCadence === "undefined" &&
        typeof (page as ftmsDataPage).power === "undefined" &&
        typeof (page as tacxStatusPage).data !== "undefined"
    );
};

export const isFtmsDataPage = (
    page?: TacxMessageType | FtmsMessageType,
): page is tacxStatusPage => {
    return (
        typeof (page as tacxGeneralPage).distance === "undefined" &&
        typeof (page as tacxDetailPage).momentaryCadence === "undefined" &&
        typeof (page as ftmsDataPage).power !== "undefined" &&
        typeof (page as tacxStatusPage).data === "undefined"
    );
};
