import { getGradeBetweenPoints } from "../../../redux/trainer/trainer.util";
import {
    GpxTrace,
    InterpolatedGpxTrace,
} from "../../../redux/video/video.interfaces";
import { getGpxData, GPXData } from "./getGpxData";

export interface HeightmapGpxStop {
    id: number;
    offset: string;
    stopColor: string;
}

const primaryGradientStop = (data: GPXData, p1: GpxTrace): string => {
    const colorHigh = "rgb(215, 21, 36)";
    const colorMedium = "rgb(239, 173, 67)";
    const colorLow = "rgb(239, 211, 67)";
    const colorVeryLow = "rgb(139, 239, 67)";
    const colorBelow = "rgb(67, 239, 73)";

    const elevationDiff = data.highestPoint - p1.ele;

    if (elevationDiff < data.delta / 5) return colorHigh;
    else if (elevationDiff < (2 * data.delta) / 5) return colorMedium;
    else if (elevationDiff < (3 * data.delta) / 5) return colorLow;
    else if (elevationDiff < (9.5 * data.delta) / 10) return colorVeryLow;
    else return colorBelow;
};

const alternativeGradientStop = (p1: GpxTrace, p2: GpxTrace): string => {
    const colorHigh = "rgb(215, 21, 36)";
    const colorMedium = "rgb(239, 173, 67)";
    const colorLow = "rgb(239, 211, 67)";
    const colorVeryLow = "rgb(139, 239, 67)";
    const colorBelow = "rgb(67, 239, 73)";

    try {
        const slope = getGradeBetweenPoints(p1, p2);
        if (slope < -1) return colorBelow;
        else if (slope < 0.5) return colorVeryLow;
        else if (slope < 1.5) return colorLow;
        else if (slope < 2.5) return colorMedium;
        else return colorHigh;
    } catch (e: unknown) {
        console.log("GOT GRADIENT ERROR", e);
        return colorBelow;
    }
};

/** @deprecated */
export const getGradientStops = (gpxTrace: GpxTrace[]): HeightmapGpxStop[] => {
    const data = getGpxData(gpxTrace);
    const HeightmapStops: HeightmapGpxStop[] = gpxTrace.map((item, index) => {
        // const elevationDiff = data.highestPoint - item.ele;
        let stopColor: string;

        if (index > 0)
            stopColor = alternativeGradientStop(item, gpxTrace[index - 1]);
        else stopColor = "rgb(139, 239, 67)";

        const offset = `${((item.distance / data.distance) * 100).toFixed(2)}%`;
        const id = Number((item.distance / data.distance).toFixed(4));

        return {
            id,
            stopColor,
            offset,
        };
    });
    return HeightmapStops;
};

export const getGradientStopsByTime = (
    gpx: InterpolatedGpxTrace[],
    totalTime: number,
): HeightmapGpxStop[] => {
    const HeightmapStops: HeightmapGpxStop[] = gpx.map((item, index) => {
        let stopColor: string;

        if (index > 0)
            stopColor = alternativeGradientStop(item, gpx[index - 1]);
        else stopColor = "rgb(139, 239, 67)";

        // prettier-ignore
        const offset = `${((item.interpolatedTimestamp / totalTime) * 100).toFixed(2)}%`;

        const id = Number((item.interpolatedTimestamp / totalTime).toFixed(4));

        return {
            id,
            stopColor,
            offset,
        };
    });
    return HeightmapStops;
};
