import React, { useEffect } from "react";
import { AboutPart, Footer, SearchPart, TracklistPart } from "../../parts";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import {
    tracksFetchNewest,
    tracksFetchPopular,
} from "../../../redux/tracks/tracks.actions";

const MainPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const tracks = useAppSelector((state) => state.tracks);

    useEffect(() => {
        dispatch(tracksFetchNewest());
        dispatch(tracksFetchPopular());
    }, []);

    return (
        <>
            <SearchPart />

            <AboutPart />

            <TracklistPart
                tracks={tracks.newest.items.slice(0, 8)}
                error={tracks.newest.error}
                errorMessage={tracks.newest.errorMessage}
                isLoading={tracks.newest.isLoading}
                title="Najnowsze Trasy"
                othersText="Więcej tras"
                othersUrl="/tracks"
            />

            <TracklistPart
                tracks={tracks.popular.items.slice(0, 8)}
                error={tracks.popular.error}
                errorMessage={tracks.popular.errorMessage}
                isLoading={tracks.popular.isLoading}
                title="Najpopularniejsze Trasy"
                // othersVisible={false}
                othersText="Więcej tras"
                othersUrl="/tracks"
            />

            <Footer />
        </>
    );
};

export default MainPage;
