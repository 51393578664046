import { GpxTrace } from "../video/video.interfaces";

/**
 * Returns slope percentage between two GPX points
 * @param p1 Previous GPX Point
 * @param p2 Next GPX Point
 * @throws on division by 0 & grade > 100
 * @returns slope percentage
 * @to-do - add a dynamic grade limit based on trainer capabilities
 */
export const getGradeBetweenPoints = (p1: GpxTrace, p2: GpxTrace): number => {
    const x1 = p1.distance;
    const y1 = p1.ele;
    const x2 = p2.distance;
    const y2 = p2.ele;

    const deltaX: number = (x2 - x1) * 1000;
    const deltaY: number = y2 - y1;

    if (deltaX === 0)
        throw new Error(`ValueError: deltaX==0, x1: ${p1.lat}, x2: ${p2.lat}`);

    const grade: number = (deltaY / deltaX) * 100;

    if (Math.abs(grade) > 20) {
        throw new Error(`OOR: grade==${grade}`);
    }

    return Math.round(grade * 10) / 10;
};
