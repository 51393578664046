import React from "react";
import Button from "../../../components/Button";
import SectionTitle from "../../../components/SectionTitle";
import TrackSpot from "../../../components/TrackSpot";
import { useAppSelector } from "../../../hooks";

const TrackSpots = (): JSX.Element => {
    const spots = useAppSelector((state) => state.video.spots);
    return (
        <div className="trackspots">
            <SectionTitle title="Ciekawe miejsca na trasie" />

            <div className="spots">
                {!!spots.length &&
                    spots.map((item) => <TrackSpot key={item.id} {...item} />)}

                {!spots.length && (
                    <div className="flex column al-c">
                        <h3 className="text text-ml text-w400 text-cntr margin-v2">
                            Brak ciekawych miejsc na trasie.
                        </h3>

                        <Button
                            bold
                            buttonClassname="no-hover"
                            isDisabled
                            isPrimary
                            text="dodaj nowe (Wkrótce!)"
                            uppercase
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default <></>;
