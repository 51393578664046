import { createAction } from "@reduxjs/toolkit";
import { ftmsDataPage } from "../../services/bluetooth/trainer/messages/definitions/pages.ftms";
import {
    tacxDetailPage,
    tacxGeneralPage,
    tacxStatusPage,
} from "../../services/bluetooth/trainer/messages/definitions/pages.tacx";
import { BluetoothConnectType } from "./bluetooth.interfaces";
import { SimplifiedBTDevice } from "./bluetooth.reducer";

export const bluetoothScan = createAction("bluetooth/scan");

export const bluetoothAddDevice = createAction<SimplifiedBTDevice>(
    "bluetooth/add-device",
);

export const bluetoothConnectPrepare = createAction<string>(
    "bluetooth/connect-prepare",
);

export const bluetoothConnect =
    createAction<BluetoothConnectType>("bluetooth/connect");

export const bluetoothDisconnectPrepare = createAction(
    "bluetooth/disconnect-prepare",
);

export const bluetoothDisconnect = createAction("bluetooth/disconnect");

export const bluetoothErrorPrepare = createAction<string>(
    "bluetooth/error-prepare",
);
export const bluetoothError = createAction<string>("bluetooth/error");

export const bluetoothMessageReceivedPrepare = createAction<number[]>(
    "bluetooth/message-page-prepare",
);

export const bluetoothMessageReceived = createAction<number[]>(
    "bluetooth/message-page",
);

export const bluetoothUpdateTacxGeneralPage = createAction<tacxGeneralPage>(
    "bluetooth/tacx-page-general",
);
export const bluetoothUpdateTacxDetailPage = createAction<tacxDetailPage>(
    "bluetooth/tacx-page-detail",
);
export const bluetoothUpdateTacxCommandPage = createAction<tacxStatusPage>(
    "bluetooth/tacx-page-status",
);

export const bluetoothUpdateFtmsPage = createAction<ftmsDataPage>(
    "bluetooth/ftms-page",
);

export const bluetoothConnectionStateUpdate = createAction<string>(
    "bluetooth/connecting-update",
);

export const bluetoothDeviceReady = createAction("bluetooth/device-rdy");
