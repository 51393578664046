import { createReducer } from "@reduxjs/toolkit";
import { TrackPanelProps } from "../../components/TrackPanel";
import {
    tracksFetchFail,
    tracksFetchMoreSuccess,
    tracksFetchNewest,
    tracksFetchNewestError,
    tracksFetchNewestSuccess,
    tracksFetchPopular,
    tracksFetchPopularError,
    tracksFetchPopularSuccess,
    tracksFetchSuccess,
    tracksFilterReset,
} from "./tracks.actions";

interface TracksStatus {
    isLoading: boolean;

    error: boolean;

    errorMessage: string;

    showMore: boolean;

    items: TrackPanelProps[];
}

interface TracksState {
    newest: TracksStatus;

    popular: TracksStatus;

    all: TracksStatus;
}

const initialState: TracksState = {
    newest: {
        isLoading: false,

        error: false,

        showMore: false,

        errorMessage: "",

        items: [],
    },

    popular: {
        isLoading: false,

        error: false,

        showMore: false,

        errorMessage: "",

        items: [],
    },

    all: {
        isLoading: false,

        error: false,

        showMore: false,

        errorMessage: "",

        items: [],
    },
};
const tracksReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(tracksFetchNewest, (state) => {
            state.newest.isLoading = true;
            state.newest.showMore = false;
            state.newest.error = false;
            state.newest.errorMessage = "";
        })
        .addCase(tracksFetchPopular, (state) => {
            state.popular.isLoading = true;
            state.popular.showMore = false;
            state.popular.error = false;
            state.popular.errorMessage = "";
        })
        .addCase(tracksFetchNewestSuccess, (state, action) => {
            state.newest.items = action.payload.items;
            state.newest.showMore = false;
            state.newest.isLoading = false;
            state.newest.error = false;
            state.newest.errorMessage = "";
        })
        .addCase(tracksFetchPopularSuccess, (state, action) => {
            state.popular.items = action.payload.items;
            state.popular.showMore = false;
            state.popular.isLoading = false;
            state.popular.error = false;
            state.popular.errorMessage = "";
        })
        .addCase(tracksFetchNewestError, (state, action) => {
            state.newest.showMore = false;
            state.newest.isLoading = false;
            state.newest.error = true;
            state.newest.errorMessage = action.payload.detail;
        })
        .addCase(tracksFetchPopularError, (state, action) => {
            state.popular.showMore = false;
            state.popular.isLoading = false;
            state.popular.error = true;
            state.popular.errorMessage = action.payload.detail;
        })
        .addCase(tracksFetchSuccess, (state, action) => {
            state.all.error = false;
            state.all.isLoading = false;
            state.all.errorMessage = "";
            state.all.items = action.payload.items;
            const totalPages = action.payload.totalPages || 1;
            const currentPage = action.payload.currentPage || 1;
            state.all.showMore = totalPages > currentPage;
        })
        .addCase(tracksFetchFail, (state, action) => {
            state.all.error = true;
            state.all.isLoading = false;
            state.all.errorMessage = action.payload;
            state.all.showMore = false;
        })
        .addCase(tracksFetchMoreSuccess, (state, action) => {
            state.all.isLoading = false;
            state.all.error = false;
            state.all.errorMessage = "";
            // state.all.items = [
            //     ...state.all.items.slice(),
            //     ...action.payload.items,
            // ];
            state.all.items = action.payload.items;
        })
        .addCase(tracksFilterReset, (state) => {
            state.all.isLoading = false;
            state.all.error = false;
            state.all.errorMessage = "";
            state.all.items = [];
        });
});

export default tracksReducer;
