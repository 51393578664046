import { createAction } from "@reduxjs/toolkit";
import {
    ExpeditionsErrorType,
    ExpeditionsFetchResponse,
} from "./expeditions.interfaces";

export const _expeditionsFetchRequest = createAction("expeditions/request");

export const _expeditionsFetchSuccess = createAction<ExpeditionsFetchResponse>(
    "expeditions/success",
);

export const _expeditionsFetchError =
    createAction<ExpeditionsErrorType>("expeditions/error");
