import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../hooks";
import { useWindowDimensions } from "../../hooks";
import { getMarkerOffsets, MarkerOffset } from "./util/getMarkerOffsets";
import {
    getGradientStopsByTime,
    HeightmapGpxStop,
} from "./util/getGradientStops";
import { getPolylineFromGpxTrace } from "./util/getPolylineFromGpxTrace";
import IconMarker from "../IconMarker";
import { StarIcon, UserIcon } from "../../assets/video-ui";
import { GpxTrace } from "../../redux/video/video.interfaces";
import { MarkerProps } from "../IconMarker/IconMarker";
import { toBool } from "../../services/bluetooth/util/helpers.others";

interface HeightmapProps {
    seekToPoi: (id: string) => void;

    isLoading: boolean;
}

interface RenderableMarkerProps extends MarkerProps {
    id: string;
}

export interface CurrGpxInterface {
    gpx?: GpxTrace;

    index?: number;
}

const Heightmap = ({ seekToPoi, isLoading }: HeightmapProps): JSX.Element => {
    // const gpxTrace = useAppSelector((state) => state.video.videoData.gpxTrace);
    const adjustedGpxTrace = useAppSelector(
        (state) => state.video.videoAdjustedGpx,
    );
    const controlState = useAppSelector((state) => ({
        playedSeconds: state.control.progress.playedSeconds,
        played: state.control.progress.played,
        duration: state.control.progress.duration,
        inFullscreen: state.control.isFullscreen,
        isFlat: state.control.isFlat,
        distance: state.video.videoData.distance,
        avgVelocity: state.video.videoAvgVelocity,
    }));

    const poiState = useAppSelector((state) => state.poi.mappedItems);

    const [gpxPolyline, setGpxPolyline] = useState<string>("");
    // prettier-ignore
    const [heightmapContainerWidth, setHeightmapContainerWidth] = useState<number>(0);
    // prettier-ignore
    const [heightmapContainerHeight, setHeightmapContainerHeight] = useState<number>(0);
    const [gpxStops, setGpxStops] = useState<HeightmapGpxStop[]>([]);
    const [userPosition, setUserPosition] = useState<MarkerOffset>({
        bottom: 0,
        left: 0,
    });
    const [poiMarkers, setPoiMarkers] = useState<RenderableMarkerProps[]>([]);

    const wrapperDimensions = useWindowDimensions();
    const heightmapRef = useRef<HTMLDivElement>(null);
    const BOTTOM_OFFSET = 75;

    useEffect(() => {
        if (typeof adjustedGpxTrace !== "undefined") {
            setGpxPolyline(
                getPolylineFromGpxTrace(
                    adjustedGpxTrace,
                    BOTTOM_OFFSET,
                    heightmapContainerHeight,
                    heightmapContainerWidth,
                    controlState.duration,
                    controlState.isFlat,
                ),
            );
        }
    }, [
        heightmapContainerHeight,
        heightmapContainerWidth,
        controlState.duration,
        controlState.isFlat,
    ]);

    useEffect(() => {
        if (typeof adjustedGpxTrace !== "undefined" && controlState.duration)
            setGpxStops(
                getGradientStopsByTime(adjustedGpxTrace, controlState.duration),
            );
    }, [adjustedGpxTrace, controlState.duration]);

    useEffect(() => {
        setTimeout(() => {
            setHeightmapContainerWidth(heightmapRef.current?.offsetWidth || 0);
            setHeightmapContainerHeight(
                heightmapRef.current?.offsetHeight || 0,
            );
        }, 100);
    }, [wrapperDimensions, heightmapRef]);

    useEffect(() => {
        setTimeout(() => {
            setHeightmapContainerWidth(heightmapRef.current?.offsetWidth || 0);
            setHeightmapContainerHeight(
                heightmapRef.current?.offsetHeight || 0,
            );
        }, 100);
    }, [controlState.inFullscreen, isLoading]);

    useEffect(() => {
        const markerOffsets = getMarkerOffsets(
            heightmapContainerHeight,
            heightmapContainerWidth,
            BOTTOM_OFFSET,
            adjustedGpxTrace,
            controlState.playedSeconds,
            controlState.isFlat,
        );

        // move the marker a little to the left to compensate for the center of the icon
        setUserPosition({
            bottom: markerOffsets.bottom,
            left: markerOffsets.left - 10,
        });
    }, [controlState.played]);

    useMemo(() => {
        // type

        const markers: RenderableMarkerProps[] = poiState.map((item) => {
            const { bottom, left } = getMarkerOffsets(
                heightmapContainerHeight,
                heightmapContainerWidth,
                BOTTOM_OFFSET,
                adjustedGpxTrace,
                item.timestampStart,
                controlState.isFlat,
            );

            return {
                id: item.id,
                bottom,
                left,
                Icon: StarIcon,
            };
        });

        setPoiMarkers(markers);
    }, [
        poiState,
        heightmapContainerHeight,
        heightmapContainerWidth,
        adjustedGpxTrace,
        controlState.isFlat,
    ]);

    /* @DEBUG PURPOSES, CURRENT GPX */
    const [currentGpx, setCurrentGpx] = useState<CurrGpxInterface>();
    const played = useAppSelector((state) => state.control.progress.played);

    if (toBool(process.env.REACT_APP_DEBUG_SHOW_GPX_TEXT) === true) {
        useEffect(() => {
            const currentIndex = adjustedGpxTrace?.findIndex(
                (item) =>
                    item.interpolatedTimestamp > controlState.playedSeconds,
            );

            const currGpx = adjustedGpxTrace?.at(currentIndex - 1 || 0);

            setCurrentGpx({
                gpx: currGpx,
                index: currentIndex - 1,
            });
        }, [played]);
    }
    return (
        <div
            className="flex column heightmap"
            id="heightmap"
            ref={heightmapRef}
            style={{ display: isLoading ? "none" : "" }}
        >
            <svg
                viewBox={`0 0 ${heightmapContainerWidth} ${heightmapContainerHeight}`}
                xmlns="http://www.w3.org/2000/svg"
                opacity={0.8}
            >
                <defs>
                    <linearGradient
                        id="height-grad"
                        x1="0%"
                        y1="100%"
                        x2="100%"
                        y2="100%"
                    >
                        {gpxStops.map((item) => (
                            <stop
                                key={item.id}
                                offset={item.offset}
                                style={{
                                    stopColor: item.stopColor,
                                    stopOpacity: Number(
                                        controlState.played + 0.005 >= item.id,
                                    ),
                                }}
                            />
                        ))}
                    </linearGradient>
                </defs>
                <polyline
                    points={gpxPolyline}
                    fill="url(#height-grad)"
                    strokeWidth={
                        Number(process.env.REACT_APP_HEIGHTMAP_STROKE_WIDTH) ||
                        1
                    }
                    stroke={
                        `#${process.env.REACT_APP_HEIGHTMAP_STROKE_COLOR}` ||
                        "#afafaf"
                    }
                />
            </svg>
            {/* user marker */}
            <IconMarker
                bottom={userPosition.bottom}
                left={userPosition.left}
                Icon={UserIcon}
                className={`heightmap-user ${
                    !adjustedGpxTrace || !adjustedGpxTrace?.length
                        ? "undef"
                        : ""
                }`}
            />

            {toBool(process.env.REACT_APP_DEBUG_SHOW_GPX_TEXT) && (
                <p
                    className="text text-ff text-s gpx"
                    style={{
                        position: "absolute",
                        bottom: userPosition.bottom + 30,
                        left: userPosition.left + 30,
                    }}
                >
                    [{currentGpx?.index}]:{" "}
                    {currentGpx?.gpx?.distance.toFixed(3)}km
                </p>
            )}

            {poiMarkers.map((i) => (
                <IconMarker
                    key={i.id}
                    {...i}
                    className={`heightmap-poi`}
                    onClick={() => seekToPoi(i.id)}
                />
            ))}
        </div>
    );
};

export default Heightmap;
