import { MappedPointOfInterest } from "../../../redux/poi/poi.interfaces";

export const getCurrentPoi = (
    currentTime: number,
    points: MappedPointOfInterest[],
): MappedPointOfInterest | undefined => {
    return points.find(
        (item) =>
            item.timestampEnd &&
            item.timestampStart &&
            item.timestampStart <= currentTime &&
            item.timestampEnd > currentTime,
    );
};
