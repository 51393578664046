import { getAverageVelocityBetweenAB } from "../../views/parts/video/util/getVideoVelocity";
import {
    GpxBreakpoint,
    GpxTrace,
    InterpolatedGpxTrace,
} from "../video/video.interfaces";
import { MappedPointOfInterest, PointOfInterest } from "./poi.interfaces";

export const poiMapItemTime = (
    item: PointOfInterest,
    distance: number,
    duration: number,
    p1?: InterpolatedGpxTrace,
    p2?: InterpolatedGpxTrace,
): MappedPointOfInterest => {
    let timestampStart = -1;
    let timestampEnd: number;

    // fallback if points unavailable
    if (typeof p1 === "undefined" || typeof p2 === "undefined") {
        timestampStart = (item.distanceStart / distance) * duration;
        timestampEnd = (item.distanceEnd / distance) * duration;
    } else {
        timestampStart = p1?.interpolatedTimestamp;
        timestampEnd = p2?.interpolatedTimestamp;
    }

    return {
        ...item,
        timestampStart,
        timestampEnd,
    };
};

interface PoiGpxRangeReturnType {
    p1: InterpolatedGpxTrace;
    p2: InterpolatedGpxTrace;
}
/**
 * Gets two points between which the GPX is located (might be not precise)
 * @param poi
 * @param gpx
 * @returns
 */
export const poiGetGpxRange = (
    poi: PointOfInterest,
    gpx: InterpolatedGpxTrace[],
): PoiGpxRangeReturnType => {
    // prettier-ignore
    const indexStart = gpx.findIndex((item) => item.distance > poi.distanceStart);
    const indexEnd = gpx.findIndex((item) => item.distance > poi.distanceEnd);

    if (indexStart < 0) {
        // prettier-ignore
        throw new Error(`POI has invalid start-distance for mapping ${poi.distanceStart}`);
    }

    if (indexEnd < indexStart) {
        // prettier-ignore
        throw new Error(`Poi has invalid end-distance for mapping ${poi.distanceEnd}`)
    }

    // @To-DO Continue - jak obliczyc śr predkość między punktami by precyzyjnie określić timestamp ;_; Końcowy punkt może prędkość wyciągać?
    const p1 = gpx[indexStart - 1];
    const p2 = gpx[indexEnd - 1];

    return { p1, p2 };
};

/** Measures a time dilation between two points based on distance and velocity
 * Uses transformed equation (v=s/t => t=s/v)
 * [!] Measured in seconds
 */
export const getTimestampForPoi = (
    gpxPoint: GpxTrace,
    poiPointDistance: number,
    velocity: number,
): number => {
    //s
    const deltaDistance = poiPointDistance - gpxPoint.distance;
    // s/v + conversion to seconds (*3600) and rounding up
    return Number(((deltaDistance / velocity) * 3600).toFixed(2));
};

export const USE_SECTION_AVERAGE_VELOCITY = true;

/** Extracts gpx-breakpoints (points with actual timestamp field) data from redux-state
 * Then finds a gpx point before the requested @nextPoint and uses its velocity as base
 */
export const getBreakpoint = (
    nextPoint: InterpolatedGpxTrace,
    breakpoints?: GpxBreakpoint[],
): number => {
    if (typeof breakpoints === "undefined" || !breakpoints.length)
        throw new TypeError("Breakpoints not available");

    const lastAvailableBreakpoint = [
        ...breakpoints.filter((item) => item.distance <= nextPoint.distance),
    ].at(-1);

    if (typeof lastAvailableBreakpoint === "undefined")
        throw new TypeError("Available breakpoint not found");

    return lastAvailableBreakpoint.velocity;
};

export const poiHandleVelocityBetweenAB = (
    p1: InterpolatedGpxTrace,
    p2: InterpolatedGpxTrace,
    breakpoints?: GpxBreakpoint[],
): number => {
    try {
        if (USE_SECTION_AVERAGE_VELOCITY) {
            return getAverageVelocityBetweenAB(
                p1.distance,
                p2.distance,
                p1.interpolatedTimestamp,
                p2.interpolatedTimestamp,
            );
        } else {
            // @To-Do figure out a better way to get velocity
            return getBreakpoint(p1, breakpoints);
        }
    } catch (e: unknown) {
        return getBreakpoint(p1, breakpoints);
    }
};
