import { SagaReturnType } from "redux-saga/effects";
import { NetworkModule } from "../../services";
import { AxiosResponse } from "axios";

import {
    ExpeditionData,
    ExpeditionGetError,
    ExpeditionTracksData,
} from "./expedition.interfaces";

export type ExpDataResponse = SagaReturnType<
    typeof NetworkModule.getExpeditionData
>;

export type ExpTrackResponse = SagaReturnType<
    typeof NetworkModule.getExpeditionTracks
>;

export const responseIsExpeditionData = (
    response: ExpDataResponse,
): response is AxiosResponse<ExpeditionData> => {
    return (
        typeof (response as AxiosResponse<ExpeditionGetError>).data?.detail ===
            "undefined" &&
        typeof (response as AxiosResponse<ExpeditionData>).data?.id !==
            "undefined"
    );
};

export const responseIsExpeditionError = (
    response: ExpDataResponse,
): response is AxiosResponse<ExpeditionGetError> =>
    typeof (response as AxiosResponse<ExpeditionGetError>).data.detail !==
        "undefined" &&
    typeof (response as AxiosResponse).config === "undefined";

export const responseIsExpeditionTracks = (
    response: ExpTrackResponse,
): response is AxiosResponse<ExpeditionTracksData> => {
    return (
        typeof (response as AxiosResponse<ExpeditionGetError>).data.detail ===
            "undefined" &&
        typeof (response as AxiosResponse<ExpeditionTracksData>).data.items !==
            "undefined"
    );
};
