import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import app styles
import "./index.scss";

// import HOCs
import {
    MainPage,
    NotFoundPage,
    ExpeditionPage,
    ExpeditionsPage,
    VideoPage,
    AboutPage,
    TrainerPage,
    ContactPage,
} from "./views/pages";
import ProfilePage from "./views/pages/ProfilePage";
import TracksPage from "./views/pages/TracksPage";
import { Layout } from "./views/parts";

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    {/* <Route path="/about" element={<AboutPage />} /> */}
                    <Route path="/trainer" element={<TrainerPage />} />
                    <Route path="/contact" element={<ContactPage />} />

                    <Route path="/track/:id" element={<VideoPage />} />
                    <Route path="/tracks/" element={<TracksPage />} />

                    <Route path="/expeditions/" element={<ExpeditionsPage />} />
                    <Route
                        path="/expedition/:id"
                        element={<ExpeditionPage />}
                    />

                    {/* <Route path="/profile" element={<ProfilePage />} /> */}

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
