import { createAction } from "@reduxjs/toolkit";
import {
    MappedPointOfInterest,
    PointOfInterest,
    PoiFetch,
    PoiFetchError,
} from "./poi.interfaces";

export const poiFetchRequest = createAction<PoiFetch>("poi/fetch-request");
// prettier-ignore
export const poiFetchSuccess = createAction<MappedPointOfInterest[]>("poi/fetch-success");
export const poiFetchError = createAction<PoiFetchError>("poi/fetch-error");

export const poiDisplay = createAction<MappedPointOfInterest>("poi/display");

// automatic POI Close
export const poiClose = createAction("poi/close");
// user-invoked poi close
export const poiCloseOnDemand = createAction("poi/close-demand");

// invoked when user skips to any poi by clicking the marker
export const poiResetLastClosed = createAction("poi/reset-last-closed");

// invoke when mapping needs taking place
export const poiMapItems = createAction("poi/map-items");

// invoke when all items mapped are ready to update
export const poiPushMappedItems = createAction<MappedPointOfInterest[]>(
    "poi/push-mapped-items",
);

export const poiCleanup = createAction("poi/cleanup");

/* POI V2.0 */
export const poiDwnl = createAction<PoiFetch>("poi/dwnl");
export const poiRawSet = createAction<PointOfInterest[]>("poi/raw-set");
export const poiRawUpdateRequest = createAction("poi/raw-update-request");
// prettier-ignore
export const poiMappedSet = createAction<MappedPointOfInterest[]>("poi/mapped-set");
/* END */
