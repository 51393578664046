import { InterpolatedGpxTrace } from "../../../redux/video/video.interfaces";

/**
 * Creates a polyline string from passed gpxTrace.
 * @warning Gpx MUST BE extended with fake points to draw the polyline correctly with its fill attribute
 * @param gpxTrace extended gpx trace
 * @returns strings of polyline points "x,y x,y x,y x,y"
 */
export const getPolylineFromGpxTrace = (
    gpxTrace: InterpolatedGpxTrace[],
    bottomOffset: number,
    containerHeight: number,
    containerWidth: number,
    duration: number,
    isFlat: boolean,
): string => {
    if (duration === 0 || !Number.isFinite(duration)) {
        // @to-do @fix launching this method beforre gpx loads
        // console.warn("Video duration is incorrect");
        return "";
    }

    const xyt = gpxTrace.map((item) => ({
        x: item.distance,
        y: item.ele,
        t: item.interpolatedTimestamp,
    }));

    const lowestPoint: number = Math.min(...xyt.map((item) => item.y));
    const highestPoint: number = Math.max(...xyt.map((item) => item.y));
    const delta: number = Math.abs(highestPoint - lowestPoint);
    const beginPoint: number = containerHeight - bottomOffset;
    let scaleFactor: number;

    if (delta < 50) scaleFactor = 0.2;
    else if (delta < 150) scaleFactor = 0.35;
    else if (delta < 300) scaleFactor = 0.5;
    else if (delta < 450) scaleFactor = 0.7;
    else scaleFactor = 1;

    const points = xyt.map((item) => {
        const x: number = (item.t / duration) * containerWidth;
        const y: number =
            beginPoint -
            (Math.abs(item.y - lowestPoint) / delta) * beginPoint * scaleFactor;
        if (isFlat) {
            return `${x.toFixed(1)},${beginPoint}`;
        }
        return `${x.toFixed(1)},${y.toFixed(2)}`;
    });

    points.unshift(`0,${containerHeight}`);
    points.push(`${containerWidth},${containerHeight}`);

    return points.join(" ");
};
