import React from "react";

interface SpinnerProps {
    size?: "" | "lg";
}
const Spinner = ({ size }: SpinnerProps): JSX.Element => (
    <div className={`spinner ${size}`} />
);

export default Spinner;
