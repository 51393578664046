import { Action, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { TrackPanelProps } from "../../components/TrackPanel";
import { NetworkModule } from "../../services";
import {
    // tracksFetchAll,
    tracksFetchFail,
    // tracksFetchMore,
    tracksFetchMoreSuccess,
    tracksFetchNewest,
    tracksFetchNewestError,
    tracksFetchNewestSuccess,
    tracksFetchPopular,
    tracksFetchPopularError,
    tracksFetchPopularSuccess,
    tracksFetchSuccess,
    tracksFilterFetch,
} from "./tracks.actions";
import {
    // TracksFetchMore,
    // TracksFetchWithFilter,
    TracksFilterFetch,
} from "./tracks.interfaces";
import {
    responseIsTrackData,
    TracksFetchWithLimitResponseType,
    TracksQuickFetchResponseType,
} from "./tracks.typeguard";

export const selectTracks = (state: any): TrackPanelProps[] =>
    state.tracks.all.items;

function* onNewestTrackFetch(
    action: Action<typeof tracksFetchNewest>,
): unknown {
    const response: TracksQuickFetchResponseType = yield call(
        NetworkModule.fetchQuickTracks,
        "newest",
    );

    if (responseIsTrackData(response)) {
        yield put({
            type: tracksFetchNewestSuccess.toString(),
            payload: {
                items: response.data.items,
            },
        });
    } else {
        yield put({
            type: tracksFetchNewestError.toString(),
            payload: {
                detail: response,
            },
        });
    }
}

function* onPopularTrackFetch(
    action: Action<typeof tracksFetchPopular>,
): unknown {
    const response: TracksQuickFetchResponseType = yield call(
        NetworkModule.fetchQuickTracks,
        "popular",
    );

    if (responseIsTrackData(response)) {
        yield put({
            type: tracksFetchPopularSuccess.toString(),
            payload: {
                items: response.data.items,
            },
        });
    } else {
        yield put({
            type: tracksFetchPopularError.toString(),
            payload: {
                detail: response,
            },
        });
    }
}

// function* onAllTracksFetch(
//     action: PayloadAction<TracksFetchWithFilter>,
// ): unknown {
//     const response: TracksFetchWithLimitResponseType = yield call(
//         NetworkModule.fetchTracksWithFilter,
//         action.payload.filter,
//         action.payload.page,
//     );

//     if (responseIsTrackData(response)) {
//         const { items } = response.data;
//         yield put({
//             type: tracksFetchSuccess.toString(),
//             payload: {
//                 items,
//             },
//         });
//     } else {
//         yield put({
//             type: tracksFetchFail.toString(),
//             payload: response,
//         });
//     }
// }

// function* onMoreTracksFetch(action: PayloadAction<TracksFetchMore>): unknown {
//     const response: TracksFetchWithLimitResponseType = yield call(
//         NetworkModule.fetchTracksWithLimit,
//         action.payload.page,
//     );

//     if (responseIsTrackData(response)) {
//         const { items } = response.data;

//         // get current items
//         const currentItems: ReturnType<typeof selectTracks> = yield select(
//             selectTracks,
//         );
//         // create an array with all the items
//         const allItems = [...currentItems, ...items];

//         // craft an array with unique items (based on id property)
//         const uniqueItems = Array.from(
//             new Map(allItems.map((item) => [item.id, item])).values(),
//         );

//         // send all items to update
//         yield put({
//             type: tracksFetchMoreSuccess.toString(),
//             payload: {
//                 items: uniqueItems,
//             },
//         });
//     } else {
//         yield put({
//             type: tracksFetchFail.toString(),
//             payload: response,
//         });
//     }
// }

/**
 * Fetches tracks from API with filters and optional page number.
 * @param action
 */
function* onTrackFilterFetch(action: PayloadAction<TracksFilterFetch>) {
    const response: TracksFetchWithLimitResponseType = yield call(
        NetworkModule.fetchTracksWithFilter,
        action.payload.filters,
        action.payload.page,
    );

    if (responseIsTrackData(response)) {
        // get tracks from API response
        const { items } = response.data;

        // get current tracks stored in redux
        const currentItems: ReturnType<typeof selectTracks> = yield select(
            selectTracks,
        );

        // create an array with all of the tracks
        const allItems = [...currentItems, ...items];

        // craft an array with unique tracks only (based on id property) as tracks from API may be duplicates
        const uniqueItems = Array.from(
            new Map(allItems.map((item) => [item.id, item])).values(),
        );

        // update redux-store with new array of tracks
        yield put({
            type: tracksFetchMoreSuccess.toString(),
            payload: {
                items: uniqueItems,
            },
        });
    } else {
        yield put({
            type: tracksFetchFail.toString(),
            payload: response,
        });
    }
}
export default [
    takeLatest(tracksFetchNewest.toString(), onNewestTrackFetch),
    takeLatest(tracksFetchPopular.toString(), onPopularTrackFetch),
    // takeLatest(tracksFetchAll.toString(), onAllTracksFetch),
    // takeLatest(tracksFetchMore.toString(), onMoreTracksFetch),
    takeLatest(tracksFilterFetch.toString(), onTrackFilterFetch),
];
