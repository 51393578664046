export const ftmsFieldSize = {
    Flags: 2,
    InstantaneousSpeed: 2,
    AvgSpeed: 2,
    InstantaneousCadence: 2,
    AvgCadence: 2,
    TotalDistance: 3,
    ResistanceLevel: 2,
    InstantaneousPower: 2,
    AvgPower: 2,
    TotalEnergy: 2,
    EnergyPerH: 2,
    EnergyPerM: 1,
    HeartRate: 2,
    MetabolicEqv: 1,
    ElapsedTime: 2,
    RemainingTime: 2,
};
