import { GpxTrace } from "../../../redux/video/video.interfaces";
import { LatLonTuple } from "./definitions";
import { gpxTraceIsTuple } from "./typeguards";

/**
 * Uses gpx point from API to return [lat, lon] tuple
 * @param gpxTrace
 * @returns
 * @throws on undefined GPXTrace
 */
export const parseGpxPoint = (
    gpxTrace: GpxTrace | LatLonTuple | undefined,
): LatLonTuple => {
    if (typeof gpxTrace === "undefined") throw new Error("GpxTrace undefined");

    if (gpxTraceIsTuple(gpxTrace)) return gpxTrace;
    else return [gpxTrace.lat, gpxTrace.lon];
};
